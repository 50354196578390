import React, { useState } from "react";
import { toast, useToast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import validator from "validator";

import loginLogoImg from "../../assets/images/logo.svg";
import { login } from "../../services/AuthService";
import Utils from "../../utils";
import { authActions, subscriptionActions } from "../../store";
import ConfigDB from "../../config";

function Login() {
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onLogin = (e) => {
    e.preventDefault();
    let errorObj = { ...errorsObj };
    let error = false;

    if (!email) {
      errorObj.email = "Email address is required";
      error = true;
    } else if (!validator.isEmail(email)) {
      errorObj.email = "Please enter valid email address";
      error = true;
    }

    if (!password) {
      errorObj.password = "Password is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    let obj = {
      email: email,
      password: password,
    };
    setIsLoading(true);
    login(obj)
      .then((response) => {
        let userData = response?.data?.data;
        if (userData) {
          // Utils.removeSetInterval();
          Utils.setDetail(userData);
          Utils.setIntervalRemove();
          dispatch(authActions.login(userData));
          if (
            parseInt(userData?.user_role) === parseInt(ConfigDB.data.ROLE_ADMIN)
          ) {
            navigate("/company");
          } else {
            navigate("/dashboard");
          }
        }
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const onSignup = () => {
    navigate("/company-registration");
  };

  return (
    <div className="login-content">
      <form method={`post`} onSubmit={onLogin}>
        <div className="logo-user">
          <img src={loginLogoImg} alt="login-logo" />
        </div>
        <div className="input-div input-group">
          <label htmlFor="" className="form-label w-100">Email</label>
          <input
            className="form-control"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <span className="input-group-text">
            <i className="bi bi-person"></i>
          </span>
          {errors.email && <span className="input-error">{errors.email}</span>}
        </div>
        <div className="input-div input-group">
          <label htmlFor="" className="form-label w-100">Password</label>
          <input
            className="form-control"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <span className="input-group-text">
            <i className="bi bi-lock"></i>
          </span>
          {errors.password && (
            <span className="input-error">{errors.password}</span>
          )}
        </div>

        <div className="input-keep-me">
          <label className="check-label">
            <input
              type="checkbox"
              checked={true}
              disabled={true}
              onChange={handleCheckboxChange}
            />
            <div className="checkmark"></div>
            {`Keep me signed in`}
          </label>
        </div>
        <div className="button-row mb-3">
          <button type="submit" className="btn login" disabled={isLoading}>
            {isLoading ? "Logging in..." : "Login"}
          </button>
          <input
            type="button"
            className="btn login-outline"
            value="Sign up"
            onClick={onSignup}
          />
        </div>

        <div className="input-keep-me justify-content-start mb-0">
          <NavLink
            to={`/forgot-password`}
            style={{ color: "blue" }}
            role={`link`}
          >{`Forgot password?`}</NavLink>
        </div>
      </form>
    </div>
  );
}

export default Login;
