import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionGetUserPermission } from "../services/RoleService";
import { permissionActions } from "../store";
import Utils from "./index";

function CheckPermission() {
  let auth = useSelector((x) => x.auth.value);
  let loginRoleId = 0;
  if (typeof auth != "object") {
    auth = JSON.parse(auth);
  }
  loginRoleId = auth.user_role;
  const permission = useSelector((x) => x.permission.value);
  const dispatch = useDispatch();
  const [loadStep, setLoadStep] = useState(1);
  const [status, setStatus] = useState(500);
  const naivgate = useNavigate();

  useEffect(() => {
    let data = { role_id: loginRoleId };

    actionGetUserPermission(data)
      .then((response) => {
        setLoadStep(2);
        dispatch(permissionActions.permission(response.data.data));
      })
      .catch((error) => {
        setLoadStep(3);
        localStorage.clear();
      });
  }, [loginRoleId, dispatch]);

  useEffect(() => {
    if (loadStep === 2) {
      let pathName = window.location.pathname;
      if (
        pathName === "/dashboard" ||
        pathName === "/profile" ||
        pathName === "/payment"
      ) {
        setStatus(200);
        setLoadStep(3);
      } else {
        if (permission && permission.length > 0) {
          let permissionName = Utils.getPermissionName(pathName);
          if (permission.findIndex((e) => e.name == permissionName) > -1) {
            setStatus(200);
            setLoadStep(3);
          } else {
            setStatus(500);
            setLoadStep(3);
          }
        } else {
          setStatus(500);
          setLoadStep(3);
        }
      }
    }
  }, [permission, loadStep]);

  return (
    <>
      {loadStep === 1 && (
        <div className={`loader-wrapper`}>
          <div className="loader-index">
            <span></span>
          </div>
          <svg>
            <defs></defs>
            <filter id="goo">
              <fegaussianblur
                in="SourceGraphic"
                stdDeviation="11"
                result="blur"
              ></fegaussianblur>
              <fecolormatrix
                in="blur"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                result="goo"
              ></fecolormatrix>
            </filter>
          </svg>
        </div>
      )}
      {loadStep === 3 && (
        <>{status === 200 ? <Outlet /> : <Navigate to={`/403`} />}</>
      )}
    </>
  );
}

export default CheckPermission;
