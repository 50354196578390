import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ConfigDB from "../../config";
import { getVehicleList } from "../../services/VehicleService";
import Select from "react-select";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  createAddDriver,
  createAddUserFilter,
} from "../../services/EmployeeService";
import validator from "validator";
import {
  actionLanguageList,
  actionTimeAllZoneList,
  actionTimeZoneList,
} from "../../services/Common";
import {
  getDepartmentAllList,
  getFilterAllList,
  getSegmentAllList,
} from "../../services/ManagementService";
import { actionChangePassword } from "../../services/ProfileService";

const Type = [
  { value: "1", label: "OverSpeed", name: "over_speed" },
  { value: "2", label: "LowerSpeed", name: "low_speed" },
  { value: "3", label: "Maintainance Task", name: "maintainance_task" },
  { value: "4", label: "Geo Fense IN", name: "in" },
  { value: "5", label: "Temperature", name: "temperature" },
  { value: "6", label: "Geo Fense OUT", name: "out" },
];

const DasboardType = [
  { value: "1", label: "Alarm" },
  { value: "2", label: "Costs" },
  { value: "3", label: "Driving Time" },
  { value: "4", label: "Work Load" },
  { value: "5", label: "Driver Coaching" },
  { value: "6", label: "Benchmarks" },
  { value: "6", label: "Logins" },
  { value: "6", label: "Mileage" },
];

const FilteringEmployee = ({ setActiveTab, usersDetails }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;

  let initialData = {
    historic_vehicle: 0,
    vehicle_filter_id: "",
    future_alarm: 0, // This should be 0 by default, not undefined
    alarm_filter_id: "",
    future_report: 0,
    report_filter_id: "",
    future_organization: 0,
    department_id: "",
    segement_id: "",
    future_dashboard: 0,
    dashboard_filter_id: "",
  };

  const [employeeData, setEmployeeData] = useState(initialData);

  console.log("employeeData", employeeData);

  const [departmentList, setDepartmentList] = useState([]);
  const [segementList, setSegementList] = useState([]);
  const [FilterList, setFilterList] = useState([]);

  let userId = localStorage.getItem("UserId");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  useEffect(function () {
    getDepartmentAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setDepartmentList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getFilterAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setFilterList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getSegmentAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setSegementList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (usersDetails) {
      setEmployeeData({
        historic_vehicle:
          usersDetails?.historic_vehicle ?? employeeData.historic_vehicle,
        vehicle_filter_id:
          usersDetails?.vehicle_filter_id ?? employeeData.vehicle_filter_id,
        future_alarm: usersDetails?.future_alarm ?? employeeData.future_alarm,
        alarm_filter_id:
          usersDetails?.alarm_filter_id ?? employeeData.alarm_filter_id,
        future_report:
          usersDetails?.future_report ?? employeeData.future_report,
        report_filter_id:
          usersDetails?.report_filter_id ?? employeeData.report_filter_id,
        future_organization:
          usersDetails?.future_organization ?? employeeData.future_organization,
        department_id:
          usersDetails?.department_ids ?? employeeData.department_id,
        segement_id: usersDetails?.segement_id ?? employeeData.segement_id,
        future_dashboard:
          usersDetails?.future_dashboard ?? employeeData.future_dashboard,
        dashboard_filter_id:
          usersDetails?.dashboard_filter_id ?? employeeData.dashboard_filter_id,
      });
    }
  }, [usersDetails]);

  const handleChangeVehicleFilter = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    setEmployeeData((prevData) => ({
      ...prevData,
      vehicle_filter_id: selectedValues,
    }));
  };

  const handleChangeDashboard = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    setEmployeeData((prevData) => ({
      ...prevData,
      dashboard_filter_id: selectedValues,
    }));
  };

  const handleChangeReport = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    setEmployeeData((prevData) => ({
      ...prevData,
      report_filter_id: selectedValues,
    }));
  };

  const handleChangeAlarm = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    setEmployeeData((prevData) => ({
      ...prevData,
      alarm_filter_id: selectedValues,
    }));
  };

  const handleChangeDepartment = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    setEmployeeData((prevData) => ({
      ...prevData,
      department_id: selectedValues,
    }));
  };

  const handleChangeSegement = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    setEmployeeData((prevData) => ({
      ...prevData,
      segement_id: selectedValues,
    }));
  };

  const handleEmployeeForm = (e) => {
    e.preventDefault();

    let data = {
      user_id: userId,
      historic_vehicle: employeeData?.historic_vehicle,
      vehicle_filter_id: employeeData?.vehicle_filter_id,
      future_alarm: employeeData?.future_alarm,
      alarm_filter_id: employeeData?.alarm_filter_id,
      future_report: employeeData?.future_report,
      report_filter_id: employeeData?.report_filter_id,
      future_organization: employeeData?.future_organization,
      department_id: employeeData?.department_id,
      segement_id: employeeData?.segement_id,
      future_dashboard: employeeData?.future_dashboard,
      dashboard_filter_id: employeeData?.dashboard_filter_id,
    };

    let updateData = {
      user_id: usersDetails?.id,
      uuid: usersDetails?.uuid,
      historic_vehicle: employeeData?.historic_vehicle,
      vehicle_filter_id: employeeData?.vehicle_filter_id,
      future_alarm: employeeData?.future_alarm,
      alarm_filter_id: employeeData?.alarm_filter_id,
      future_report: employeeData?.future_report,
      report_filter_id: employeeData?.report_filter_id,
      future_organization: employeeData?.future_organization,
      department_id: employeeData?.department_id,
      segement_id: employeeData?.segement_id,
      future_dashboard: employeeData?.future_dashboard,
      dashboard_filter_id: employeeData?.dashboard_filter_id,
    };

    if (usersDetails?.uuid) {
      modifyEmployee(createAddUserFilter, updateData);
    } else {
      modifyEmployee(createAddUserFilter, data);
    }
  };

  const modifyEmployee = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("interact");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setEmployeeData({
      ...employeeData,
      historic_vehicle: newValue,
    });
  };

  const handleCheckboxChangeAlarm = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setEmployeeData({
      ...employeeData,
      future_alarm: newValue,
    });
  };

  const handleCheckboxChangeReport = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setEmployeeData({
      ...employeeData,
      future_report: newValue,
    });
  };

  const handleCheckboxChangeDepart = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setEmployeeData({
      ...employeeData,
      future_organization: newValue,
    });
  };

  const handleCheckboxChangeDashboard = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setEmployeeData({
      ...employeeData,
      future_dashboard: newValue,
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleEmployeeForm}>
              <h4>Data Filtering</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Hide historic vehicle data
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="historic_vehicle"
                          checked={employeeData.historic_vehicle === 1}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="historic_vehicle"
                        >
                          Hide historic vehicle data
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">Vehicle filter</Label>
                    <div className="input-div">
                      <Select
                        options={FilterList}
                        isMulti
                        value={FilterList.filter(
                          (option) =>
                            Array.isArray(employeeData?.vehicle_filter_id) &&
                            employeeData?.vehicle_filter_id.includes(
                              option.value
                            )
                        )}
                        onChange={handleChangeVehicleFilter}
                        placeholder={"Select Vehicle filter"}
                        classNamePrefix="select"
                        closeMenuOnSelect={false}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h4>Alarms Filtering</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Include all present and future alarms
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="future_alarm"
                          checked={employeeData.future_alarm === 1}
                          onChange={handleCheckboxChangeAlarm}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="future_alarm"
                        >
                          Include all present and future alarms
                        </label>
                      </div>
                    </div>
                  </div>

                  {employeeData.future_alarm === undefined ||
                    (employeeData.future_alarm === 0 && (
                      <div className="col-md-4">
                        <Label className="col-form-label">Alarm filter</Label>
                        <div className="input-div">
                          <Select
                            options={Type}
                            isMulti
                            value={Type.filter(
                              (option) =>
                                Array.isArray(employeeData?.alarm_filter_id) &&
                                employeeData?.alarm_filter_id.includes(
                                  option.value
                                )
                            )}
                            onChange={handleChangeAlarm}
                            placeholder={"Select Alarm filter"}
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </FormGroup>

              <h4>Reports Filtering</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Include all present and future reports
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="future_report"
                          checked={employeeData.future_report === 1}
                          onChange={handleCheckboxChangeReport}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="future_report"
                        >
                          Include all present and future reports
                        </label>
                      </div>
                    </div>
                  </div>

                  {employeeData.future_report === 0 && (
                    <div className="col-md-4">
                      <Label className="col-form-label">Report filter</Label>
                      <div className="input-div">
                        <Select
                          options={Type}
                          isMulti
                          value={Type.filter(
                            (option) =>
                              Array.isArray(employeeData?.report_filter_id) &&
                              employeeData?.report_filter_id.includes(
                                option.value
                              )
                          )}
                          onChange={handleChangeReport}
                          placeholder={"Select Report filter"}
                          classNamePrefix="select"
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </FormGroup>

              <h4>Organization Filtering</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Include all present and future organizations
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="future_organization"
                          checked={employeeData.future_organization === 1}
                          onChange={handleCheckboxChangeDepart}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="future_organization"
                        >
                          Include all present and future organizations
                        </label>
                      </div>
                    </div>
                  </div>

                  {employeeData.future_organization === 0 && (
                    <>
                      <div className="col-md-4">
                        <Label className="col-form-label">
                          Organization filter
                        </Label>
                        <div className="input-div">
                          <Select
                            options={departmentList}
                            isMulti
                            value={departmentList.filter(
                              (option) =>
                                Array.isArray(employeeData?.department_id) &&
                                employeeData?.department_id.includes(
                                  option.value
                                )
                            )}
                            onChange={handleChangeDepartment}
                            placeholder={"Select Department"}
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <Label className="col-form-label">
                          Organization filter
                        </Label>
                        <div className="input-div">
                          <Select
                            options={segementList}
                            isMulti
                            value={segementList.filter(
                              (option) =>
                                Array.isArray(employeeData?.segement_id) &&
                                employeeData?.segement_id.includes(option.value)
                            )}
                            onChange={handleChangeSegement}
                            placeholder={"Select Segement"}
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </FormGroup>

              <h4>Advanced Dashboard Filtering</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Include all present and future advanced dashboards
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="future_dashboard"
                          checked={employeeData.future_dashboard === 1}
                          onChange={handleCheckboxChangeDashboard}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="future_dashboard"
                        >
                          Include all present and future advanced dashboards
                        </label>
                      </div>
                    </div>
                  </div>

                  {employeeData.future_dashboard === 0 && (
                    <div className="col-md-4">
                      <Label className="col-form-label">
                        Advanced dashboard filter
                      </Label>
                      <div className="input-div">
                        <Select
                          options={DasboardType}
                          isMulti
                          value={DasboardType.filter(
                            (option) =>
                              Array.isArray(
                                employeeData?.dashboard_filter_id
                              ) &&
                              employeeData?.dashboard_filter_id.includes(
                                option.value
                              )
                          )}
                          onChange={handleChangeDashboard}
                          placeholder={"Select Advacnced filter"}
                          classNamePrefix="select"
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilteringEmployee;
