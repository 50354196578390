import React from "react";
import { useNavigate } from "react-router-dom";

import logoImg from "../assets/images/logo.svg";
import groupImg from "../assets/images/Group1722.png";
import aboutusImg from "../assets/images/aboutus.png";
import counterImg from "../assets/images/counter.png";
import laptopImg from "../assets/images/laptop_img.png";
import playstoreImg from "../assets/images/playstore.png";
import iStoreImg from "../assets/images/i_Store.png";
import bannerImg from "../assets/images/banner.png";
import Counter from "./about/Counter";

function LandingPage() {
  const navigate = useNavigate();

  return (
    <>
      <section
        id="hero"
        className="d-flex align-items-center"
        style={{ backgroundImage: `url(${bannerImg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1">
              <h1>Welcome To On-Route</h1>
              <h2>
                We are one of the leading GPS Tracking platforms across the
                globe.
              </h2>
            </div>
            <div
              className="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img src={groupImg} className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row content">
              <div className="col-lg-6">
                <img src={aboutusImg} className="img-fluid animated" alt="" />
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <h2>About Us</h2>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="counter">
          <Counter />
        </div>

        <section className="fleet-tracking">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-6 pt-4 pt-lg-0">
                <h2>Easy to use, mobile friendly fleet tracking</h2>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable.{" "}
                </p>
              </div>
              <div className="col-lg-6">
                <img src={laptopImg} className="img-fluid animated" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="platforms-section">
          <div className="container">
            <div className="section-title">
              <h2>Available on Android and IOS Mobile platforms.</h2>
            </div>

            <div className="android-block">
              <ul className="android-nav">
                <li>
                  <a href="#">
                    <img
                      src={playstoreImg}
                      className="img-fluid animated"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={iStoreImg}
                      className="img-fluid animated"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default LandingPage;

// <!-- Template Main JS File -->
// <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.0/jquery.min.js"></script>
// <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js" integrity="sha384-I7E8VVD/ismYTF4hNIPjVp/Zjvgyol6VFvRkX/vR+Vc4jQkC+hVqc2pM8ODewa9r" crossorigin="anonymous"></script>
// <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.min.js" integrity="sha384-0pUGZvbkm6XF6gxjEnlmuGrJXVbNuzT9qBBavbLwCsOGabYfZo0T0to5eqruptLy" crossorigin="anonymous"></script>
// <script src="js/main.js"></script>
