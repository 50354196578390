import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const name = 'subscription';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({name, initialState, reducers});

export const subscriptionPlanActions = {...slice.actions, ...extraActions};
export const subscriptionPlanReducer = slice.reducer;

function createInitialState() {
    return {
        value: 0
    }
}

function createReducers() {
    return {
        setSubscriptionPlan
    };

    function setSubscriptionPlan(state, action) {
        state.value = action.payload;
    }
}

function createExtraActions() {
    return {
        subscriptionPlan: subscriptionPlan()
    };

    function subscriptionPlan() {
        return createAsyncThunk(
            `${name}/load`,
            function (arg, {dispatch}) {
                dispatch(subscriptionPlanActions.setSubscriptionPlan(arg));
            }
        );
    }
}