import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate, useParams } from "react-router-dom";

import GeneralFilters from "./GeneralFilters";
import Vehicles from "./Vehicles";
import Driver from "./Driver";
import ConfigDB from "../../config";
import { Button } from "reactstrap";
import { createFilter, getFilterList } from "../../services/ManagementService";

const tableLengthList = ConfigDB.data.dataTableLength;

const AddFilters = ({ setFilterStatusData, addFiltersData }) => {
  const [activeTab, setActiveTab] = useState("general");
  const navigate = useNavigate();
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [generalData, setGeneralData] = useState({ name: "" });

  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);
  const isAdminRole = JsonParse?.user_role === ConfigDB.data.ROLE_ADMIN;
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const handleVehicleSelection = (selected) => {
    setSelectedVehicles(selected);
  };

  const handleDriverSelection = (selected) => {
    setSelectedDrivers(selected);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (addFiltersData?.uuid) {
      setSelectedVehicles([addFiltersData.vehicle_total]);
      setSelectedDrivers([addFiltersData.driver_total]);
    }
  }, [addFiltersData]);

  const handleVehicleForm = (e) => {
    e.preventDefault();

    let data = {
      name: generalData?.name,
      vehicle_total: selectedVehicles,
      driver_total: selectedDrivers,
    };

    let updateData = {
      uuid: addFiltersData?.uuid,
      name: generalData?.name,
      vehicle_total: selectedVehicles,
      driver_total: selectedDrivers,
    };

    if (addFiltersData?.uuid) {
      createFilter(updateData)
        .then((response) => {
          toast.success(response.data.message);
          // navigate("/administration");
          // setActiveTab("filter");
          setFilterStatusData(false);

          let data = { ...params };
          getFilterList(data)
            .then((response) => {})
            .catch((err) => {
              toast.error(Utils.getErrorMessage(err));
            });
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      createFilter(data)
        .then((response) => {
          toast.success(response.data.message);
          // navigate("/administration");
          // setActiveTab("filter");
          setFilterStatusData(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  return (
    <div>
      <div className="inner-header py-3 d-none">
        <div className="left-block"></div>
      </div>
      <div className="inner-header-back">
        <div className="left-col">
          <button
            type="buttton"
            className="back-btn"
            onClick={() => {
              setFilterStatusData(false);
            }}
          >
            <i class="bi bi-chevron-left"></i> Back
          </button>
        </div>
        <div className="right-block w-auto">
          <Button
            type={`button`}
            onClick={handleVehicleForm}
            className={`add-btn btn  float-right`}
          >
            {`Save`}
          </Button>
        </div>
      </div>

      <div className="leaflet-control-management managementui-tab leaflet-control-var">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "general" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("general");
                }}
              >
                <span data-href="#tab-1">General</span>
              </li>
            </ul>

            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "vehicle" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("vehicle");
                }}
              >
                {addFiltersData?.vehicleTotalCount > 0 ? (
                  <>
                    <i
                      className="bi bi-check-lg"
                      style={{ fontSize: "20px" }}
                    ></i>
                    <span data-href="#tab-1" style={{ marginLeft: "10px" }}>
                      Vehicle
                    </span>
                  </>
                ) : (
                  <span data-href="#tab-1">Vehicle</span>
                )}
              </li>
            </ul>

            <ul className="tabs-management">
              <li
                className={`nav-link ${activeTab === "driver" ? "active" : ""}`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("driver");
                }}
              >
                {addFiltersData?.driverTotalCount > 0 ? (
                  <>
                    <i
                      className="bi bi-check-lg"
                      style={{ fontSize: "20px" }}
                    ></i>
                    <span data-href="#tab-1" style={{ marginLeft: "10px" }}>
                      Driver
                    </span>
                  </>
                ) : (
                  <span data-href="#tab-1">Driver</span>
                )}
              </li>
            </ul>
          </nav>

          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "general" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <GeneralFilters
                setActiveTab={setActiveTab}
                addFiltersData={addFiltersData}
                setFilterStatusData={setFilterStatusData}
                setGeneralData={setGeneralData}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "vehicle" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <Vehicles
                setActiveTab={setActiveTab}
                addFiltersData={addFiltersData}
                setFilterStatusData={setFilterStatusData}
                onSelectionChange={handleVehicleSelection}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "driver" ? "show active" : ""
              }`}
              id="tab-3"
            >
              <Driver
                setActiveTab={setActiveTab}
                addFiltersData={addFiltersData}
                setFilterStatusData={setFilterStatusData}
                onSelectionChange={handleDriverSelection}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFilters;
