import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import signupLogoImg from "../../assets/images/logo.svg";
import uploadIcon from "../../assets/images/upload.svg";
import Utils from "../../utils";
import { toast } from "react-toastify";
import { createCompany } from "../../services/CompanyService";
import * as validator from "validator";
import {
  actionCountryList,
  actionLanguageList,
  actionTimeZoneList,
} from "../../services/Common";
import { Label } from "reactstrap";

function CompanyRegistration() {
  const [name, setName] = useState("");
  const [logo, setLogo] = useState(null);
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [tin, setTin] = useState("");
  const [country, setCountry] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [language, setLanguage] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactDOB, setContactDOB] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  let errorsObj = {
    name: "",
    contact: "",
    address: "",
    tin: "",
    country: "",
    timeZone: "",
    // vatNumber: "",
    language: "",
    companyEmail: "",
    contactName: "",
    contactDOB: "",
    contactEmail: "",
    contactNumber: "",
    logo: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [countryList, setCountryList] = useState([]);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [isTimezoneLoad, setIsTimezoneLoad] = useState(false);
  const navigate = useNavigate();

  useEffect(function () {
    actionCountryList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCountryList(list);
      })
      .catch((err) => {});

    actionLanguageList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].language_name,
          });
        }
        setLanguageList(list);
      })
      .catch((err) => {});
  }, []);

  const onUpdateCountry = (e) => {
    let countryId = e.target.value;
    setCountry(countryId);
    setTimeZone("");
    setIsTimezoneLoad(true);
    actionTimeZoneList({ countryId: countryId })
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setTimeZoneList(list);
        setIsTimezoneLoad(false);
      })
      .catch((err) => {
        setIsTimezoneLoad(false);
      });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setLogo(selectedFile);

    if (selectedFile) {
      setSelectedFileName(selectedFile.name);
    } else {
      setSelectedFileName("");
    }
  };

  const validateImage = (file) => {
    // Check file type
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedTypes.includes(file.type)) {
      return false;
    }

    // Check file size
    if (file.size > 5 * 1024 * 1024) {
      // 5 MB limit
      return false;
    }

    return true;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    let errorObj = { ...errorsObj };
    let error = false;

    if (!name) {
      errorObj.name = "Name is required";
      error = true;
    }

    if (!contact) {
      errorObj.contact = "Contact number is required";
      error = true;
    } else if (!validator.isMobilePhone(contact)) {
      errorObj.contact = "Invalid contact";
      error = true;
    } else if (contact && contact.length !== 9) {
      errorObj.contact = "Invalid contact";
      error = true;
    }
    if (!address) {
      errorObj.address = "Address is required";
      error = true;
    }

    // if (!tin) {
    //   errorObj.tin = "Tin is required";
    //   error = true;
    // }

    if (!country) {
      errorObj.country = "Country is required";
      error = true;
    }

    if (!timeZone) {
      errorObj.timeZone = "Timezone is required";
      error = true;
    }

    // if (!vatNumber) {
    //   errorObj.vatNumber = "Vat number is required";
    //   error = true;
    // }

    if (!language) {
      errorObj.language = "Language is required";
      error = true;
    }

    if (!companyEmail) {
      errorObj.companyEmail = "Company email is required";
      error = true;
    }

    if (!contactName) {
      errorObj.contactName = "Contact name is required";
      error = true;
    }

    if (!contactDOB) {
      errorObj.contactDOB = "Contact DOB is required";
      error = true;
    }

    if (!contactEmail) {
      errorObj.contactEmail = "Contact email is required";
      error = true;
    }

    if (!contactNumber) {
      errorObj.contactNumber = "Contact number is required";
      error = true;
    } else if (!validator.isMobilePhone(contactNumber)) {
      errorObj.contactNumber = "Invalid contact number";
      error = true;
    } else if (contactNumber && contactNumber.length !== 9) {
      errorObj.contactNumber = "Invalid contact number";
      error = true;
    }

    // if (!logo) {
    //   errorObj.logo = "Logo is required";
    //   error = true;
    // } else if (!validateImage(logo)) {
    //   errorObj.logo =
    //     "Invalid file. Please upload a valid image. Size must be < 5mb";
    //   error = true;
    // }

    setErrors(errorObj);
    if (error) return;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("contact", contact);
    formData.append("address", address);
    formData.append("tin", tin);
    formData.append("country", country);
    formData.append("timeZone", timeZone);
    formData.append("vatNumber", vatNumber);
    formData.append("language", language);
    formData.append("companyEmail", companyEmail);
    formData.append("contactName", contactName);
    formData.append("contactDOB", contactDOB);
    formData.append("contactEmail", contactEmail);
    formData.append("contactNumber", contactNumber);
    formData.append("logo", logo);

    createCompany(formData)
      .then((response) => {
        toast.success(response.data.message);
        navigate("/");
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      });
  };

  // const onFormSubmit = (e) => {
  //     e.preventDefault();
  //
  //     const validationRules = [
  //         { field: 'name', message: 'Name is required' },
  //         { field: 'contact', message: 'Contact is required' },
  //         { field: 'address', message: 'Address is required' },
  //         { field: 'tin', message: 'Tin is required' },
  //         { field: 'country', message: 'Country is required' },
  //         { field: 'timeZone', message: 'TimeZone is required' },
  //         { field: 'vatNumber', message: 'VatNumber is required' },
  //         { field: 'language', message: 'Language is required' },
  //         { field: 'companyEmail', message: 'Company email is required' },
  //         { field: 'contactName', message: 'Contact name is required' },
  //         { field: 'contactDOB', message: 'Contact DOB is required' },
  //         { field: 'contactEmail', message: 'Contact email is required' },
  //         { field: 'contactNumber', message: 'Contact number is required' }
  //     ];
  //
  //     const errorObj = validationRules.reduce((errors, rule) => {
  //         if (!eval(rule.field)) {
  //             errors[rule.field] = rule.message;
  //         }
  //         return errors;
  //     }, {});
  //
  //     setErrors(errorObj);
  //
  //     if (Object.keys(errorObj).length > 0) return;
  //
  //     const obj = {
  //         name,
  //         contact,
  //         address,
  //         tin,
  //         country,
  //         timeZone,
  //         vatNumber,
  //         language,
  //         companyEmail,
  //         contactName,
  //         contactDOB,
  //         contactEmail,
  //         contactNumber
  //     };
  //
  //     createCompany(obj)
  //         .then(response => {
  //             toast.success(response.data.message);
  //             navigate('/dashboard');
  //         })
  //         .catch(error => {
  //             toast.error(Utils.getErrorMessage(error));
  //         });
  // };

  return (
    <div className="signup-content">
      <h2>Signup</h2>
      <form method={"post"} onSubmit={onFormSubmit}>
        <div className="logo-user">
          <img src={signupLogoImg} alt="" />
        </div>
        <div className="row">
          <div className="col-xl-4">
            <Label className="col-form-label">{"Company name"}</Label>

            <input
              type="text"
              id=""
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Company name"
            />
            {errors.name && <span className="input-error">{errors.name}</span>}
          </div>

          <div className="col-xl-4">
            <Label className="col-form-label">{"Contact"}</Label>
            <input
              type="number"
              id=""
              className="form-control"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              placeholder="Contact"
            />
            {errors.contact && (
              <span className="input-error">{errors.contact}</span>
            )}
          </div>

          <div className="col-xl-4">
            <Label className="col-form-label">{"Add logo"}</Label>
            <div className="upload-btn-wrapper">
              <button className="btn">
                <img src={uploadIcon} alt="" />
              </button>
              <input
                type="file"
                accept="image/*"
                name="myfile"
                onChange={handleFileChange}
              />
            </div>
            {selectedFileName && <span>{selectedFileName}</span>}
          </div>

          <div className="col-xl-12">
            <Label className="col-form-label">{"Address"}</Label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="1"
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Address"
              defaultValue={address}
            ></textarea>
            {errors.address && (
              <span className="input-error">{errors.address}</span>
            )}
          </div>
          <div className="col-xl-4">
            <Label className="col-form-label">{"Tin"}</Label>
            <input
              type="text"
              id=""
              className="form-control"
              value={tin}
              onChange={(e) => setTin(e.target.value)}
              placeholder="Tin"
            />
            {/* {errors.tin && <span className="input-error">{errors.tin}</span>} */}
          </div>
          <div className="col-xl-4">
            <Label className="col-form-label">{"Select Country"}</Label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={country}
              onChange={onUpdateCountry}
            >
              <option value="">Select country</option>
              {countryList?.length > 0 &&
                countryList.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
            </select>
            {errors.country && (
              <span className="input-error">{errors.country}</span>
            )}
          </div>
          <div className="col-xl-4">
            <Label className="col-form-label">{"Select Timezone"}</Label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={timeZone}
              onChange={(e) => setTimeZone(e.target.value)}
            >
              {isTimezoneLoad ? (
                "Loading..."
              ) : (
                <>
                  <option value="">Select timezone</option>
                  {timeZoneList?.length > 0 &&
                    timeZoneList?.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                </>
              )}
            </select>
            {errors.timeZone && (
              <span className="input-error">{errors.timeZone}</span>
            )}
          </div>
          <div className="col-xl-4">
            <Label className="col-form-label">{"Vat Number"}</Label>
            <input
              type="number"
              id=""
              className="form-control"
              value={vatNumber}
              onChange={(e) => setVatNumber(e.target.value)}
              placeholder="Vat Number"
            />
            {/* {errors.vatNumber && (
              <span className="input-error">{errors.vatNumber}</span>
            )} */}
          </div>
          <div className="col-xl-4">
            <Label className="col-form-label">{"Select Language"}</Label>
            <select
              className="form-select"
              aria-label="language"
              onChange={(e) => setLanguage(e.target.value)}
              value={language}
            >
              <option value="">Select language</option>
              {languageList?.length > 0 &&
                languageList.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
            </select>
            {errors.language && (
              <span className="input-error">{errors.language}</span>
            )}
          </div>
          <div className="col-xl-4">
            <Label className="col-form-label">{"Company Email"}</Label>
            <input
              type="email"
              id="email"
              className="form-control"
              value={companyEmail}
              onChange={(e) => setCompanyEmail(e.target.value)}
              placeholder="Company email"
            />
            {errors.companyEmail && (
              <span className="input-error">{errors.companyEmail}</span>
            )}
          </div>
          <div className="line-border"></div>
          <div className="col-xl-4">
            <Label className="col-form-label">{"Contact Name"}</Label>
            <input
              type="text"
              id=""
              className="form-control"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              placeholder="Contact name"
            />
            {errors.contactName && (
              <span className="input-error">{errors.contactName}</span>
            )}
          </div>
          <div className="col-xl-4">
            <Label className="col-form-label">{"Contact DOB"}</Label>
            <input
              type="date"
              id=""
              className="form-control"
              value={contactDOB}
              onChange={(e) => setContactDOB(e.target.value)}
              placeholder="Contact DOB"
            />
            {errors.contactDOB && (
              <span className="input-error">{errors.contactDOB}</span>
            )}
          </div>
          <div className="col-xl-4">
            <Label className="col-form-label">{"Contact Email"}</Label>
            <input
              type="email"
              id="email"
              className="form-control"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              placeholder="Contact email"
            />
            {errors.contactEmail && (
              <span className="input-error">{errors.contactEmail}</span>
            )}
          </div>
          <div className="col-xl-4">
            <Label className="col-form-label">{"Contact Number"}</Label>
            <input
              type="number"
              id=""
              className="form-control"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              placeholder="Contact number"
            />
            {errors.contactNumber && (
              <span className="input-error">{errors.contactNumber}</span>
            )}
          </div>
          <div className="col-xl-12 mt-3">
            <button type="submit" className="btn btn-submit me-2">
              Submit
            </button>
            <NavLink
              to={`/login`}
              role={`button`}
              className="btn btn-submit"
            >{`Login`}</NavLink>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CompanyRegistration;
