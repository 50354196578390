import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";

import ConfigDB from "../../config";
import {
  getVehicleList,
  getDeviceHelathAllList,
  getDeviceImeiList,
} from "../../services/VehicleService";
import Select from "react-select";
import Utils from "../../utils";
import { toast } from "react-toastify";
import moment from "moment";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const DeviceHelp = () => {
  const navigate = useNavigate();
  const tableLengthList = ConfigDB.data.dataTableLength;
  const [helathData, setHelathData] = useState([]);
  const [PopIMEIData, setPopIMEIData] = useState({});

  const sideBarCanvasRef = useRef(null);

  const [params] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const [vehicleList, setVehicleList] = useState([]);
  const [vehicle, setVehicle] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [open, setOpen] = useState(false);

  const handleSelect = (ranges) => {
    setState([ranges.selection]);
  };

  const togglePopup = (item) => {
    setIsPopupOpen(!isPopupOpen);
    setPopIMEIData(item);
    sideBarCanvasRef?.current?.click();
  };

  const togglePopupClose = () => {
    setIsPopupOpen(false);
  };

  let formattedServerDate;
  if (vehicle.vehicleId) {
    formattedServerDate = moment(helathData.server_time).format(
      "YYYY-MM-DD HH:mm:ss"
    );
  }

  useEffect(
    function () {
      let data = params;
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
              device_imei: response.data[i].device_imei,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  const togglePicker = () => {
    setOpen(!open);
  };
  const formatDate = (date, isEndDate = false) => {
    const dateString = date.toISOString().slice(0, 10);
    const timeString = isEndDate ? "23:59:59" : "00:00:00";
    return `${dateString} ${timeString}`;
  };

  useEffect(() => {
    if (vehicle.vehicleId) {
      const endDate = new Date(state[0].endDate);
      endDate.setDate(endDate.getDate() + 1);

      const startDate = new Date(state[0].startDate);
      startDate.setDate(startDate.getDate() + 1);

      let data = {
        id: vehicle.vehicleId,
        startDate: formatDate(state[0].startDate),
        endDate: formatDate(state[0].endDate, true),
      };
      getDeviceHelathAllList(data)
        .then((response) => {
          setHelathData(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [vehicle.vehicleId, state]);

  return (
    <div>
      <div>
        <div className="inner-header py-3">
          <div className="left-block">
            {/* <h5>Device Health Dashboard</h5> */}
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-9">
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "22px",
                }}

              >
                <div className="clander-datepicker">
                <Button onClick={togglePicker}>
                  {open ? "" : ""} {state[0].startDate.toDateString()} -{" "}
                  {state[0].endDate.toDateString()}
                </Button>
                {open && (
                <div className="Date-Range"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <DateRange
                    editableDateInputs={true}
                    onChange={handleSelect}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                  />
                </div>
              )}
                </div>
               
           
              </div>

            
            </div>
            <div className="col-xl-3">
              <div className="vehiclist-box">
                <div className="mb-2">
                  <Select
                    options={vehicleList}
                    value={vehicleList?.find(
                      (option) => option.value === vehicle
                    )}
                    onChange={(selectedOption) =>
                      setVehicle((prevData) => ({
                        ...prevData,
                        vehicleId: selectedOption.value,
                        device_imei: selectedOption.device_imei,
                      }))
                    }
                    placeholder={"Select Vehicle"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <section
            className="section-healthdashboard"
            style={{ height: "auto" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      <p></p>
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>Device Status</h6>
                      </div>
                      <div className="flout">
                        <p>Active</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card health-dashboard-card">
                    <div className="card-inner-block-2">
                      {/* <p>{formattedServerDate}</p> */}
                    </div>
                    <div className="card-title">
                      <div className="card-inner-block">
                        <h6>Data Transmission</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="device-health-dashboard-section">
              <div className="container-fluid">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" style={{ color: "white" }}>
                            timestamp
                          </th>
                          <th scope="col" style={{ color: "white" }}>
                            server.timestamp
                          </th>
                          <th scope="col" style={{ color: "white" }}>
                            ident
                          </th>
                          <th scope="col" style={{ color: "white" }}>
                            poistion.latitude
                          </th>
                          <th scope="col" style={{ color: "white" }}>
                            poistion.longitude
                          </th>
                          <th scope="col" style={{ color: "white" }}>
                            poistion.altitude
                          </th>
                          <th scope="col" style={{ color: "white" }}>
                            speed
                          </th>
                          <th
                            scope="col"
                            style={{ color: "white", cursor: "pointer" }}
                          >
                            etc
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {helathData?.map((item, index) => {
                          let formattedTimeStampDate = moment(
                            item.timestamp
                          ).format("YYYY-MM-DD HH:mm:ss");
                          let formattedServerDate = moment(
                            item.server_time
                          ).format("YYYY-MM-DD HH:mm:ss");
                          return (
                            <>
                              <tr key={index}>
                                <td
                                  style={{ color: "white", cursor: "pointer" }}
                                  onClick={() => {
                                    togglePopup(item);
                                  }}
                                >
                                  {formattedTimeStampDate}
                                </td>
                                <td
                                  style={{ color: "white", cursor: "pointer" }}
                                  onClick={() => {
                                    togglePopup(item);
                                  }}
                                >
                                  {formattedServerDate}
                                </td>
                                <td
                                  style={{ color: "white", cursor: "pointer" }}
                                  onClick={() => {
                                    togglePopup(item);
                                  }}
                                >
                                  {item.device_imei}
                                </td>

                                <td
                                  style={{ color: "white", cursor: "pointer" }}
                                  onClick={() => {
                                    togglePopup(item);
                                  }}
                                >
                                  {item?.latitude}
                                </td>
                                <td
                                  style={{ color: "white", cursor: "pointer" }}
                                  onClick={() => {
                                    togglePopup(item);
                                  }}
                                >
                                  {item?.longitude}
                                </td>
                                <td
                                  style={{ color: "white", cursor: "pointer" }}
                                  onClick={() => {
                                    togglePopup(item);
                                  }}
                                >
                                  {item?.altitude}
                                </td>
                                <td
                                  style={{ color: "white", cursor: "pointer" }}
                                  onClick={() => {
                                    togglePopup(item);
                                  }}
                                >
                                  {item.speed}
                                </td>
                                <td
                                  style={{ color: "white", cursor: "pointer" }}
                                  onClick={() => {
                                    togglePopup(item);
                                  }}
                                >
                                  battery_current:{item.battery_current || 0}{" "}
                                  battery_voltage:{item.battery_voltage}{" "}
                                  priority:
                                  {item.priority}{" "}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                        {helathData && helathData?.length === 0 && (
                          <tr>
                            <td
                              colSpan="10"
                              className={`text-center`}
                              style={{ color: "white" }}
                            >{`Record Not Found`}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
      {/* <Modal isOpen={isPopupOpen}>
        <ModalHeader>{`Device Data`}</ModalHeader>
        <ModalBody>
          <div>
            <h5>Data Details:</h5>
            <pre>{JSON.stringify(PopIMEIData, null, 2)}</pre>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglePopupClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal> */}
      <span
        ref={sideBarCanvasRef}
        data-bs-toggle="offcanvas"
        data-bs-target="#off-Canvas-Right"
        aria-controls="off-Canvas-Right"
      />
      <div
        class="offcanvas offcanvas-end off-canvas-right"
        tabindex="-1"
        id="off-Canvas-Right"
        aria-labelledby="offcanvasRightLabel"
        isOpen={isPopupOpen}
      >
        <div class="offcanvas-header">
          <h5 class="devices-title">Device Data</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div className="canvas-inner-body">
            <h5>Data Details:{PopIMEIData?.device_imei}</h5>
            <pre>{JSON.stringify(PopIMEIData, null, 2)}</pre>
          </div>
        </div>
      
      </div>
    </div>
  );
};

export default DeviceHelp;
