import configDb from "../config";
import FileDownload from "js-file-download";
import Axios from "axios";
import { toast } from "react-toastify";

class Utils {
  static setInt = null;
  static getErrorMessage(err) {
    let error = "Oops...something went wrong.";
    if (err.response && err.response.data) {
      error = err.response.data.message;
    } else if (err.message) {
      error = err.message;
    }
    return error;
  }

  static setDetail(data) {
    data = JSON.stringify(data);
    localStorage.setItem(configDb.data.login_data, data);
  }

  static setIntervalRemove() {
    this.setInt = setInterval(() => {
      localStorage.clear();
    }, 1000 * 60 * 60 * 24);
  }

  static removeSetInterval() {
    if (this.setInt) clearTimeout(this.setInt);
  }

  static loginDetail() {
    const data = localStorage.getItem(configDb.data.login_data);
    if (data) {
      return JSON.parse(data);
    } else {
      return {};
    }
  }

  static getPermissionName(path) {
    let split = path.split("/");
    let name = "";
    if (split.length === 2) {
      name = "view_" + split[1].replace("-", "_");
    } else if (split.length > 2) {
      name = "modify_" + split[1].replace("-", "_");
    }
    return name;
  }

  static getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  static async downloadAnyFile(fileUrl, fileName) {
    await Axios({
      url: fileUrl,
      method: "GET",
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "*",
      },
    })
      .then((res) => {
        FileDownload(res.data, fileName);
      })
      .catch((err) => {
        toast.error("Oops...something went wrong. File not found.");
      });

    return true;
  }
}

export default Utils;
