import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getVehicleList } from "../../services/VehicleService";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

const tableLengthList = ConfigDB.data.dataTableLength;

const Vehicles = ({ onSelectionChange, addFiltersData }) => {
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  let filterData = localStorage.getItem("filter");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    filter_id: filterData,
  });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (addFiltersData?.uuid) {
      setSelectedVehicles([addFiltersData.vehicle_total]);
    }
  }, []);

  const handleCheckboxChange = (e, item) => {
    const isChecked = e.target.checked;
    setSelectedVehicles((prevSelected) => {
      const newSelection = isChecked
        ? [...prevSelected, item.id]
        : prevSelected.filter((id) => id !== item.id);
      onSelectionChange(newSelection);
      return newSelection;
    });
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const newSelection = isChecked
      ? vehicleList.data.map((item) => item.id)
      : [];
    setSelectedVehicles(newSelection);
    onSelectionChange(newSelection);
  };

  useEffect(
    function () {
      let data = { ...params };
      setInterval(function () {
        if (data.filter_id !== localStorage.getItem("filter")) {
          data.filter_id = localStorage.getItem("filter");
          data.is_reload = !data.is_reload;
          setParams(data);
        }
      }, 1000);
    },
    [params]
  );

  useEffect(() => {
    let data = { ...params };
    setIsLoading(true);
    getVehicleList(data)
      .then((response) => {
        setVehicleList(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // toast.error(Utils.getErrorMessage(err));
      });
  }, [params]);

  return (
    <div>
      <div className="container-event">
        <div className="page-table-row vehicle-td">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope="col">
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={
                      vehicleList?.data?.length > 0 &&
                      selectedVehicles.length === vehicleList?.data?.length
                    }
                  />
                </th>
                <th scope="col">Driver Name</th>
                <th scope="col">Vehicle Name</th>
                <th scope="col">Vehicle Plate</th>

                <th scope="col">Model Year</th>
              </tr>
            </thead>
            {vehicleList?.data?.map((item, i) => (
              <tr key={i}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedVehicles.includes(item.id)}
                    onChange={(e) => handleCheckboxChange(e, item)}
                  />
                </td>
                <td>{item.driver_name || "-"}</td>
                <td>{item.vehicleName}</td>
                <td>{item.vehiclePlate}</td>

                <td>{item.modelYear}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Vehicles;
