import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import validator from "validator";

import loginLogoImg from "../../assets/images/logo.svg";
import {
  resetPassword,
  actionResetPasswordCheckToken,
} from "../../services/AuthService";
import Utils from "../../utils";
import configDb from "../../config";

function ResetPassword() {
  const { token } = useParams();
  let errorsObj = { password: "", confirmPassword: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const [linkExpMsg, setLinkExpMsg] = useState("");
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(
    function () {
      let data = { token: token };
      actionResetPasswordCheckToken(data)
        .then((response) => {})
        .catch((err) => {
          setLinkExpMsg(Utils.getErrorMessage(err));
        });
    },
    [token]
  );

  const onResetPassword = (e) => {
    e.preventDefault();
    let errorObj = { ...errorsObj };
    let error = false;

    if (!password) {
      errorObj.password = "Password is required";
      error = true;
    } else if (
      !validator.isStrongPassword(password, configDb.data.strongPassword)
    ) {
      errorObj.password =
        "Your password must be more than 6 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.";
      error = true;
    }

    if (!confirmPassword) {
      errorObj.confirmPassword = "Confirm password is required";
      error = true;
    } else if (password !== confirmPassword) {
      errorObj.confirmPassword =
        "New password and confirm password does not match";
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    let obj = {
      token: token,
      password: password,
    };

    resetPassword(obj)
      .then((response) => {
        toast.success(response.data.message);
        navigate("/");
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      });
  };

  return (
    <>
      <div className="login-content">
        {linkExpMsg ? (
          <>
            <p>{linkExpMsg}</p>
          </>
        ) : (
          <form method={`post`} onSubmit={onResetPassword}>
            <div className="logo-user">
              <img src={loginLogoImg} alt="login-logo" />
            </div>

            <div className="input-div input-group">
              <input
                className="form-control"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <span className="input-group-text">
                <i className="bi bi-lock"></i>
              </span>
              {errors.password && (
                <span className="input-error">{errors.password}</span>
              )}
            </div>

            <div className="input-div input-group">
              <input
                className="form-control"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
              />
              <span className="input-group-text">
                <i className="bi bi-lock"></i>
              </span>
              {errors.confirmPassword && <span>{errors.confirmPassword}</span>}
            </div>

            <div className="button-row">
              <input
                type="submit"
                className="btn login"
                value="Save Password"
                style={{
                  width: "100%",
                  fontSize: "16px",
                }}
              />
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default ResetPassword;
