import {configureStore} from '@reduxjs/toolkit';

import {authReducer} from "./auth.slice";
import {loaderReducer} from "./loader.slice";
import {permissionReducer} from "./permission.slice";
import {subscriptionReducer} from "./subscription.slice";
import {subscriptionPlanReducer} from "./subscription_plan.slice";

export * from './auth.slice';
export * from './permission.slice';
export * from './loader.slice';
export * from './subscription.slice';
export * from './subscription_plan.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        permission: permissionReducer,
        load: loaderReducer,
        subscription: subscriptionReducer,
        subscriptionPlan: subscriptionPlanReducer
    }
});