import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import ConfigDB from "../config";
import {subscriptionActions} from "../store";

export const PrivateRoute = () => {
    let auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();
    if (typeof auth != "object") {
        auth = JSON.parse(auth);
    }
    if (!auth?.access_token) {
        return <Navigate to="/"/>
    } else {
        let subscription = true;
        if (auth?.user_role != ConfigDB.data.ROLE_ADMIN) {
            subscription = Object.keys(auth.subscription_data).length > 0;
        }
        dispatch(subscriptionActions.subscription(subscription));
        return <Outlet/>;
    }
};
export const PublicRoute = () => {
    let auth = useSelector(x => x.auth.value);
    if (typeof auth != "object") {
        auth = JSON.parse(auth);
    }
    if (auth?.access_token) {
        if(parseInt(auth.user_role) === parseInt(ConfigDB.data.ROLE_ADMIN)){
            return <Navigate to="/company"/>
        }
        return <Navigate to="/dashboard"/>
    } else {
        return <Outlet/>;
    }
};

export const ManageMenu = (menuList) => {
    const userPermission = useSelector(x => x.permission.value);
    let list = [];
    for (let i = 0; i < menuList.length; i++) {
        if (menuList[i]['type'] === 'link') {
            if (menuList[i]['access']) {
                if (userPermission.findIndex((e) => e.name == menuList[i]['access']) > -1) {
                    list.push(menuList[i]);
                }
            } else {
                list.push(menuList[i]);
            }
        } else if (menuList[i]['type'] === 'sub') {
            let children = [];
            for (let c = 0; c < menuList[i]['children'].length; c++) {
                if (menuList[i]['children'][c]['access']) {
                    if (userPermission.indexOf(menuList[i]['children'][c]['access']) > -1) {
                        children.push(menuList[i]['children'][c]);
                    }
                } else {
                    children.push(menuList[i]['children'][c]);
                }
            }
            if (children.length > 0) {
                menuList[i]['children'] = children;
                list.push(menuList[i]);
            }
        }
    }
    return list;
};