import React from "react";

import LeftHeader from "./LeftHeader";
import RightHeader from "./RightHeader";

function Header() {
    return (
        <div className="app-header header-shadow">
            <LeftHeader/>
            <RightHeader/>
        </div>
    );
}

export default Header;