import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const name = 'permission';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({name, initialState, reducers});

export const permissionActions = {...slice.actions, ...extraActions};
export const permissionReducer = slice.reducer;

function createInitialState() {
    return {
        value: []
    }
}

function createReducers() {
    return {
        setPermission
    };

    function setPermission(state, action) {
        state.value = action.payload;
    }
}

function createExtraActions() {
    return {
        permission: permission()
    };

    function permission() {
        return createAsyncThunk(
            `${name}/permission`,
            function (arg, {dispatch}) {
                dispatch(permissionActions.setPermission(arg));
            }
        );
    }
}