import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useNavigate, NavLink } from "react-router-dom";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus-i.svg";
import {
  actionClassVehicle,
  actionDelVehicle,
  actionUpdateVehicle,
  getVehicleList,
} from "../../services/VehicleService";
import { actionCompanyList } from "../../services/Common";
import Select from "react-select";
import { getDriverList } from "../../services/TripService";
import AddVehicle from "./AddVehicle";

const tableLengthList = configDb.data.dataTableLength;

const Vehicle = () => {
  const subscriptionTypeList = ConfigDB.data.SUBSCRIPTION_TYPE_LIST;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);
  const navigate = useNavigate();
  let filterData = localStorage.getItem("filter");
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [vehicleList, setVehicleList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [modifyDetailsModal, setModifyDetailsModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [delSubscription, setDelSubscription] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [demo, setDemo] = useState([]);
  const [driver, setDriver] = useState("");
  const [vehicleData, setVehicleData] = useState({
    uuid: "",
    vehicleName: "",
    modelYear: "",
    vehicleClass: "",
    vehiclePlate: "",
    maintainanceKm: "",
    companyId: "",
    deviceImei: "",
    driverId: "",
    speed: "",
    total_km_after_maintanance: "",
    vehicle_model: "",
  });
  const [DriverList, setDriverList] = useState([]);
  const [vehicleClasssList, setVehicleClasssList] = useState([]);
  const [vehicleClassFilterList, setVehicleClassFilterList] = useState([]);

  const [companyList, setCompanyList] = useState([]);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [vehicleClass, setVehicleClass] = useState("");
  const [AddVehicleStatus, setAddVehicleStatus] = useState(false);
  const [VehicleStatusData, setVehicleAddData] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  let errorsObj = {
    vehicleName: "",
    modelYear: "",
    vehicleClass: "",
    vehiclePlate: "",
    maintainanceKm: "",
    companyId: "",
    // driver:""
  };
  const [errors, setErrors] = useState(errorsObj);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
    vehicle_class: vehicleClass.vehicle_class,
    filter_id: filterData,
  });

  const handleVehicle = (e, item) => {
    setAddVehicleStatus(true);
    setVehicleAddData(item);
  };

  const selectedCompany = companyList.find(
    (item) => item.value == vehicleData.companyId
  );
  const selectedClass = vehicleClasssList.find(
    (item) => item.value == vehicleData.vehicleClass
  );

  useEffect(
    function () {
      let data = { ...params };
      setInterval(function () {
        if (data.filter_id !== localStorage.getItem("filter")) {
          data.filter_id = localStorage.getItem("filter");
          data.is_reload = !data.is_reload;
          setParams(data);
        }
      }, 1000);
    },
    [params]
  );

  useEffect(function () {
    actionCompanyList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setCompanyList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(
    function () {
      let data = params;
      getDriverList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setDriverList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      if (
        vehicleData.companyId &&
        JsonParse.user_role == ConfigDB.data.ROLE_ADMIN
      ) {
        const data = {
          companyId: vehicleData.companyId,
        };
        actionClassVehicle(data)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].vehicle_class,
              });
            }

            setVehicleClasssList(list);
          })
          .catch((err) => {});
      }
    },
    [vehicleData.companyId]
  );

  useEffect(() => {
    let data = params;
    actionClassVehicle(data)
      .then((response) => {
        response = response.data;
        let list = response.data.map((vehicle) => ({
          value: vehicle.id,
          label: vehicle.vehicle_class,
        }));
        setVehicleClassFilterList(list);
      })
      .catch((err) => {});
  }, [params]);

  useEffect(function () {
    if (JsonParse.user_role == ConfigDB.data.ROLE_COMPANY) {
      actionClassVehicle(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicle_class,
            });
          }

          setVehicleClasssList(list);
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(
    function () {
      let data = { ...params };
      setIsLoading(true);
      getVehicleList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setVehicleList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    const getValue = (e) => (e ? (e.target ? e.target.value : e.value) : null);
    if (type === "length") {
      paramsObj.limit = getValue(e);
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = getValue(e);
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "device_imei") {
      paramsObj.page = 1;
      paramsObj.device_imei = getValue(e);
    } else if (type === "vehicleClassId") {
      paramsObj.page = 1;
      paramsObj[type === "vehicleClassId" ? "vehicle_class" : ""] = getValue(e);
    }

    setParams(paramsObj);
  };

  const onAddSubscription = (e) => {
    e.preventDefault();
    setVehicleData({
      vehicleName: "",
      modelYear: "",
      vehicleClass: "",
      vehiclePlate: "",
      maintainanceKm: "",
      deviceImei: "",
      driverId: "",
      speed: "",
      total_km_after_maintanance: "",
      vehicle_model: "",
    });
    setModifyModal(true);
  };

  const onEditSubscription = (e, item) => {
    e.preventDefault();
    setVehicleData({
      uuid: item.uuid,
      vehicleName: item.vehicleName,
      modelYear: item.modelYear,
      vehicleClass: item.vehicleClass,
      companyId: item.companyId,
      vehiclePlate: item.vehiclePlate,
      maintainanceKm: item.maintainanceKm,
      deviceImei: item.device_imei,
      driverId: item.driverId,
      speed: item.speed,
      total_km_after_maintanance: item.total_km_after_maintanance,
      vehicle_model: item.vehicle_model,
    });
    setDemo(item);
    setDriver(item.driverId);
    setModifyModal(true);
  };

  const onDetailsShowSubscription = (e, item) => {
    e.preventDefault();
    setVehicleData({
      uuid: item.uuid,
      vehicleName: item.vehicleName,
      modelYear: item.modelYear,
      vehicleClass: item.vehicleClass,
      companyId: item.companyId,
      vehiclePlate: item.vehiclePlate,
      maintainanceKm: item.maintainanceKm,
      deviceImei: item.device_imei,
      driverId: item.driverId,
      speed: item.speed,
      total_km_after_maintanance: item.total_km_after_maintanance,
      vehicle_model: item.vehicle_model,
    });
    setDemo(item);
    setDriver(item.driverId);
    setModifyDetailsModal(true);
  };

  const onDetailsModalClose = (e) => {
    setModifyDetailsModal(false);
  };

  const onDeleteSubscription = (e, item) => {
    e.preventDefault();
    setDelSubscription(item.uuid);
    setDelModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelSubscription("");
    setDelModal(false);
  };

  const onDestroyRole = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delSubscription };
    actionDelVehicle(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelSubscription("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleVehicleForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!vehicleData.vehicleName) {
      errorObj.vehicleName = `Vehicle Name required.`;
      error = true;
    }

    if (!/^\d+$/.test(vehicleData.modelYear)) {
      errorObj.modelYear = "Make model year must contain only numbers.";
      error = true;
    }

    if (!vehicleData.vehicleClass) {
      errorObj.vehicleClass = `Select vehicle class.`;
      error = true;
    }
    if (JsonParse.user_role == ConfigDB.data.ROLE_ADMIN) {
      if (!vehicleData.companyId) {
        errorObj.companyId = `Select Company is required`;
        error = true;
      }
    }

    if (!vehicleData.vehiclePlate) {
      errorObj.vehiclePlate = `Vehicle Plate required.`;
      error = true;
    }

    // if (!vehicleData.speed) {
    //   errorObj.speed = `Vehicle speed required.`;
    //   error = true;
    // }

    // if (!vehicleData.driverId) {
    //   console.log("vehicleData.driverId",vehicleData.driverId)
    //   errorObj.driver = `driver required.`;
    //   error = true;
    // }

    // if (!vehicleData.maintainanceKm) {
    //   errorObj.maintainanceKm = `Vehicle MaintainanceKm required.`;
    //   error = true;
    // }

    setErrors(errorObj);
    if (error) return;

    let data = {
      vehicleName: vehicleData.vehicleName,
      modelYear: vehicleData.modelYear,
      vehicleClass: vehicleData.vehicleClass,
      vehiclePlate: vehicleData.vehiclePlate,
      // maintainanceKm: vehicleData.maintainanceKm,
      deviceImei: vehicleData.deviceImei,
      driverId: driver.driverId,
      vehicle_model: vehicleData.vehicle_model,
      // speed: vehicleData.speed,
      // total_km_after_maintanance: vehicleData.total_km_after_maintanance,
    };

    if (JsonParse.user_role === ConfigDB.data.ROLE_ADMIN) {
      data.companyId = vehicleData.companyId;
    }

    let updateData = {
      vehicleName: vehicleData.vehicleName,
      modelYear: vehicleData.modelYear,
      vehicleClass: vehicleData.vehicleClass,
      vehiclePlate: vehicleData.vehiclePlate,
      // maintainanceKm: vehicleData.maintainanceKm,
      deviceImei: vehicleData.deviceImei,
      uuid: vehicleData.uuid,
      companyId: vehicleData.companyId,
      // speed: vehicleData.speed,
      driverId: driver.driverId,
      vehicle_model: vehicleData.vehicle_model,

      // total_km_after_maintanance: vehicleData.total_km_after_maintanance,
    };

    if (JsonParse.user_role === ConfigDB.data.ROLE_ADMIN) {
      updateData.companyId = vehicleData.companyId;
    }

    if (vehicleData?.uuid) {
      modifySubscription(actionUpdateVehicle, updateData);
    } else {
      modifySubscription(actionUpdateVehicle, data);
    }
  };

  const modifySubscription = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setVehicleData({
          vehicleName: "",
          modelYear: "",
          vehicleClass: "",
          vehiclePlate: "",
          maintainanceKm: "",
          deviceImei: "",
          driverId: "",
          speed: "",
          total_km_after_maintanance: "",
          vehicle_model: "",
        });
        setModifyModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const onModifyModalClose = (e) => {
    setVehicleData({
      vehicleName: "",
      modelYear: "",
      vehicleClass: "",
      vehiclePlate: "",
      maintainanceKm: "",
      deviceImei: "",
      driverId: "",
      speed: "",
      total_km_after_maintanance: "",
      vehicle_model: "",
    });
    setModifyModal(false);
    setErrors(errorsObj);
  };

  return (
    <>
      {AddVehicleStatus ? (
        <AddVehicle
          setAddVehicleStatus={setAddVehicleStatus}
          VehicleStatusData={VehicleStatusData}
        />
      ) : (
        <>
          <div className="inner-header py-3">
            <div className="left-block">
              <h5>Vehicles</h5>
            </div>

            <div className="right-block w-auto">
              {allPermissionsList.findIndex((e) => e.name == "modify_vehicle") >
                -1 && (
                <Button
                  type={`button`}
                  onClick={handleVehicle}
                  className={`add-btn btn  float-right`}
                >
                  {`Add Vehicle`}
                  <i className="plus-circle">
                    <img src={plusIcon} alt="" className="plus-icons" />
                  </i>
                </Button>
              )}
            </div>
          </div>

          <div className="container-event">
            <div className="page-header-row company-section-Area">
              <div className="row">
                <div className="col">
                  <div className="row d-flex justify-content-between company-section">
                    <div className="col-xl-3">
                      <div className="selected-block">
                        <p>Show</p>
                        <select
                          className="form-select"
                          aria-label="Events Only"
                          defaultValue={params.limit}
                          onChange={(e) => handleParams(e, "length")}
                        >
                          {tableLengthList &&
                            tableLengthList.map((item, i) => (
                              <option value={item} key={i}>
                                {item}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    {/* <div className="col">
                  <div className="selected-block selected-mobile">
                    <p>Device IMEI</p>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Device IMEI"
                        value={params.device_imei}
                        onChange={(e) => handleParams(e, "device_imei")}
                      />
                    </div>
                  </div>
                </div> */}

                    <div className="col">
                      <div className="selected-block selected-mobile">
                        <p>Vehicle Class</p>
                        <Select
                          options={vehicleClassFilterList}
                          value={vehicleClassFilterList?.find(
                            (option) => option.value === params.vehicle_class
                          )}
                          onChange={(selectedOption) => {
                            if (selectedOption) {
                              setVehicleClass((prevData) => ({
                                ...prevData,
                                vehicle_class: selectedOption.value,
                              }));
                              handleParams(selectedOption, "vehicleClassId");
                            } else {
                              setVehicleClass((prevData) => ({
                                ...prevData,
                                vehicle_class: null,
                              }));
                              handleParams(null, "vehicleClassId");
                            }
                          }}
                          placeholder="Select Vehicle Class"
                          isClearable={true}
                        />
                      </div>
                    </div>

                    <div className="col">
                      <div className="selected-block selected-mobile">
                        <p>Search</p>
                        <div className="search-bar">
                          <i className="bi bi-search"></i>
                          <input
                            type="text"
                            className="form-control"
                            value={params.search}
                            onChange={(e) => handleParams(e, "search")}
                            placeholder="Search"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="page-table-row">
              <table
                className={`table table-striped ${isLoading ? "loading" : ""}`}
                width="100%"
              >
                <thead>
                  <tr>
                    <th scope={`col`}>#</th>
                    <th scope={`col`}>{`Vehicle Plate`}</th>
                    <th scope={`col`}>{`Vehicle Name`}</th>
                    <th scope={`col`}>{`Vehicle Class`}</th>
                    <th scope={`col`}>{`Manufacturer`}</th>
                    <th scope={`col`}>{`Model Year`}</th>

                    <th scope={`col`}>{`Vehicle Model`}</th>
                    <th scope={`col`}>{`Driver Name`}</th>
                    <th scope={`col`}>{`Segment`}</th>
                    <th scope={`col`}>{`Department`}</th>
                    <th scope={`col`}>{`Status`}</th>

                    {(allPermissionsList.findIndex(
                      (e) => e.name == "modify_vehicle"
                    ) > -1 ||
                      allPermissionsList.findIndex(
                        (e) => e.name == "delete_vehicle"
                      ) > -1) && <th scope={`col`}>{`Action`}</th>}
                  </tr>
                </thead>
                <tbody>
                  {vehicleList &&
                    vehicleList?.data?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.index}</td>
                          <td
                            style={{ cursor: "pointer", color: "#45abe3" }}
                            onClick={(e) => handleVehicle(e, item)}
                          >
                            {item.vehiclePlate}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.vehicleName}
                          </td>

                          <td style={{ cursor: "pointer" }}>
                            {item.vehicleClassName}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.manufacturer}
                          </td>

                          <td style={{ cursor: "pointer" }}>
                            {item.modelYear}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.vehicle_model || "-"}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.driver_name || "-"}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.segment_name}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.department_name}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.status || "-"}
                          </td>

                          {(allPermissionsList.findIndex(
                            (e) => e.name == "modify_vehicle"
                          ) > -1 ||
                            allPermissionsList.findIndex(
                              (e) => e.name == "delete_vehicle"
                            ) > -1) && (
                            <td>
                              {allPermissionsList.findIndex(
                                (e) => e.name == "modify_vehicle"
                              ) > -1 && (
                                <NavLink
                                  to="#"
                                  role="button"
                                  className="btn btn-info add-btn-circle me-2"
                                  onClick={(e) => handleVehicle(e, item)}
                                >
                                  <i className="bi bi-pencil-fill"></i>
                                </NavLink>
                              )}
                              {allPermissionsList.findIndex(
                                (e) => e.name == "delete_vehicle"
                              ) > -1 && (
                                <button
                                  type={`button`}
                                  onClick={(e) => onDeleteSubscription(e, item)}
                                  className={`btn btn-circle btn-danger add-btn-circle me-2`}
                                >
                                  <i className="bi bi-trash-fill"></i>
                                </button>
                              )}

                              {/* <button
                            type={`button`}
                            //
                            onClick={() => {
                              navigate(`/vehicle/${item.id}/history-details`, {
                                state: item,
                              });
                            }}
                            className={`btn btn-success add-btn-circle me-2`}
                          >
                            <i className="bi bi-eye-fill" />
                          </button> */}
                            </td>
                          )}
                        </tr>
                      );
                    })}

                  {vehicleList && vehicleList?.data?.length === 0 && (
                    <tr>
                      <td
                        colSpan={
                          allPermissionsList.findIndex(
                            (e) => e.name == "modify_vehicle"
                          ) > -1 ||
                          allPermissionsList.findIndex(
                            (e) => e.name == "delete_vehicle"
                          ) > -1
                            ? 7
                            : 7
                        }
                        className={`text-center`}
                      >{`Record Not Found`}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className={`float-right`}>
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              />
            </div>
          </div>
        </>
      )}

      <Modal isOpen={modifyModal}>
        <ModalHeader>
          {vehicleData.uuid ? `Edit vehicle` : `Create vehicle`}
        </ModalHeader>
        <Form method={`post`} onSubmit={handleVehicleForm}>
          <ModalBody>
            <FormGroup>
              <Label className="col-form-label">
                {"Vehicle name"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.vehicleName}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      vehicleName: e.target.value,
                    });
                  }}
                  name={`vehicleName`}
                  maxLength={50}
                  placeholder="Vehicle name"
                />
                {errors.vehicleName && (
                  <span className="input-error">{errors.vehicleName}</span>
                )}
              </div>

              <Label className="col-form-label">
                {"Make Model Year"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  id=""
                  placeholder="Make Model Year"
                  name={`modelYear`}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      modelYear: e.target.value,
                    });
                  }}
                  // disabled={isApproveRejectLoad}
                  defaultValue={vehicleData.modelYear}
                  // onChange={(e) => setReason(e.target.value)}
                ></input>
                {errors.modelYear && (
                  <span className="input-error">{errors.modelYear}</span>
                )}
              </div>

              <Label className="col-form-label">{"Vehcile Model"}</Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.vehicle_model}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      vehicle_model: e.target.value,
                    });
                  }}
                  min={0}
                  name={`vehicle_model`}
                  maxLength={30}
                  placeholder="Vehcile Model"
                />
              </div>

              {JsonParse.user_role == ConfigDB.data.ROLE_ADMIN ? (
                <Label className="col-form-label">
                  {"Company List"}
                  <span className={`text-danger ml-1`}>*</span>
                </Label>
              ) : null}

              {JsonParse.user_role == ConfigDB.data.ROLE_ADMIN ? (
                <div className="mb-3">
                  <Select
                    options={companyList}
                    value={
                      companyList.find(
                        (option) => option.value === vehicleData.companyId
                      ) || selectedCompany
                    }
                    onChange={(selectedOption) =>
                      setVehicleData((prevData) => ({
                        ...prevData,
                        companyId: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Company"}
                  />
                  {errors.companyId && (
                    <span className="input-error">{errors.companyId}</span>
                  )}
                </div>
              ) : null}
              <Label className="col-form-label">
                {"Vehicle Class"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="mb-3">
                {/* <select
                  className="form-select"
                  aria-label="Default select example"
                  value={vehicleData.vehicleClass}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      vehicleClass: e.target.value,
                    });
                  }}
                >
                  <option value="">Select Vehicle Class</option>
                  {vehicleClasssList?.length > 0 &&
                    vehicleClasssList.map((item, index) => {
                      {
                      }
                      return (
                        <option key={index} value={item.value}>
                          {item?.label}
                        </option>
                      );
                    })}
                </select> */}

                <Select
                  options={vehicleClasssList}
                  value={
                    vehicleClasssList.find(
                      (option) => option.value === vehicleData.vehicleClass
                    ) || selectedClass
                  }
                  onChange={(selectedOption) =>
                    setVehicleData((prevData) => ({
                      ...prevData,
                      vehicleClass: selectedOption.value,
                    }))
                  }
                  placeholder={"Select vehicle class"}
                />
                {errors.vehicleClass && (
                  <span className="input-error">{errors.vehicleClass}</span>
                )}
              </div>

              <Label className="col-form-label">
                {"Vehicle Plate"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.vehiclePlate}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      vehiclePlate: e.target.value,
                    });
                  }}
                  min={0}
                  name={`vehiclePlate`}
                  maxLength={50}
                  placeholder="Vehicle Plate"
                />
                {errors.vehiclePlate && (
                  <span className="input-error">{errors.vehiclePlate}</span>
                )}
              </div>

              {/* <Label className="col-form-label">
                {"Vehicle Speed"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.speed}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      speed: e.target.value,
                    });
                  }}
                  min={0}
                  name={`speed`}
                  maxLength={50}
                  placeholder="Speed"
                />
                {errors.speed && (
                  <span className="input-error">{errors.speed}</span>
                )}
              </div>

              <Label className="col-form-label">
                {"MaintainanceKm"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.maintainanceKm}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      maintainanceKm: e.target.value,
                    });
                  }}
                  min={0}
                  name={`maintainanceKm`}
                  maxLength={50}
                  placeholder="MaintainanceKm"
                />
                {errors.maintainanceKm && (
                  <span className="input-error">{errors.maintainanceKm}</span>
                )}
              </div>

              <Label className="col-form-label">
                {"Total km after maintanance"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.total_km_after_maintanance}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      total_km_after_maintanance: e.target.value,
                    });
                  }}
                  min={0}
                  name={`total_km_after_maintanance`}
                  maxLength={50}
                  placeholder="Total km after maintanance"
                />
                {errors.maintainanceKm && (
                  <span className="input-error">{errors.maintainanceKm}</span>
                )}
              </div> */}

              <Label className="col-form-label">{"Device IMEI"}</Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.deviceImei}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      deviceImei: e.target.value,
                    });
                  }}
                  min={0}
                  name={`deviceImei`}
                  maxLength={15}
                  placeholder="Device IMEI"
                />
              </div>

              <Label className="col-form-label">
                {"Driver"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="col-xl-12">
                <div className="input-div">
                  <Select
                    options={DriverList}
                    value={DriverList.find((option) => option.value === driver)}
                    // value={2}
                    onChange={(selectedOption) =>
                      setDriver((prevData) => ({
                        ...prevData,
                        driverId: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Driver"}
                  />
                  {errors.driver && (
                    <span className="input-error">{errors.driver}</span>
                  )}
                </div>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Vehicle`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this vehicle?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroyRole}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyDetailsModal}>
        <ModalHeader>Vehicle Details</ModalHeader>

        <ModalBody>
          <div style={{ fontSize: "15px", fontWeight: "700" }}>
            Vehicle Name:
            <span style={{ color: "gray" }}> {vehicleData.vehicleName}</span>
          </div>
          <div style={{ fontSize: "15px", fontWeight: "700" }}>
            Vehicle Plate:
            <span style={{ color: "gray" }}> {vehicleData.vehiclePlate}</span>
          </div>
          <div style={{ fontSize: "15px", fontWeight: "700" }}>
            Vehicle Class:
            <span style={{ color: "gray" }}> {vehicleData.vehicleClass}</span>
          </div>
          <div style={{ fontSize: "15px", fontWeight: "700" }}>
            Vehicle Model Year:
            <span style={{ color: "gray" }}> {vehicleData.modelYear}</span>
          </div>
          <div style={{ fontSize: "15px", fontWeight: "700" }}>
            Vehicle Speed:
            <span style={{ color: "gray" }}> {vehicleData.speed || "-"}</span>
          </div>
          <div style={{ fontSize: "15px", fontWeight: "700" }}>
            Device IMEI:
            <span style={{ color: "gray" }}>
              {" "}
              {vehicleData.deviceImei || "-"}
            </span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isModifyButtonLoad}
            outline
            onClick={onDetailsModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Vehicle;
