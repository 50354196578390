import axiosInstance from "./AxiosInstance";

export function login(data) {
    return axiosInstance.post(`/login`, data);
}

export function forgotPassword(data) {
    return axiosInstance.post(`/forgot-password`, data);
}

export function resetPassword(data) {
    return axiosInstance.post(`/reset-password`, data);
}

export function actionResetPasswordCheckToken(data) {
    return axiosInstance.post(`/reset-password/check-token`, data);
}

