import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import Organization from "./organization";
import Segment from "./Segment";

const OrganizationMain = ({ setAddDriverStatus, DriverData }) => {
  const [activeTab, setActiveTab] = useState("department");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  return (
    <div>
      <>
        <div className="inner-header py-3 d-none">
          <div className="left-block">{/* <h5>Management</h5> */}</div>
        </div>

        <div className="leaflet-control-management managementui-tab leaflet-control-var">
          <div className="tabs">
            <nav
              className="tab-nav"
              // style={{ display: !showContent ? "block" : "none" }}
            >
              <ul className="tabs-management">
                {/* Sub-tabs container */}
                <li
                  className={`nav-link ${
                    activeTab === "department" ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleTabClick("department");
                  }}
                >
                  <span data-href="#sub-tab-1">Department</span>
                </li>
                <li
                  className={`nav-link ${
                    activeTab === "Segment" ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleTabClick("Segment");
                  }}
                >
                  <span data-href="#sub-tab-2">Segment</span>
                </li>
              </ul>
            </nav>

            <div className="dashboard-block tab-content" id="nav-tabContent">
              <div
                className={`tab-pane fade ${
                  activeTab === "department" ? "show active" : ""
                }`}
                id="sub-tab-1"
              >
                <Organization activeTab={activeTab} />
              </div>

              <div
                className={`tab-pane fade ${
                  activeTab === "Segment" ? "show active" : ""
                }`}
                id="sub-tab-2"
              >
                <Segment activeTab={activeTab} />
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default OrganizationMain;
