import axiosInstance from "./AxiosInstance";

export function actionDetail(data) {
    return axiosInstance.post(`/profile/detail`, data);
}

export function actionChangePassword(data) {
    return axiosInstance.post(`/profile/change-password`, data);
}

export function actionChangeImage(data) {
    return axiosInstance.post(`/profile/change-profile`, data);
}

export function actionChangeGeneral(data) {
    return axiosInstance.post(`/profile/change-detail`, data);
}