import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

import Select from "react-select";

import { useNavigate } from "react-router-dom";
import { createAccountDrivingCondition } from "../../services/ManagementService";

const tableLengthList = configDb.data.dataTableLength;

const DriverType = [
  {
    value: "1",
    label: "Never",
  },
  { value: "2", label: "CANBus vehicle only" },
  { value: "3", label: "Always" },
];

const DrivingCondition = ({ setActiveTab, AccountDetails }) => {
  const navigate = useNavigate();

  const [DrivingData, setDrivingData] = useState({
    allow_driving_teams: 0,
    split_trips: "",
    allow_coupling_vehicles: 0,
    use_driving_time_restriction_rules: 0,
    allow_two_consecutive_weekly_rests: 0,
  });

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
  });

  const handleCheckboxChangeAllowDriving = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDrivingData({
      ...DrivingData,
      allow_driving_teams: newValue,
    });
  };

  const handleCheckboxCoupling = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDrivingData({
      ...DrivingData,
      allow_coupling_vehicles: newValue,
    });
  };

  const handleCheckboxUseDrivingTime = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDrivingData({
      ...DrivingData,
      use_driving_time_restriction_rules: newValue,
    });
  };

  const handleCheckboxWeekly = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDrivingData({
      ...DrivingData,
      allow_two_consecutive_weekly_rests: newValue,
    });
  };

  useEffect(() => {
    if (AccountDetails) {
      setDrivingData({
        allow_driving_teams:
          AccountDetails?.permitted_driving_condition?.allow_driving_teams,
        split_trips: AccountDetails?.permitted_driving_condition?.split_trips,
        allow_coupling_vehicles:
          AccountDetails?.permitted_driving_condition?.allow_coupling_vehicles,
        use_driving_time_restriction_rules:
          AccountDetails?.permitted_driving_condition?.allow_driving_teams,
        allow_two_consecutive_weekly_rests:
          AccountDetails?.permitted_driving_condition
            ?.allow_two_consecutive_weekly_rests,
      });
    }
  }, [AccountDetails]);

  const handleDrivingForm = (e) => {
    e.preventDefault();

    let data = {
      allow_driving_teams: DrivingData?.allow_driving_teams,
      split_trips: DrivingData?.split_trips,
      allow_coupling_vehicles: DrivingData?.allow_coupling_vehicles,
      use_driving_time_restriction_rules:
        DrivingData?.use_driving_time_restriction_rules,
      allow_two_consecutive_weekly_rests:
        DrivingData?.allow_two_consecutive_weekly_rests,
    };

    let Updatedata = {
      uuid: AccountDetails?.permitted_driving_condition?.uuid,
      allow_driving_teams: DrivingData?.allow_driving_teams,
      split_trips: DrivingData?.split_trips,
      allow_coupling_vehicles: DrivingData?.allow_coupling_vehicles,
      use_driving_time_restriction_rules:
        DrivingData?.use_driving_time_restriction_rules,
      allow_two_consecutive_weekly_rests:
        DrivingData?.allow_two_consecutive_weekly_rests,
    };

    if (
      AccountDetails?.permitted_driving_condition !== null &&
      AccountDetails?.permitted_driving_condition?.uuid
    ) {
      modifymanagament(createAccountDrivingCondition, Updatedata);
    } else {
      modifymanagament(createAccountDrivingCondition, data);
    }
  };

  const modifymanagament = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("canbus");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const selectedDriving = DriverType.find(
    (option) => option.value === JSON.stringify(DrivingData.split_trips)
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleDrivingForm}>
              <h4>Driver</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{ border: "solid #eee 1px", borderRadius: "5px" }}
                >
                  <div className="col-md-3">
                    <Label className="col-form-label">
                      Allow the use of driving teams
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="allow_driving_teams"
                          checked={DrivingData.allow_driving_teams}
                          onChange={handleCheckboxChangeAllowDriving}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="allow_driving_teams"
                        >
                          Driving teams
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Split trips when changing driver"}
                    </Label>
                    <div className="mb-3">
                      <Select
                        options={DriverType}
                        value={selectedDriving}
                        onChange={(selectedOption) =>
                          setDrivingData((prevData) => ({
                            ...prevData,
                            split_trips: selectedOption.value,
                          }))
                        }
                        placeholder={"Never"}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h4 className="mb-3">Coupling</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Allow coupling of vehicles and assets
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="allow_coupling_vehicles"
                          checked={DrivingData.allow_coupling_vehicles}
                          onChange={handleCheckboxCoupling}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="allow_coupling_vehicles"
                        >
                          Allow coupling
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4"></div>
                </div>
              </FormGroup>

              <h4 className="mb-3">Driving Time Restrictions</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-3">
                    <Label className="col-form-label">
                      Use driving time restriction rules
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="use_driving_time_restriction_rules"
                          checked={
                            DrivingData.use_driving_time_restriction_rules
                          }
                          onChange={handleCheckboxUseDrivingTime}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="use_driving_time_restriction_rules"
                        >
                          Use driving time
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Allow the use of two consecutive reduced weekly rests
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="allow_two_consecutive_weekly_rests"
                          checked={
                            DrivingData.allow_two_consecutive_weekly_rests
                          }
                          onChange={handleCheckboxWeekly}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="allow_two_consecutive_weekly_rests"
                        >
                          Reduced weekly rests
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrivingCondition;
