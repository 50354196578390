import axiosInstance from "./AxiosInstance";

export function actionUpdateSetting(data) {
    return axiosInstance.post(`/settings/create-update`, data);
}

export function getSettingList(data) {
    return axiosInstance.post(`/settings/list`, data);
}


export function getSettingDetails(data) {
    return axiosInstance.post(`/settings/details-list`, data);
}

export function getSettingDetailsList(data) {
    return axiosInstance.post(`/settings/details`, data);
}

