import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import configDb from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus-i.svg";
import {
  actionDelVehicle,
  actionUpdateworkingPeriodVehicle,
} from "../../services/VehicleService";
import {
  createAccount,
  getAccountList,
} from "../../services/ManagementService";

import TimePicker from "react-time-picker";

const tableLengthList = configDb.data.dataTableLength;

const WorkingPeriod = ({ setActiveTab, vehicleDetails }) => {
  const navigate = useNavigate();
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [vehicleList, setVehicleList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [delSubscription, setDelSubscription] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);

  const [vehicleData, setVehicleData] = useState({
    week_day: "",
    period: "",
    additional_period: "",
  });

  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });
  const [time, setTime] = useState("12:00");
  const [timeAdd, setTimeAdd] = useState("12:00");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);
  const VehicleDataID = localStorage.getItem("vehicleId");

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const handleChange = (newTime) => {
    setTime(newTime);
  };

  const handleChangeAdd = (newTime) => {
    setTimeAdd(newTime);
  };

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getAccountList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setVehicleList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const onAddSubscription = (e) => {
    e.preventDefault();
    setVehicleData({
      week_day: "",
      period: "",
      additional_period: "",
    });
    setModifyModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelSubscription("");
    setDelModal(false);
  };

  const onDestroyRole = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delSubscription };
    actionDelVehicle(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelSubscription("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleVehicleForm = (e) => {
    e.preventDefault();

    let data = {
      week_day: vehicleData?.week_day,
      period: time,
      additional_period: timeAdd,
    };

    let updateData = {
      week_day: vehicleData?.week_day,
      period: time,
      additional_period: timeAdd,
      uuid: vehicleData.uuid,
    };

    if (vehicleData?.uuid) {
      modifySubscription(createAccount, updateData);
    } else {
      modifySubscription(createAccount, data);
    }
  };

  const modifySubscription = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setVehicleData({
          week_day: "",
          period: "",
          additional_period: "",
        });
        setModifyModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const onModifyModalClose = (e) => {
    setVehicleData({
      week_day: "",
      period: "",
      additional_period: "",
    });
    setModifyModal(false);
  };

  const onEditSubscription = (e, item) => {
    e.preventDefault();
    setVehicleData({
      uuid: item.uuid,
      week_day: item?.week_day,
      period: item?.period,
      additional_period: item?.additional_period,
    });
    setTime(item?.period);
    setTimeAdd(item?.additional_period);
    setModifyModal(true);
  };

  const formattedVehicleList = Array.isArray(vehicleList.data)
    ? vehicleList?.data.map((vehicle) => ({
        week_day: vehicle?.week_day,
        period: vehicle?.period,
        additional_period: vehicle?.additional_period,
      }))
    : [];

  const handleVehicleForms = (e) => {
    e.preventDefault();
    const data = {
      working_day: formattedVehicleList,
      vehicle_id: VehicleDataID,
    };
    let updateData = {
      uuid: vehicleDetails?.uuid,
      working_day: formattedVehicleList,
      vehicle_id: vehicleDetails?.id,
    };
    if (vehicleDetails?.uuid) {
      modifymanagament(actionUpdateworkingPeriodVehicle, updateData);
    } else {
      modifymanagament(actionUpdateworkingPeriodVehicle, data);
    }
  };

  const modifymanagament = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("DrivingWorkingPeriod");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="inner-header py-3 mt-3">
            <div className="left-block-2">
              <h5>
                <div>
                  <label className="col-form-label">
                    <input
                      type="checkbox"
                      checked={isCheckboxChecked}
                      onChange={handleCheckboxChange}
                    />
                    Use account definitions
                  </label>
                </div>
              </h5>
            </div>

        <div className="right-block w-auto">
              {!isCheckboxChecked &&
                allPermissionsList.findIndex(
                  (e) => e.name === "modify_vehicle"
                ) > -1 && (
                  <Button
                    type="button"
                    onClick={onAddSubscription}
                    className="add-btn btn float-right"
                  >
                    Add
                    <i className="plus-circle">
                      <img src={plusIcon} alt="" className="plus-icons" />
                    </i>
                  </Button>
                )}
            </div> 
          </div>

          <div className="container-event">
            {!isCheckboxChecked && (
              <div className="page-header-row">
                <div className="row">
                  <div className="col">
                    <div className="row d-flex justify-content-between company-section"></div>
                  </div>
                </div>
              </div>
            )}

            <div className="page-table-row workingP general-ac">
              <table
                className={`table table-striped ${isLoading ? "loading" : ""}`}
                width="100%"
              >
                <thead>
                  <tr>
                    <th scope="col">Week Days</th>
                    <th scope="col">Period</th>
                    <th scope="col">Additional Period</th>
                    {!isCheckboxChecked && <th scope="col">Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {vehicleList &&
                    vehicleList?.data?.map((item, i) => (
                      <tr key={i}>
                        <td style={{ cursor: "pointer" }}>
                          {item.week_day || "-"}
                        </td>
                        <td style={{ cursor: "pointer" }}>
                          {item.additional_period} {item.period}
                        </td>
                        <td style={{ cursor: "pointer" }}>
                          {item.additional_period}
                        </td>
                        {!isCheckboxChecked && (
                          <td style={{ cursor: "pointer" }}>
                            <button
                              type="button"
                              onClick={(e) => onEditSubscription(e, item)}
                              className="btn btn-info add-btn-circle me-2"
                            >
                              <i className="bi bi-pencil-fill"></i>
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}

                  {vehicleList && vehicleList?.data?.length === 0 && (
                    <tr>
                      <td
                        colSpan={!isCheckboxChecked ? 7 : 6}
                        className="text-center"
                      >
                        Record Not Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {!isCheckboxChecked && (
              <div className="float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass="page-item"
                  linkClass="page-link"
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            )}

            {/* {!vehicleDetails?.uuid && (
              <form onSubmit={handleVehicleForms}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    color="success btn-info"
                    type={`submit`}
                    className={`btn-square `}
                  >{`Submit`}</Button>
                </div>
              </form>
            )} */}
          </div>
        </div>
      </div>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Create Account</ModalHeader>
        <Form method={`post`} onSubmit={handleVehicleForm}>
          <ModalBody>
            <FormGroup>
              <Label className="col-form-label">
                {"Week Days"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.week_day}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      week_day: e.target.value,
                    });
                  }}
                  name={`week_day`}
                  maxLength={50}
                  placeholder="Week Days"
                />
              </div>

              <Label className="col-form-label">
                {"Period"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <TimePicker
                onChange={handleChange}
                value={time}
                format={`hh:mm a`}
                className={`react-picker form-control`}
              />
            </FormGroup>

            <FormGroup>
              <Label className="col-form-label">
                {"Additional Period"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <TimePicker
                onChange={handleChangeAdd}
                value={timeAdd}
                format={`hh:mm a`}
                className={`react-picker form-control`}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Vehicle`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this vehicle?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroyRole}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default WorkingPeriod;
