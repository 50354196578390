import React from "react";
import {Outlet} from "react-router-dom";

function AuthLayout() {
    return (
        <div className="login-section">
            <div className="wave"></div>
            <div className="container">
                <div className="description-content">
                    <div className="welcome-content">
                        <h1>Welcome</h1>
                        <p>Manage Your Fleet</p>
                    </div>
                </div>
                <>
                    <Outlet/>
                </>
            </div>
        </div>
    );
}

export default AuthLayout;