import axiosInstance from "./AxiosInstance";

export function actionAddUpdateRoutepath(data) {
    return axiosInstance.post(`/places/create-route-path`, data);
}

export function getRoutepathList(data) {
    return axiosInstance.post(`/places/list-route-path`, data);
}



export function actionDelRoutepath(data) {
    return axiosInstance.post(`/places/delete-route-path`, data);
}
