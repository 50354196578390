import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import GeneralDriver from "./GeneralDriver";
import ProfessonalDriver from "./ProfessonalDriver";
import LicenseDriver from "./LicenseDriver";
import EmergencyDriver from "./EmergencyDriver";
import PersonalInfoDriver from "./PersonalInfoDriver";
import DriverStatus from "./DriverStatus";
import TachnographDriver from "./TachnographDriver";
import {
  getDriverAllDetails,
  getUsersAllDetails,
} from "../../services/EmployeeService";
import PrivacyMode from "./PrivacyMode";
import GeneralEmployee from "./GeneralEmployee";
import FilteringEmployee from "./FilteringEmployee";
import InteractEmployee from "./InteractEmployee";
import LoginUser from "./LoginUser";

const AddEmployee = ({ setAddUserStatus, UserData }) => {
  const [activeTab, setActiveTab] = useState("general");
  const [usersDetails, setUsersDetails] = useState({});
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const { uuid } = useParams();

  useEffect(function () {
    if (UserData?.uuid) {
      let data = {
        uuid: UserData?.uuid,
      };
      getUsersAllDetails(data)
        .then((response) => {
          setUsersDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, []);

  return (
    <div>
      <div className="inner-header py-3 d-none">
        <div className="left-block">
          <h5>Management</h5>
        </div>
      </div>
      <div className="inner-header-back">
        <div className="left-col">
          <button
            type="buttton"
            className="back-btn"
            onClick={() => setAddUserStatus(false)}
          >
            <i class="bi bi-chevron-left"></i> Back
          </button>
        </div>
        <div className="tight-block"></div>
      </div>

      <div className="leaflet-control-management managementui-tab leaflet-control-var">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "general" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("general");
                }}
              >
                <span data-href="#tab-1">General</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "filtering" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("filtering");
                }}
              >
                <span data-href="#tab-1">Filtering</span>
              </li>

              <li
                className={`nav-link ${activeTab === "login" ? "active" : ""}`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("login");
                }}
              >
                <span data-href="#tab-1">Login</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "interact" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("interact");
                }}
              >
                <span data-href="#tab-1">Interact</span>
              </li>
            </ul>
          </nav>

          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "general" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <GeneralEmployee
                setActiveTab={setActiveTab}
                usersDetails={usersDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "filtering" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <FilteringEmployee
                setActiveTab={setActiveTab}
                usersDetails={usersDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "login" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <LoginUser
                setActiveTab={setActiveTab}
                usersDetails={usersDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "interact" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <InteractEmployee
                setActiveTab={setActiveTab}
                usersDetails={usersDetails}
                setAddUserStatus={setAddUserStatus}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
