import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Button } from "reactstrap";
import ConfigDB from "../../config";
import { getVehicleList } from "../../services/VehicleService";
import Select from "react-select";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  getDepartmentAllList,
  getSegmentAllList,
} from "../../services/ManagementService";
import { actionCountryList } from "../../services/Common";
import { createAddProfessional } from "../../services/EmployeeService";

const ProfessonalDriver = ({ setActiveTab, driverDetails }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;

  const [professonalData, setProfessonalData] = useState({
    employment_date: "",
    professional_situation: "",
    professional_experience: "",
    department_id: "",
    segment_id: "",
    same_country_used_for_company: 1,
    driver_specific_country: "",
  });
  const [departmentList, setDepartmentList] = useState([]);
  const [segementList, setSegementList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const [country, setCountry] = useState("");

  const [department, setdepartment] = useState("");
  const [segment, setSegment] = useState("");

  let DriverId = localStorage.getItem("driverId");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  useEffect(() => {
    if (driverDetails) {
      setProfessonalData({
        employment_date: driverDetails?.employment_date,
        professional_situation: driverDetails?.professional_situation,
        professional_experience: driverDetails?.professional_experience,
        same_country_used_for_company:
          driverDetails?.same_country_used_for_company,
        driver_specific_country: driverDetails?.driver_specific_country,
        department_id: driverDetails?.department_id,
        segment_id: driverDetails?.segment_id,
      });
    }
  }, [driverDetails]);

  const handleCheckboxAuxiliary = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setProfessonalData({
      ...professonalData,
      same_country_used_for_company: newValue,
    });
  };

  useEffect(function () {
    getDepartmentAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setDepartmentList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getSegmentAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setSegementList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    actionCountryList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCountryList(list);
      })
      .catch((err) => {});
  }, []);

  const handleDrivingForm = (e) => {
    e.preventDefault();

    let data = {
      uuid: driverDetails?.uuid ? driverDetails?.uuid : DriverId,
      employment_date: professonalData?.employment_date,
      professional_situation: professonalData?.professional_situation,
      professional_experience: professonalData?.professional_experience,
      department_id: professonalData?.department_id,
      segment_id: professonalData?.segment_id,
      same_country_used_for_company:
        professonalData?.same_country_used_for_company,
      driver_specific_country: professonalData?.driver_specific_country,
    };

    // if (AccountDetails?.route?.uuid) {
    //   modifymanagament(createAddProfessional, updateData);
    // } else {
    modifyDriver(createAddProfessional, data);
    // }
  };

  const modifyDriver = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("driving_license");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method={`post`} onSubmit={handleDrivingForm}>
              <h4>Professonal</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">{"Employee Date"}</Label>
                    <div className="input-div">
                      <input
                        type="date"
                        id=""
                        className="form-control"
                        value={professonalData?.employment_date}
                        onChange={(e) => {
                          setProfessonalData({
                            ...professonalData,
                            employment_date: e.target.value,
                          });
                        }}
                        placeholder="Employee Date"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Professonal Situation"}
                    </Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={professonalData.professional_situation}
                        onChange={(e) => {
                          setProfessonalData({
                            ...professonalData,
                            professional_situation: e.target.value,
                          });
                        }}
                        name={`professional_situation`}
                        placeholder="Professonal Situation"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Professonal Experience"}
                    </Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={professonalData.professional_experience}
                        onChange={(e) => {
                          setProfessonalData({
                            ...professonalData,
                            professional_experience: e.target.value,
                          });
                        }}
                        name={`professional_experience`}
                        placeholder="Professonal Experience"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Department"}</Label>
                    <div className="input-div">
                      <Select
                        options={departmentList}
                        value={departmentList.find(
                          (option) =>
                            option.value ===
                            JSON.stringify(professonalData.department_id)
                        )}
                        onChange={(selectedOption) =>
                          setProfessonalData({
                            ...professonalData,
                            department_id: selectedOption.value,
                          })
                        }
                        placeholder={"Select Department"}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Segment"}</Label>
                    <div className="input-div">
                      <Select
                        options={segementList}
                        value={segementList.find(
                          (option) =>
                            option.value ===
                            JSON.stringify(professonalData.segment_id)
                        )}
                        onChange={(selectedOption) =>
                          setProfessonalData({
                            ...professonalData,
                            segment_id: selectedOption.value,
                          })
                        }
                        placeholder={"Select Segments"}
                      />
                    </div>
                  </div>

                  <div className="col-md-4" style={{ marginTop: "15px" }}>
                    <Label className="col-form-label">
                      {"Use same establishment country used for company"}
                    </Label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="same_country_used_for_company"
                        checked={professonalData.same_country_used_for_company}
                        onChange={handleCheckboxAuxiliary}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="same_country_used_for_company"
                      >
                        Use same establishment country used for company
                      </label>
                    </div>
                  </div>

                  {professonalData.same_country_used_for_company === 0 && (
                    <div className="col-md-4">
                      <Label className="col-form-label">{"Country"}</Label>
                      <div className="input-div">
                        <Select
                          options={countryList}
                          value={countryList.find(
                            (option) =>
                              option.value ===
                              JSON.stringify(
                                professonalData.driver_specific_country
                              )
                          )}
                          onChange={(selectedOption) =>
                            setProfessonalData({
                              ...professonalData,
                              driver_specific_country: selectedOption.value,
                            })
                          }
                          placeholder={"Select Country"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessonalDriver;
