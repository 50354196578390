import axiosInstance from "./AxiosInstance";

export function actionAddUpdatePlace(data) {
    return axiosInstance.post(`/places/create-update`, data);
}

export function getPlacesList(data) {
    return axiosInstance.post(`/places/list`, data);
}

export function actionDelPlace(data) {
    return axiosInstance.post(`/places/delete`, data);
}
