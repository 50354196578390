import axiosInstance from "./AxiosInstance";

// const hostName = Utils.getDomain();

export function getRoleList(data) {
    return axiosInstance.post(`/roles/list`, data);
}

export function getAllRoleList() {
    return axiosInstance.get(`/roles/list`);
}

export function actionStoreRole(data) {
    return axiosInstance.post(`/roles/create`, data);
}

export function actionUpdateRole(data) {
    return axiosInstance.post(`/roles/update`, data);
}

export function actionDelRole(data) {
    return axiosInstance.post(`/roles/delete`, data);
}

export function actionGetRolePermission(data) {
    return axiosInstance.post(`/roles/permission`, data);
}

export function actionModifyRolePermission(data) {
    return axiosInstance.post(`/roles/permission-update`, data);
}

export function actionGetUserPermission(data) {
    return axiosInstance.post(`/roles/user-permission`, data);
}