import React, { useState, useEffect } from "react";

import { Button, Form, FormGroup, Label } from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

import {
  actionUpdateManagementVehicle,
  actionUpdateTachographVehicle,
} from "../../services/VehicleService";

import Select from "react-select";

import { useNavigate } from "react-router-dom";
import { createAccountCRM } from "../../services/ManagementService";

const tableLengthList = configDb.data.dataTableLength;

const CRM = ({ setActiveTab, AccountDetails }) => {
  const navigate = useNavigate();

  const [CrmData, setCRMData] = useState({
    tracking_last_position: 0,
  });

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
  });
  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setCRMData({
      ...CrmData,
      tracking_last_position: newValue,
    });
  };

  useEffect(() => {
    if (AccountDetails) {
      setCRMData({
        tracking_last_position: AccountDetails?.crm?.tracking_last_position,
      });
    }
  }, [AccountDetails]);

  const handleDrivingForm = (e) => {
    e.preventDefault();

    let data = {
      tracking_last_position: CrmData?.tracking_last_position,
    };

    let updateData = {
      uuid: AccountDetails?.crm?.uuid,
      tracking_last_position: CrmData?.tracking_last_position,
    };

    if (AccountDetails?.crm?.uuid) {
      modifymanagament(createAccountCRM, updateData);
    } else {
      modifymanagament(createAccountCRM, data);
    }
  };

  const modifymanagament = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setCRMData({
          tracking_last_position: "",
        });
        setActiveTab("route");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method={`post`} onSubmit={handleDrivingForm}>
              <h4>Tracking Tickets</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Show only last position on map
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="tracking_last_position"
                          checked={CrmData.tracking_last_position}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="tracking_last_position"
                        >
                          Show only last position on map
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRM;
