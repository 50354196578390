import React, { useState, useEffect } from "react";

import { Button, Form, FormGroup, Label } from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

import {
  actionUpdateManagementVehicle,
  actionUpdateStatus,
} from "../../services/VehicleService";

import Select from "react-select";

import { useNavigate } from "react-router-dom";

const tableLengthList = configDb.data.dataTableLength;

const StatusType = [
  { value: "1", label: "Active" },
  { value: "2", label: "InActive" },
];

const useFuelType = [{ value: "1", label: "Use Fixed Fuel Combustion" }];

const VehicleStatus = ({ setActiveTab, vehicleDetails }) => {
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);

  const VehicleData = localStorage.getItem("vehicleId");

  const navigate = useNavigate();

  const [vehicleData, setVehicleData] = useState({
    status: "",
  });

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
  });

  useEffect(() => {
    if (vehicleDetails) {
      setVehicleData({
        status: vehicleDetails?.status,
      });
    }
  }, [vehicleDetails]);

  const handleVehicleForm = (e) => {
    e.preventDefault();

    let data = {
      vehicle_id: VehicleData,
      status: vehicleData.status,
    };

    if (JsonParse.user_role === ConfigDB.data.ROLE_ADMIN) {
      data.companyId = vehicleData.companyId;
    }

    let updateData = {
      uuid: vehicleDetails?.uuid,
      vehicle_id: vehicleDetails?.id,
      status: vehicleData.status,
    };

    if (JsonParse.user_role === ConfigDB.data.ROLE_ADMIN) {
      updateData.companyId = vehicleData.companyId;
    }

    if (vehicleDetails?.uuid) {
      modifymanagament(actionUpdateStatus, updateData);
    } else {
      modifymanagament(actionUpdateStatus, data);
    }
  };

  const modifymanagament = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
      
        setActiveTab("WorkingPeriod");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const SelectedStatus = StatusType.find(
    (option) => option.value === JSON.stringify(vehicleData?.status)
  )

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method={`post`} onSubmit={handleVehicleForm}>
              <FormGroup>
                <div className="row">
                  <div className="col-md-4">
                    <Label className="col-form-label">{"Status type"}</Label>
                    <div className="mb-3">
                      <Select
                        options={StatusType}
                        value={SelectedStatus}
                        onChange={(selectedOption) =>
                          setVehicleData((prevData) => ({
                            ...prevData,
                            status: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Status type"}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleStatus;
