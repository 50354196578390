import React from "react";
import logoImg from "../assets/images/logo.svg";

const Footer = () => {
  return (
    <div>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact">
              <div className="footer-logo">
                <img src={logoImg} alt="" className="img-fluid" />
              </div>
            </div>

            <div className="col-lg-9 col-md-6 footer-links">
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="/about">About Us</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
                {/* <li><a href="#">Terms and Conditions</a></li>
                                <li><a href="#">Privacy Policy</a></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom clearfix">
        <div className="copyright">
          &copy; Copyright © 2022 On-Route Real-Time Connections
        </div>
      </div>
    </div>
  );
};

export default Footer;
