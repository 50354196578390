import React, { useCallback, useEffect, useRef, useState } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";
import configDb from "../../config";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus-i.svg";
import { actionDelPlace } from "../../services/PlaceService";
import { useJsApiLoader } from "@react-google-maps/api";
import { actionCompanyList } from "../../services/Common";
import Select from "react-select";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  useLoadScript,
} from "use-places-autocomplete";
import {
  addZonedevice,
  deleteZonedevice,
  getZonedeviceList,
} from "../../services/TripService";
import ActiveCustomer from "../../assets/images/Active _customer.svg";
// import Company from "../../assets/images/Company.svg";
import Construction from "../../assets/images/Construction_site.svg";
import Customer from "../../assets/images/Customer.svg";
import InActiveCustomer from "../../assets/images/Inactive_customer.svg";
import Load from "../../assets/images/Load.svg";
import Logistic from "../../assets/images/Logistics.svg";
import Parking from "../../assets/images/Parking.svg";
import PetrolStation from "../../assets/images/Petrol_station.svg";
import RepairShop from "../../assets/images/Repair_shop.svg";
import Rest from "../../assets/images/Rest.svg";
import Store from "../../assets/images/Store.svg";
import Supplier from "../../assets/images/Supplier.svg";
import Unload from "../../assets/images/Unload.svg";
import WarHouses from "../../assets/images/Warehouse.svg";
import Yard from "../../assets/images/Yard.svg";
import AddAreas from "./AddAreas";
import {
  getDepartmentAllList,
  getSegmentAllList,
} from "../../services/ManagementService";

const tableLengthList = configDb.data.dataTableLength;

const containerStyle = {
  width: "100%",
  height: "400px",
};

const libraries = ["places", "drawing"];

const center = {
  lat: 38.78939581924669,
  lng: -9.386177246912233,
  placeName: "Tanzania",
};

const areaType = [
  { value: "1", label: "Zone" },
  { value: "2", label: "Place" },
];

const GeneralArea = ({
  setActiveTab,
  AreaData,
  setAddAreaStatus,
  setPlaceDatas,
  setPlaces,
  setZones,
  setSelectedPlaces,
}) => {
  console.log("AreaData", AreaData);
  const auth = useSelector((x) => x.auth.value);
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [totalRecords, setTotalRecords] = useState(0);
  const [placeData, setPlaceData] = useState({
    radius_latitude: "",
    radius_longitude: "",
    radius_zonename: "",
    place_id: "",
    area_type: "",
    vehicle_radius: "",
    department_id: "",
    segement_id: "",
  });


  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);

  const [area, setArea] = useState("");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    radius_zonename: "",
    area_type: area.area_type,
  });
  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(center);
  const [companyList, setCompanyList] = useState([]);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: ConfigDB.data.GOOGLE_MAP_KEY,
    libraries,
  });
  const [zoneDeviceList, setZoneDeviceList] = useState([]);
  const [deleteZoneID, setdeleteZoneID] = useState("");
  const [zone, setZone] = useState("");
  console.log("zone", zone);

  const [redius, setRedius] = useState("");

  const [originPlace, setOriginPlace] = useState(null);
  const [place, setPlace] = useState("");
  const [originZone, setOriginZone] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  let errorsObj = {
    area_type: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [departmentList, setDepartmentList] = useState([]);
  const [segementList, setSegementList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (AreaData?.uuid) {
      setPlaceData({
        radius_latitude: AreaData?.radius_latitude || "",
        radius_longitude: AreaData?.radius_longitude || "",
        radius_zonename: AreaData?.radius_zonename || "",
        place_id: AreaData?.place_id || "",
        area_type: AreaData?.area_type || "",
        vehicle_radius: AreaData?.vehicle_radius || "",
        department_id: AreaData?.department_id || "",
        segement_id: AreaData?.segement_id || "",
      });
      setZone(AreaData?.radius_zonename);
      setPlace(AreaData?.radius_zonename);
      setSelectedPlace(AreaData?.place_id);
    }
  }, [AreaData]);

  const placesList = [
    { value: 1, label: "Load", name: "load", image: Load },
    {
      value: 2,
      label: "Store",
      name: "store",
      image: Store,
    },
    {
      value: 3,
      label: "Logistics",
      name: "logistics",
      image: Logistic,
    },
    {
      value: 4,
      label: "Rest",
      name: "rest",
      image: Rest,
    },
    {
      value: 5,
      label: "Repair shop",
      name: "repair_shop",
      image: RepairShop,
    },
    {
      value: 6,
      label: "Parking",
      name: "parking",
      image: Parking,
    },
    {
      value: 7,
      label: "Unload",
      name: "unload",
      image: Unload,
    },
    {
      value: 8,
      label: "Warehouse",
      name: "warehouse",
      image: WarHouses,
    },
    {
      value: 9,
      label: "Customer",
      name: "customer",
      image: Customer,
    },
    {
      value: 10,
      label: "Supplier",
      name: "supplier",
      image: Supplier,
    },
    {
      value: 11,
      label: "Company",
      name: "company",
      image: Supplier,
    },
    {
      value: 12,
      label: "Construction site",
      name: "construction_site",
      image: Construction,
    },
    {
      value: 13,
      label: "Yard",
      name: "yard",
      image: Yard,
    },
    {
      value: 14,
      label: "Active customer",
      name: "active_customer",
      image: ActiveCustomer,
    },
    {
      value: 15,
      label: "Inactive customer",
      name: "inactive_customer",
      image: InActiveCustomer,
    },
    {
      value: 16,
      label: "Petrol station",
      name: "petrol_station",
      image: PetrolStation,
    },
  ];

  useEffect(function () {
    getDepartmentAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setDepartmentList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getSegmentAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setSegementList(list);
      })
      .catch((err) => {});
  }, []);
  const onMapClick = useCallback(async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
    );
    const data = await response.json();
    const placeName = data.results[0]?.formatted_address || "";
    setMarkerPosition({ lat, lng, placeName });
  }, []);

  const mapRef = useRef();

  const panToZone = useCallback(async ({ lat, lng, zoom }) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(14);
    }
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
    );
    const data = await response.json();
    const placeName = data.results[0]?.formatted_address || "";
    setOriginZone({ lat, lng, placeName });
  }, []);

  const panToPlace = useCallback(async ({ lat, lng, zoom }) => {
    if (mapRef.current) {
      mapRef.current.panToPlace({ lat, lng });
      mapRef.current.setZoom(17);
    }
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
    );
    const data = await response.json();
    const placeName = data.results[0]?.formatted_address || "";
    setOriginPlace({ lat, lng, placeName });
  }, []);
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);

  const handleZoneChange = (event) => {
    setZone(event.target.value);
  };

  const handleZoneRediusChange = (event) => {
    setRedius(event.target.value);
  };

  const handlePlaceChange = (event) => {
    setPlace(event.target.value);
  };

  useEffect(() => {
    if (AreaData) {
      setPlaceData({
        radius_latitude: AreaData?.radius_latitude,
        radius_longitude: AreaData?.radius_longitude,
        radius_zonename: AreaData?.radius_zonename,
        place_id: AreaData?.place_id,
        area_type: AreaData?.area_type,
        vehicle_radius: AreaData?.vehicle_radius,
        department_id: AreaData?.department_id,
        segement_id: AreaData?.segment_id,
      });
      setRedius(AreaData?.vehicle_radius);
      setPlace(AreaData?.radius_zonename);
    }
  }, [AreaData]);

  useEffect(() => {
    if (placeData && placeData.latitude && placeData.longitude) {
      const { latitude, longitude, placeName } = placeData;
      setMarkerPosition({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
        placeName,
      });
    }
  }, [placeData]);

  useEffect(() => {
    if (mapRef.current && markerPosition) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(markerPosition);
      mapRef.current.fitBounds(bounds);
    }
  }, [markerPosition]);

  const handleonFormSubmit = (e) => {
    e.preventDefault();

    setPlaceDatas(placeData);
    setZones(zone);
    setPlaces(place);
    setSelectedPlaces(selectedPlace);

    setActiveTab("map");

    // let data = {
    //   radius_latitude:
    //     placeData.area_type === "1" ? originZone?.lat : originPlace?.lat,
    //   radius_longitude:
    //     placeData.area_type === "1" ? originZone?.lng : originPlace?.lng,
    //   radius_zonename: placeData.area_type === "1" ? zone : place,
    //   place_id: placeData.area_type === "2" ? selectedPlace?.value : "",
    //   area_type: placeData.area_type,
    //   vehicle_radius: Math.max(redius),
    //   department_id: placeData?.department_id,
    //   segment_id: placeData?.segement_id,
    // };

    // let updateData = {
    //   uuid: AreaData?.uuid,
    //   radius_latitude:
    //     placeData.area_type === "1"
    //       ? AreaData?.radius_latitude
    //       : AreaData?.radius_latitude,
    //   radius_longitude:
    //     placeData.area_type === "1"
    //       ? AreaData?.radius_longitude
    //       : AreaData?.radius_longitude,
    //   radius_zonename: placeData.area_type === "1" ? zone : place,
    //   place_id: placeData.area_type === "2" ? selectedPlace?.value : "",
    //   area_type: placeData.area_type,
    //   vehicle_radius: Math.max(redius),
    //   department_id: placeData?.department_id,
    //   segment_id: placeData?.segement_id,
    // };

    // if (AreaData?.uuid) {
    //   modifyPlace(addZonedevice, updateData);
    // } else {
    //   modifyPlace(addZonedevice, data);
    // }
  };

  const modifyPlace = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setPlaceData({
          area_type: "",
        });
        setIsModifyButtonLoad(false);
        setAddAreaStatus(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };

    const getValue = (e) => (e ? (e.target ? e.target.value : e.value) : null);

    if (type === "length") {
      paramsObj.limit = getValue(e);
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = getValue(e);
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "zonename") {
      paramsObj.page = 1;
      paramsObj.radius_zonename = getValue(e);
    } else if (type === "AreaType") {
      paramsObj.page = 1;
      paramsObj[type === "AreaType" ? "area_type" : ""] = getValue(e);
    }

    setParams(paramsObj);
  };

  useEffect(
    function () {
      let data = params;
      getZonedeviceList(data)
        .then((response) => {
          setZoneDeviceList(response.data.data);
          setTotalRecords(response.data.totalRecords);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const selctedArea = areaType.find(
    (option) => option.value === placeData.area_type
  );

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleonFormSubmit}>
              <div
                className="col-xl-4"
                style={{ width: "95%", marginLeft: "10px" }}
              >
                <div className="mb-3">
                  <Select
                    options={areaType}
                    value={selctedArea}
                    onChange={(selectedOption) =>
                      setPlaceData((prevData) => ({
                        ...prevData,
                        area_type: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Area"}
                  />
                </div>
              </div>

              {placeData?.area_type === "1" && (
                <>
                  <div className="col-md-12">
                    <i
                      className="bi bi-geo me-2"
                      style={{
                        marginLeft: "10px",
                      }}
                    ></i>
                    <Label
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Zone
                    </Label>
                    <Input
                      type="text"
                      value={zone}
                      onChange={handleZoneChange}
                      placeholder="Search your zone"
                      style={{
                        width: "95%",
                        marginLeft: "13px",
                      }}
                    />
                  </div>

                  <div className="col-md-12">
                    <SearchZone panToZone={panToZone} />
                  </div>
                </>
              )}

              {placeData?.area_type === "2" && (
                <>
                  <div className="col-md-12">
                    <i
                      className="bi bi-geo me-2"
                      style={{
                        marginLeft: "10px",
                      }}
                    ></i>
                    <Label
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Place
                    </Label>
                    <Input
                      type="text"
                      value={place}
                      onChange={handlePlaceChange}
                      placeholder="Search Your Place"
                      style={{
                        width: "95%",
                        marginLeft: "13px",
                      }}
                    />
                  </div>
                  <div className="col-md-12">
                    <SearchPlace
                      panToPlace={panToPlace}
                      setOriginplace={setOriginPlace}
                    />
                  </div>
                  <div className="col-md-12">
                    <div
                    // style={{
                    //   width: "465px",
                    //   padding: "10px",
                    // }}
                    >
                      <Select
                        options={placesList}
                        value={selectedPlace}
                        onChange={setSelectedPlace}
                        getOptionLabel={(option) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={option.image}
                              style={{
                                width: 20,
                                height: 20,
                                marginRight: 10,
                              }}
                            />
                            {option.label}
                          </div>
                        )}
                        getOptionValue={(option) => option.value}
                        placeholder="Select a Place Type"
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="col-md-12">
                <Label className="col-form-label">Department</Label>
                <div className="input-div">
                  <Select
                    options={departmentList}
                    value={departmentList?.find(
                      (option) =>
                        option.value === JSON.stringify(placeData.department_id)
                    )}
                    onChange={(selectedOption) =>
                      setPlaceData((prevData) => ({
                        ...prevData,
                        department_id: selectedOption.value,
                      }))
                    }
                    placeholder={"None"}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <Label className="col-form-label">Segement</Label>
                <div className="input-div">
                  <Select
                    options={segementList}
                    value={segementList?.find(
                      (option) => option.value === placeData.segement_id
                    )}
                    onChange={(selectedOption) =>
                      setPlaceData((prevData) => ({
                        ...prevData,
                        segement_id: selectedOption.value,
                      }))
                    }
                    placeholder={"None"}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Next`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

const SearchZone = ({ panToZone, setOriginZone }) => {
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      if (results.length === 0) {
        throw new Error("No results found");
      }
      const { lat, lng } = await getLatLng(results[0]);
      panToZone({ lat, lng });
      setOriginZone({ lat, lng, time: new Date() });
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup style={{ padding: "10px", paddingBottom: "0" }}>
          <i className="bi bi-geo me-2"></i>
          <Label style={{ fontSize: "16px", fontWeight: "600" }}>
            Search Location
          </Label>
          <Input
            type="text"
            value={value}
            onChange={handleInput}
            placeholder="Search your location"
          />
        </FormGroup>
      </Form>
      {status === "OK" && (
        <ListGroup>
          {data.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

const SearchPlace = ({ panToPlace, setOriginplace }) => {
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      if (results.length === 0) {
        throw new Error("No results found");
      }
      const { lat, lng } = await getLatLng(results[0]);
      panToPlace({ lat, lng });
      setOriginplace({ lat, lng, time: new Date() });
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup style={{ padding: "10px", paddingBottom: "0" }}>
          <i className="bi bi-geo me-2"></i>
          <Label style={{ fontSize: "16px", fontWeight: "600" }}>
            Search Location
          </Label>
          <Input
            type="text"
            value={value}
            onChange={handleInput}
            placeholder="Search your location"
          />
        </FormGroup>
      </Form>
      {status === "OK" && (
        <ListGroup>
          {data.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default GeneralArea;
