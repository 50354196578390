import React, {useEffect, useState} from "react";

import validator from "validator";
import configDb from "../config";
import {actionChangeGeneral, actionChangeImage, actionChangePassword, actionDetail} from "../services/ProfileService";
import {toast} from "react-toastify";
import Utils from "../utils";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../store";
import RightHeader from "./partials/RightHeader";


function Profile() {
    const auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();
    const [isPasswordButtonLoad, setIsPasswordButtonLoad] = useState(false);
    const [isProfileButtonLoad, setIsProfileButtonLoad] = useState(false);
    const [isGeneralButtonLoad, setIsGeneralButtonLoad] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [previewUrl, setPreviewUrl] = useState(null);
    const [image, setImage] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const errorsGeneralObj = {name: '', email: '', number: ''};
    const [errorsGeneral, setErrorsGeneral] = useState(errorsGeneralObj);
    const [activeTab, setActiveTab] = useState("general");


    let errorsPicObj = {
        image: ''
    };
    const [errorsPic, setErrorsPic] = useState(errorsPicObj);


    const errorsObj = {newPassword: '', confirmPassword: '', oldPassword: ''};
    const [errors, setErrors] = useState(errorsObj);

    useEffect(function () {

        actionDetail()
            .then(response => {
                let data = response?.data?.data;
                if (data) {
                    setPreviewUrl(data?.profile);
                    setName(data?.name);
                    setEmail(data?.email);
                    setNumber(data?.number);
                }
            })
            .catch(error => {
                toast.error(Utils.getErrorMessage(error));
            });
    }, []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleFileChange = (event) => {
        const selectedFiles = event.target.files[0];

        if (selectedFiles) {
            setImage(selectedFiles);
            const imageUrl = URL.createObjectURL(selectedFiles);
            setPreviewUrl(imageUrl);
        }
    };

    const validateImage = (image) => {
        if (!image) {
            return false;
        }
        const allowedTypes = ['image/jpeg', 'image/png'];
        const maxSize = 2 * 1024 * 1024; // 2MB
        return allowedTypes.includes(image.type) && image.size <= maxSize;
    };

    const onChangePasswordSubmit = (e) => {
        e.preventDefault();
        let errorObj = {...errorsObj};
        let error = false;

        if (!newPassword) {
            errorObj.newPassword = 'New Password is required';
            error = true;
        } else if (!validator.isStrongPassword(newPassword, configDb.data.strongPassword)) {
            errorObj.newPassword = "Your password must be more than 6 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.";
            error = true;
        }
        
        if (!oldPassword) {
            errorObj.oldPassword = 'Old Password is required';
            error = true;
        } else if (!validator.isStrongPassword(oldPassword, configDb.data.strongPassword)) {
            errorObj.oldPassword = "Your password must be more than 6 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.";
            error = true;
        }
        
        if (oldPassword === newPassword) {
            errorObj.newPassword = 'New Password should not be same as Old Password';
            error = true;
        }

        if (!confirmPassword) {
            errorObj.confirmPassword = 'Confirm password is required';
            error = true;
        } else if (newPassword !== confirmPassword) {
            errorObj.confirmPassword = 'New password and confirm password does not match';
            error = true;
        }

        setErrors(errorObj);
        if (error) return;

        setIsPasswordButtonLoad(true);
        let data = {password: newPassword, oldPassword: oldPassword};
        actionChangePassword(data)
            .then(response => {
                toast.success(response.data.message);
                setIsPasswordButtonLoad(false);
                setNewPassword('');
                setOldPassword('');
                setConfirmPassword('');
            })
            .catch(error => {
                toast.error(Utils.getErrorMessage(error));
                setIsPasswordButtonLoad(false);
            });
    };

    const onFormPictureSubmit = (e) => {
        e.preventDefault();
        let errorObjPic = {...errorsPicObj};
        let errorPic = false;

        if (!image) {
            errorObjPic.image = 'Image is required';
            errorPic = true;
        } else if (!validateImage(image)) {
            errorObjPic.image = 'Invalid file. Please upload a valid image. Size must be < 2mb';
            errorPic = true;
        }

        setErrorsPic(errorObjPic);
        if (errorPic) return;

        setIsProfileButtonLoad(true);
        const formData = new FormData();
        formData.append("image", image);

        actionChangeImage(formData).then(response => {
            let userData = {...auth, user_image: response.data.path};
            Utils.setDetail(userData);
            dispatch(authActions.login(userData));
            toast.success(response.data.message);
            setIsProfileButtonLoad(false);
        }).catch(error => {
            toast.error(Utils.getErrorMessage(error));
            setIsProfileButtonLoad(false);
        });
    };

    const onGeneralSave = (e) => {
        e.preventDefault();
        let errorObj = {...errorsGeneralObj};
        let error = false;

        if (!name) {
            errorObj.name = 'Name is required';
            error = true;
        }

        if (!email) {
            errorObj.email = 'Email is required';
            error = true;
        } else if (!validator.isEmail(email)) {
            errorObj.email = 'Please enter valid email address';
            error = true;
        }

        if (!number) {
            errorObj.number = 'Contact is required';
            error = true;
        } else if (!validator.isMobilePhone(number)) {
            errorObj.number = 'Invalid contact';
            error = true;
        } else if (number && number.length !== 10) {
            errorObj.number = 'Invalid contact';
            error = true;
        }

        setErrorsGeneral(errorObj);
        if (error) return;


        setIsGeneralButtonLoad(true);
        let data = {
            name: name,
            email: email,
            number: number
        };

        actionChangeGeneral(data)
            .then(response => {
                toast.success(response.data.message);
                setIsGeneralButtonLoad(false);
            })
            .catch(error => {
                toast.error(Utils.getErrorMessage(error));
                setIsGeneralButtonLoad(false);
            });
    };
    return (
        <>
            <div className="profle-header">
                <h4>USER PROFILE</h4>
            </div>
            <div className="container-event-profle profile-container">
                <ul className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                    <li className={`nav-link ${activeTab === 'general' ? 'active' : ''}`} role="tab"
                        onClick={(e) => {
                            e.preventDefault();
                            handleTabClick('general');
                        }}>
                        <a href="#">General</a>
                    </li>

                    <li className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`} role="tab"
                        onClick={(e) => {
                            e.preventDefault();
                            handleTabClick('profile');
                        }}>
                        <a href="#">Profile</a>
                    </li>

                    <li className={`nav-link ${activeTab === 'changePassword' ? 'active' : ''}`} role="tab"
                        onClick={(e) => {
                            e.preventDefault();
                            handleTabClick('changePassword')
                        }}>
                        <a href="#">Change Password</a>
                    </li>
                </ul>
                <div className="profle-block tab-content" id="nav-tabContent">

                    <div className={`tab-pane fade ${activeTab === 'general' ? 'show active' : ''}`} role="tabpanel">
                        <div className="row">
                            <div className="col-lg-12 mb-4 mb-sm-5">
                                <div className="card">
                                    <div className="card-body"  style={{ padding: "0px" }}>
                                        <div className="row">
                                            <div className="col-sm-4 align-content-center">
                                                <h6 className="mb-3">Name</h6>
                                                <input type="text"
                                                       className="form-control"
                                                       value={name}
                                                       onChange={e => setName(e.target.value)}/>
                                                {errorsGeneral.name && (
                                                    <span className='input-error'>{errorsGeneral.name}</span>)}
                                            </div>

                                            {/* Add other fields here */}
                                            <div className="col-sm-4 align-content-center">
                                                <h6 className="mb-3">Email</h6>
                                                <input type="email"
                                                       className="form-control"
                                                       value={email}
                                                       onChange={e => setEmail(e.target.value)}/>
                                                {errorsGeneral.email && (
                                                    <span className='input-error'>{errorsGeneral.email}</span>)}
                                            </div>


                                            <div className="col-sm-4 align-content-center">
                                                <h6 className="mb-3">Contact</h6>

                                                <input type="number"
                                                       className="form-control"
                                                       value={number}
                                                       onChange={e => setNumber(e.target.value)}/>
                                                {errorsGeneral.number && (
                                                    <span className='input-error'>{errorsGeneral.number}</span>)}
                                            </div>

                                            {/* Add other fields similarly */}

                                            <div className="col-sm-10 mt-4">
                                                {/*<input type="button"*/}
                                                {/*onClick={onGeneralSave}*/}
                                                {/*className="change-password"*/}
                                                {/*value="Save Changes"/>*/}

                                                {!isGeneralButtonLoad && (
                                                    <button type="button" onClick={onGeneralSave}
                                                            className="change-password">
                                                        <span>Save Changes</span>
                                                    </button>
                                                )}

                                                {isGeneralButtonLoad && (
                                                    <button type="button" disabled={true} className="change-password">
                                                        <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tab-pane fade ${activeTab === 'profile' ? 'show active' : ''}`} role="tabpanel">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-column align-items-center text-center">
                                            <h3>Edit Profile</h3>
                                            <div className="avatar-upload">
                                                <div className="avatar-edit">
                                                    <input type='file' id="imageUpload" accept="image/*"
                                                           onChange={handleFileChange}/>
                                                    <label htmlFor="imageUpload"></label>
                                                </div>
                                                <div className="avatar-preview profile-user">
                                                    <img src={previewUrl} className="preview-image"/>
                                                </div>
                                            </div>
                                            {errorsPic.image && (
                                                <span className='input-error'>{errorsPic.image}</span>)}
                                            {/*<a className="change-password" href="#" onClick={onFormPictureSubmit}>*/}
                                            {/*Save Profile*/}
                                            {/*</a>*/}
                                            {!isProfileButtonLoad && (
                                                <button type="button" onClick={onFormPictureSubmit}
                                                        className="change-password">
                                                    <span>Save Profile</span>
                                                </button>
                                            )}

                                            {isProfileButtonLoad && (
                                                <button type="button" disabled={true} className="change-password">
                                                    <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tab-pane fade ${activeTab === 'changePassword' ? 'show active' : ''}`}
                         role="tabpanel">
                        <div className="cardStyle">
                            <form method={`post`} onSubmit={onChangePasswordSubmit}>
                                <h2 className="formTitle">
                                    Change Password
                                </h2>
                                <div className="inputDiv">
                                    <label className="inputLabel">Old Password</label>
                                    <input className="form-control" type="password"
                                           value={oldPassword}
                                           onChange={e => setOldPassword(e.target.value)}/>
                                    {errors.oldPassword && (
                                        <span className='input-error'>{errors.oldPassword}</span>
                                    )}

                                </div>
                                <div className="inputDiv">
                                    <label className="inputLabel">New Password</label>
                                    <input className="form-control" type="password"
                                           value={newPassword}
                                           onChange={e => setNewPassword(e.target.value)}/>
                                    {errors.newPassword && (
                                        <span className='input-error'>{errors.newPassword}</span>
                                    )}
                                </div>
                                <div className="inputDiv">
                                    <label className="inputLabel">Confirm Password</label>
                                    <input className="form-control" type="password"
                                           value={confirmPassword}
                                           onChange={e => setConfirmPassword(e.target.value)}/>
                                    {errors.confirmPassword && (
                                        <span className='input-error'>{errors.confirmPassword}</span>
                                    )}
                                </div>
                                <div className="buttonWrapper mt-4">
                                    {!isPasswordButtonLoad && (
                                        <button type="submit" id="submitButton" className="change-password">
                                            <span>Change Password</span>
                                        </button>
                                    )}

                                    {isPasswordButtonLoad && (
                                        <button type="button" disabled={true} className="change-password">
                                            <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Profile;