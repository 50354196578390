import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { orderTracking } from "../../services/TripService";
import Utils from "../../utils";
import { toast } from "react-toastify";
import moment from "moment";
import Logo from "../../assets/images/logo.svg";

const OrderTrip = () => {
  const { uuid } = useParams();

  const [trackindData, SetTrackingData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    function () {
      let data = {
        uuid: uuid,
      };
      setIsLoading(true);
      orderTracking(data)
        .then((response) => {
          SetTrackingData(response.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [uuid]
  );

  return (
    <div>
      <div class="card">
        <div class="card-body">
          <div className="card-header">
            <h5 class="card-logo">
              <img src={Logo} />
            </h5>
          </div>
          <div className="card-dec-2">
            <div className="card-header-block">
              <h5 class="card-title">TRACK YOUR ORDER</h5>
            </div>
            <div class="card-text-block">
              <div className="number">Reference #: </div>
              <div className="number-dec">
                {trackindData?.list?.reference_number}
              </div>
            </div>

            <div class="card-text-block">
              <div className="number">Source Address:</div>
              <div className="number-dec">
                {trackindData?.list?.source_place}
              </div>
            </div>
            <div class="card-text-block">
              <div className="number">Delivery Address:</div>
              <div className="number-dec">
                {trackindData?.list?.destination_place}
              </div>
            </div>
            <div class="card-text-block">
              <div className="number">Status:</div>
              <div className="number-dec">{trackindData?.list?.name}</div>
            </div>
            <div class="card-text-block">
              <div className="number">Estimated Time:</div>
              {trackindData.status_id === "2" ? (
                <div className="number-dec">
                  {trackindData?.estimatedTravelTime
                    ? moment(trackindData?.estimatedTravelTime).format(
                        "DD/MM/YYYY HH:mm:ss A"
                      )
                    : "-"}
                </div>
              ) : (
                <div className="number-dec">
                  {trackindData?.SchduleList?.estimated_date
                    ? moment(trackindData?.SchduleList?.estimated_date).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )
                    : "-"}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTrip;
