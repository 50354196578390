import React, { useState } from "react";

import ConfigDB from "../../config";
import Filter from "./Filter";
import { useLocation, useNavigate } from "react-router-dom";
import Driver from "./Driver";
import Vehicles from "./Vehicles";
import { createFilter } from "../../services/ManagementService";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import Utils from "../../utils";

const FilterVehicle = () => {
  const [activeTab, setActiveTab] = useState("vehicle");
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedDrivers, setSelectedDrivers] = useState([]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);
  const isAdminRole = JsonParse?.user_role === ConfigDB.data.ROLE_ADMIN;

  const state = useLocation();
  const navigate = useNavigate();

  const handleVehicleSelection = (selected) => {
    setSelectedVehicles(selected);
  };

  const handleDriverSelection = (selected) => {
    setSelectedDrivers(selected);
  };

  const handleVehicleForm = (e) => {
    e.preventDefault();

    let data = {
      name: state?.state?.vehicleName,
      vehicle_total: selectedVehicles,
      driver_total: selectedDrivers,
    };

    createFilter(data)
      .then((response) => {
        toast.success(response.data.message);
        navigate("/administration");
        setActiveTab("filter")
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="leaflet-control-management managementui-tab">
        <div className="inner-header py-3">
          <div className="left-block" style={{ marginTop: "0px !important" }}>
            <h5>{state?.state?.vehicleName}</h5>
          </div>

          <div className="right-block w-auto">
            <Button
              type={`button`}
              onClick={handleVehicleForm}
              className={`add-btn btn  float-right`}
            >
              {`Save`}
            </Button>
          </div>
        </div>
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "vehicle" ? "active" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("vehicle");
                }}
              >
                <span data-href="#tab-2">Vehicles</span>
              </li>
              <li
                className={`nav-link ${activeTab === "driver" ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("driver");
                }}
              >
                <span data-href="#tab-3">Driver</span>
              </li>
            </ul>
          </nav>
          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "vehicle" ? "show active" : ""
              }`}
              id="tab-2"
            >
              <Vehicles onSelectionChange={handleVehicleSelection} />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "driver" ? "show active" : ""
              }`}
              id="tab-3"
            >
              <Driver onSelectionChange={handleDriverSelection} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterVehicle;
