import axiosInstance from "./AxiosInstance";

export function createCompany(data) {
    return axiosInstance.post(`/company-registration`, data);
}

export function getCompanyList(data) {
    return axiosInstance.post(`/company/list`, data);
}

export function getCompanyDetail(data) {
    return axiosInstance.post(`/company/view`, data);
}

export function actionDelCompany(data) {
    return axiosInstance.post(`/company/delete`, data);
}

export function actionApproveRejectCompany(data) {
    return axiosInstance.post(`/company/approve-reject`, data);
}

export function actionBuySubscription(data) {
    return axiosInstance.post(`/company/subscribe`, data);
}

export function actionSubscriptionSucceeded(data) {
    return axiosInstance.post(`/company/subscribe-succeeded`, data);
}