import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
  DirectionsService,
  InfoWindow,
  DrawingManager,
  Polyline,
} from "@react-google-maps/api";
import VehicleIcon from "../assets/images/vehicle-icon.svg";
import RadioIcon from "../assets/images/radio-red.svg";
import RadioGreenIcon from "../assets/images/radio-green.svg";

import {
  getDeviceFilterList,
  getDeviceVehcileTripList,
  getVehicleDetail,
  getVehicleList,
  getDeviceLastAllVehcileTripList,
} from "../services/VehicleService";
import { toast } from "react-toastify";
import Utils from "../utils";
import ConfigDB from "../config";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { getSettingDetails } from "../services/SettingService";
import {
  actionAddUpdateTrip,
  addZonedevice,
  deleteZonedevice,
  getDeviceDelay,
  getDriverList,
  getZonedeviceList,
} from "../services/TripService";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import Speedometer, {
  Background,
  Arc,
  Needle,
  Progress,
  Marks,
  Indicator,
} from "react-speedometer";
import BlueKey from "../assets/images/blue key.svg";
import RedFlag from "../assets/images/red flag.svg";
import GreenFlag from "../assets/images/green flag.svg";

import moment from "moment";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import "leaflet-draw/dist/leaflet.draw.css"; // Import Leaflet Draw CSS
import L from "leaflet"; // Import Leaflet library
import "leaflet-draw"; // Import Leaflet Draw
import ActiveCustomer from "../assets/images/Active _customer.svg";

import Construction from "../assets/images/Construction_site.svg";
import Customer from "../assets/images/Customer.svg";
import InActiveCustomer from "../assets/images/Inactive_customer.svg";
import Load from "../assets/images/Load.svg";
import Logistic from "../assets/images/Logistics.svg";
import Parking from "../assets/images/Parking.svg";
import PetrolStation from "../assets/images/Petrol_station.svg";
import RepairShop from "../assets/images/Repair_shop.svg";
import Rest from "../assets/images/Rest.svg";
import Store from "../assets/images/Store.svg";
import Supplier from "../assets/images/Supplier.svg";
import Unload from "../assets/images/Unload.svg";
import WarHouses from "../assets/images/Warehouse.svg";
import Yard from "../assets/images/Yard.svg";
import GeoFense from "../assets/images/geofense.svg";
import RotateMarker from "../assets/images/Next.svg";

const placesList = [
  { value: 1, label: "Load", name: "load", image: Load },
  {
    value: 2,
    label: "Store",
    name: "store",
    image: Store,
  },
  {
    value: 3,
    label: "Logistics",
    name: "logistics",
    image: Logistic,
  },
  {
    value: 4,
    label: "Rest",
    name: "rest",
    image: Rest,
  },
  {
    value: 5,
    label: "Repair shop",
    name: "repair_shop",
    image: RepairShop,
  },
  {
    value: 6,
    label: "Parking",
    name: "parking",
    image: Parking,
  },
  {
    value: 7,
    label: "Unload",
    name: "unload",
    image: Unload,
  },
  {
    value: 8,
    label: "Warehouse",
    name: "warehouse",
    image: WarHouses,
  },
  {
    value: 9,
    label: "Customer",
    name: "customer",
    image: Customer,
  },
  {
    value: 10,
    label: "Supplier",
    name: "supplier",
    image: Supplier,
  },
  {
    value: 11,
    label: "Company",
    name: "company",
    image: Supplier,
  },
  {
    value: 12,
    label: "Construction site",
    name: "construction_site",
    image: Construction,
  },
  {
    value: 13,
    label: "Yard",
    name: "yard",
    image: Yard,
  },
  {
    value: 14,
    label: "Active customer",
    name: "active_customer",
    image: ActiveCustomer,
  },
  {
    value: 15,
    label: "Inactive customer",
    name: "inactive_customer",
    image: InActiveCustomer,
  },
  {
    value: 16,
    label: "Petrol station",
    name: "petrol_station",
    image: PetrolStation,
  },
];

const libraries = ["places", "drawing"];

const containerStyle = {
  width: "100%",
  height: "990px",
};

const center = {
  lat: 38.78939581924669,
  lng: -9.386177246912233,
  placeName: "Tanzania",
};

const initialZoom = 7;

const Dashboard = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const navigate = useNavigate();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: ConfigDB.data.GOOGLE_MAP_KEY,
    libraries,
  });
  let filterData = localStorage.getItem("filter");

  const [vehicleList, setVehicleList] = useState([]);
  const [vehicleLoader, setVehicleLoader] = useState(true);
  const [vehicleLists, setVehicleLists] = useState([]);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    filter_id: filterData,
  });
  const [activeTab, setActiveTab] = useState("search");
  const [activeSubTab, setActiveSubTab] = useState("Zone");
  const [settingDetails, setsettingDetails] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const [dates, setDates] = useState("");
  const [origin, setOrigin] = useState(null);

  const [destination, setDestination] = useState(null);
  const [waypoints, setWaypoints] = useState([]);
  const [waypointsMap, setWaypointsMap] = useState([]);
  const [source, setSource] = useState("");

  const [dest, setDest] = useState("");
  const [tripDetailsList, setTripDetailsList] = useState([]);
  const [finalResult, setFinalResult] = useState([]);
  const [directions, setDirections] = useState(null);
  const [directionsForDevice, setDirectionsForDevice] = useState([]);
  const [directionsForFilterDevice, setDirectionsForFilterDevice] = useState(
    []
  );

  const [map, setMap] = useState(null);
  const [distance, setDistance] = useState(0);
  const [duration, setDuration] = useState(0);
  const [time, setTime] = useState(0);
  const [price, setPrice] = useState("");
  const [delModal, setDelModal] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [vehicleTripModal, setvehicleTripModal] = useState(false);
  const [DriverList, setDriverList] = useState([]);
  const [vehicle, setVehicle] = useState("");
  const [driver, setDriver] = useState("");
  const [tripName, setTripName] = useState("");
  const [pin, setPin] = useState("");
  const [referencenumber, setReferenceNumber] = useState("");
  const [deviceDetails, setDeviceDetails] = useState({});
  const [uuid, setUuid] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [devicedelayData, setDeviceDelayData] = useState([]);
  const [datas, setDatas] = useState("");
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const [vehicleDate, setVehicleDate] = useState();
  const [vehicleDateRoute, setVehicleDatesRoute] = useState();
  const [devicedelayFilterData, setDeviceDelayFilterData] = useState([]);
  console.log("devicedelayFilterData", devicedelayFilterData);
  const [devicedelayVehicleTripData, setDeviceDelayVehicleTripData] = useState(
    []
  );
  const [
    devicedelayVehicleAllLastTripData,
    setDeviceDelayVehicleAllLastTripData,
  ] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [zoneDeviceList, setZoneDeviceList] = useState([]);
  const [geoFenceRadius, setGeoFenceRadius] = useState(null);
  const [isOpenZone, setIsOpenZone] = useState(true);
  const [isOpenPlace, setIsOpenPlace] = useState(true);
  const [deleteZoneID, setdeleteZoneID] = useState("");
  const [delZoneModal, setDelZoneModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [originZone, setOriginZone] = useState(null);
  const [zone, setZone] = useState("");
  const [dateTime, setDateTime] = useState(new Date());
  const [error, setError] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const [deviceTripTotal, setDeviceTripTotal] = useState({});
  const [deviceLastTripTotal, setDeviceLastTripTotal] = useState({});

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [originPlace, setOriginPlace] = useState(null);
  const [place, setPlace] = useState("");
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [placeNamesTrip, setPlaceNamesTrip] = useState({});
  const [TripData, setTripData] = useState({});
  const [directionsResponse, setDirectionsResponse] = useState(null);
  console.log("directionsResponse", directionsResponse);
  const [directionsError, setDirectionsError] = useState(null);
  const [directionsData, setDirectionsData] = useState(null);

  const getTripCoordinates = (tripData) => {
    if (!tripData?.logs || tripData?.logs?.length === 0)
      return { startLocation: null, endLocation: null };

    const startLocation = {
      lat: parseFloat(tripData?.logs[0]?.latitude),
      lng: parseFloat(tripData?.logs[0]?.longitude),
    };
    const endLocation = {
      lat: parseFloat(tripData?.logs[tripData?.logs?.length - 1].latitude),
      lng: parseFloat(tripData?.logs[tripData?.logs?.length - 1].longitude),
    };

    return { startLocation, endLocation };
  };
  const handleRouteDisplay = useCallback((tripData) => {
    const { startLocation, endLocation } = getTripCoordinates(tripData);
    if (!startLocation || !endLocation) {
      console.log(`Alert trip not found`);
      return;
    }

    const DirectionsService = new window.google.maps.DirectionsService();

    DirectionsService.route(
      {
        origin: startLocation,
        destination: endLocation,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirectionsResponse(result);
          setDirectionsData(
            result.routes[0].overview_path.map((path) => ({
              lat: path.lat(),
              lng: path.lng(),
            }))
          );
        } else {
          setDirectionsError(result);
        }
      }
    );
  }, []);

  useEffect(
    function () {
      let data = { ...params };
      setInterval(function () {
        if (data.filter_id !== localStorage.getItem("filter")) {
          data.filter_id = localStorage.getItem("filter");
          data.is_reload = !data.is_reload;
          setParams(data);
        }
      }, 1000);
    },
    [params]
  );

  const getPlaceName = (placeId) => {
    const place = placesList?.find((p) => JSON.stringify(p.value) === placeId);
    return place ? place.image : "Unknown Place";
  };

  const getImageByPlaceId = (placeId) => {
    const place = placesList.find(
      (item) => JSON.stringify(item.value) === placeId
    );
    return place ? place.image : null;
  };

  const handleChangeDateTime = (date) => {
    if (dates && date < dates) {
      setError(true);
      return;
    }
    setDateTime(date);
    setError(false);
  };

  const handleChangeDate = (date) => {
    setDates(date);
    setError(false);
  };

  const handleZoneChange = (event) => {
    setZone(event.target.value);
  };

  const handlePlaceChange = (event) => {
    setPlace(event.target.value);
  };

  const onCircleComplete = (circle) => {
    const radius = circle.getRadius();
    const center = circle.getCenter();
    setGeoFenceRadius({
      center: center.toJSON(),
      radius: radius,
    });
  };

  // useEffect(() => {
  //   if (mapRef.current) {
  //     const map = mapRef.current.leafletElement;

  //     const drawControl = new L.Control.Draw({
  //       draw: {
  //         polygon: true,
  //         circle: false,
  //         rectangle: false,
  //         marker: false,
  //         polyline: false,
  //       },
  //       edit: {
  //         featureGroup: new L.FeatureGroup(),
  //         remove: true,
  //       },
  //     });
  //     map.addControl(drawControl);

  //     map.on(L.Draw.Event.CREATED, function (event) {
  //       const layer = event.layer;
  //       map.addLayer(layer);
  //     });

  //     return () => {
  //       map.removeControl(drawControl);
  //       map.off(L.Draw.Event.CREATED);
  //     };
  //   }
  // }, []);

  const handleToggleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleToggleZoneClick = () => {
    setIsOpenZone(!isOpenZone);
  };

  const handleTogglePlaceClick = () => {
    setIsOpenPlace(!isOpenPlace);
  };

  const handleButtonClick = () => {
    setClickCount((prevCount) => prevCount + 1);
  };

  const handleVehicleTripOpen = () => {
    setvehicleTripModal(true);
  };

  const handleVehicleTripClose = () => {
    setvehicleTripModal(false);
    // setUuid();
    // setDeviceDelayData([]);
    // setDirectionsForDevice([]);
    // setDirectionsForFilterDevice([]);
    // setDeviceDelayVehicleTripData([]);
    // setDeviceDelayFilterData([]);
    // setVehicleDate("");
  };

  /** by defualt driver id select start */
  useEffect(() => {
    if (vehicle) {
      setDriver(vehicle?.vehicleId ? "62" : "");
    }
  }, [vehicle]);

  /** by defualt driver id select end */

  /**get driver list start */
  useEffect(
    function () {
      let data = params;
      getDriverList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setDriverList(list);
        })
        .catch((err) => {});
    },
    [params]
  );
  /**get driver list end */

  /**get vehicle list start */
  useEffect(
    function () {
      let data = { ...params };

      setVehicleLoader(true);
      getVehicleList(data)
        .then((response) => {
          setVehicleLists(response.data.data);
          setVehicleLoader(false);
        })
        .catch((err) => {
          setVehicleLoader(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );
  /**get vehicle list end */

  /**get device zone list start */
  useEffect(
    function () {
      let data = params;
      getZonedeviceList(data)
        .then((response) => {
          setZoneDeviceList(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );
  /**get device zone list end */

  /**get vehicle list for dropdown start */
  useEffect(
    function () {
      let data = params;
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );
  /**get vehicle list for dropdown end */

  /**get vehicle details for device details start */
  useEffect(
    function () {
      let data = {
        uuid: uuid,
      };
      if (uuid) {
        getVehicleDetail(data)
          .then((response) => {
            setDeviceDetails(response.data.data);
            setVehicleDate(moment().format("YYYY-MM-DD"));
          })
          .catch((err) => {
            toast.error(Utils.getErrorMessage(err));
          });
      }
    },
    [uuid]
  );
  /**get vehicle details for device details end */

  /**get setting details start */
  useEffect(
    function () {
      let data = params;
      getSettingDetails(data)
        .then((response) => {
          setsettingDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );
  /**get setting details end */

  /**get device delay data ignition details start */
  useEffect(
    function () {
      if (datas) {
        let data = {
          device_imei: datas.device_imei,
        };
        getDeviceDelay(data)
          .then((response) => {
            setDeviceDelayData(response.data.data);
          })
          .catch((err) => {
            toast.error(Utils.getErrorMessage(err));
          });
      }
    },
    [datas, datas?.device_imei]
  );
  /**get device delay data ignition details end */

  const showRoute = () => {
    if (vehicleDateRoute) {
      const date = new Date(vehicleDateRoute);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      if (formattedDate && datas.device_imei) {
        let data = {
          device_imei: datas.device_imei,
          startDate: formattedDate,
        };
        setIsLoading(true);

        getDeviceFilterList(data)
          .then((response) => {
            setDeviceDelayFilterData(response.data.data);
            setDeviceDelayData([]);
            setDirectionsForDevice([]);
            setIsLoading(false);
          })
          .catch((err) => {
            toast.error(Utils.getErrorMessage(err));
          });

        getDeviceVehcileTripList(data)
          .then((response) => {
            setDeviceDelayVehicleTripData(response.data.data);
            setIsLoading(false);
          })
          .catch((err) => {
            toast.error(Utils.getErrorMessage(err));
          });

        setUuid(datas.uuid);
      } else {
        setDeviceDelayFilterData([]);
        setDirectionsForFilterDevice([]);
        setDeviceDelayData([]);
        setDirectionsForDevice([]);
        setDeviceDelayVehicleTripData([]);
        setUuid(datas.uuid);
      }
    } else if (datas) {
      let data = {
        device_imei: datas.device_imei,
      };
      getDeviceDelay(data)
        .then((response) => {
          setDeviceDelayData(response.data.data);
          setDeviceDelayVehicleTripData([]);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  /** get device delay filter data start */
  useEffect(() => {
    if (vehicleDate) {
      let data = {
        device_imei: datas.device_imei,
        startDate: vehicleDate,
      };
      getDeviceFilterList(data)
        .then((response) => {
          setDeviceDelayFilterData(response.data.data);
          setDirectionsForFilterDevice([]);
          setDeviceDelayData([]);
          setDirectionsForDevice([]);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      setDeviceDelayFilterData([]);
      setDirectionsForFilterDevice([]);
      setDeviceDelayData([]);
      setDirectionsForDevice([]);
    }
  }, [datas.device_imei, vehicleDate]);
  /** get device delay filter data end */

  /** get device vehicle trip data start */
  let todayDate = moment().format("YYYY-MM-DD");
  useEffect(() => {
    if (datas && vehicleDate) {
      let data = {
        device_imei: datas.device_imei,
        startDate: vehicleDate ? vehicleDate : todayDate,
      };
      getDeviceVehcileTripList(data)
        .then((response) => {
          setDeviceDelayVehicleTripData(response.data.data);
          setDeviceTripTotal(response.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      setDeviceDelayVehicleTripData([]);
    }
  }, [datas.device_imei, vehicleDate]);
  /** get device vehicle trip data end */

  useEffect(() => {
    if (datas) {
      let data = {
        device_imei: datas.device_imei,
      };
      getDeviceLastAllVehcileTripList(data)
        .then((response) => {
          setDeviceDelayVehicleAllLastTripData(response.data.data);
          setDeviceLastTripTotal(response.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      setDeviceDelayVehicleAllLastTripData([]);
    }
  }, [datas.device_imei]);

  const onMoreDetails = (e) => {
    e.preventDefault();
    setDelModal(true);
  };

  const onMoreDetailsClose = (e) => {
    e.preventDefault();
    setDelModal(false);
  };

  const onScheduleOpen = (e) => {
    e.preventDefault();
    setScheduleModal(true);
  };

  const onScheduleClose = (e) => {
    e.preventDefault();
    setScheduleModal(false);
    setPin("");
    setTripName("");
    setReferenceNumber("");
  };

  const onMapClick = useCallback(
    async (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyB3iQbwmhRJeAZ53jGXTfCDV8imGBtGFDs`
      );
      const data = await response.json();
      const placeName = data.results[0]?.formatted_address || "";

      const position = { lat, lng, placeName };
      setIsDraggable(true);

      if (!origin) {
        setOrigin(position);
        setSource(position);
      } else if (!destination) {
        setDestination(position);
        setDest(position);
      } else {
        setWaypoints([...waypoints, position]);
        setWaypointsMap([...waypoints, position]);
      }
      // setSelectedDevice(null)
    },
    [origin, destination, waypoints]
  );

  const calculatePrice = (totalDistance, totalDuration) => {
    const distanceRate =
      settingDetails?.length > 0 ? settingDetails[0].price_per_distance : 0;

    const durationRate =
      settingDetails?.length > 0 ? settingDetails[0].price_per_hour : 0;

    const distancePrice = parseFloat((totalDistance * distanceRate).toFixed(2));

    const durationPrice = parseFloat(
      ((totalDuration * durationRate) / 100).toFixed(2)
    );

    if (distancePrice > durationPrice) {
      return {
        price: distancePrice,
        description: `distance: $${distancePrice.toFixed(2)}`,
      };
    } else {
      return {
        price: durationPrice,
        description: `duration: $${durationPrice.toFixed(2)}`,
      };
    }
  };

  const directionsCallback = useCallback(
    (result, status) => {
      if (result !== null && status === "OK") {
        let totalDistance = 0;
        let totalDuration = 0;
        const legs = result.routes[0].legs;
        const triplistDetails = legs.map((leg) => ({
          latitude: leg.start_location.lat(),
          longitude: leg.start_location.lng(),
          distance: (leg.distance.value / 1000).toFixed(2),
          duration: parseInt(leg.duration.text),
          sourcePlace: leg.start_address,
          destinationPlace: leg.end_address,
          destination_latitude: leg.end_location.lat(),
          destination_longitude: leg.end_location.lng(),
        }));

        result.routes[0].legs.forEach((leg) => {
          totalDistance += leg.distance.value;
          totalDuration += leg.duration.value;
        });

        const totalDistanceText = (totalDistance / 1000).toFixed(2);

        const hours = Math.floor(totalDuration / 3600);
        const minutes = Math.floor((totalDuration % 3600) / 60);
        const totalDurationText = `${hours}h:${minutes}m`;

        function calculateTotalDuration(hours, minutes) {
          return hours * 60 + minutes;
        }

        const hoursTime = hours;
        const minutesTime = minutes;

        const totalTime = calculateTotalDuration(hoursTime, minutesTime);
        const totalDurationTime = `${totalTime}`;

        const totalDistanceInKm = (totalDistance / 1000).toFixed(2);

        const { price } = calculatePrice(totalDistanceInKm, totalDuration);

        setPrice(price);
        setTime(totalDurationTime);
        setDistance(totalDistanceText);
        setDuration(totalDurationText);
        setDirections(result);
        setFinalResult(result.routes[0].legs);
        setTripDetailsList(triplistDetails);
      }
    },
    [settingDetails]
  );

  const directionsOptions = useMemo(() => {
    return {
      destination,
      origin,
      waypoints: waypoints?.map((waypoint) => ({ location: waypoint })),
      travelMode: "DRIVING",
    };
  }, [origin, destination, waypoints]);

  const mapRef = React.createRef();

  const panToZone = useCallback(async ({ lat, lng, zoom }) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(zoom || 14);
    }

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
      );
      const data = await response.json();
      const placeName = data.results[0]?.formatted_address || "";
      setOriginZone({ lat, lng, placeName });
    } catch (error) {
      console.error("Error fetching place name:", error);
    }
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  });

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    setMap(map);
  });

  const mapOptions = {
    styles: [
      {
        elementType: "geometry",
        stylers: [{ color: "#f5f5f5" }],
      },
      {
        elementType: "labels.icon",
        stylers: [{ visibility: "on" }],
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [{ color: "##f0eef0" }],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }],
      },
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "on" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#bdd6a4" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#bdd6a4", visibility: "on" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e", visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#dadada" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#616161" }],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [{ color: "#e5e5e5" }],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#b8edff" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#b8edff" }],
      },

      {
        featureType: "poi.medical",
        elementType: "geometry",
        stylers: [{ color: "#f0eef0" }],
      },

      {
        featureType: "poi.medical",
        elementType: "geometry.fill",
        stylers: [{ visibility: "off" }],
      },
    ],
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: true,
    streetViewControl: true,
    rotateControl: true,
    fullscreenControl: true,
  };

  const panTo = useCallback(async ({ lat, lng, zoom }) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(14);
    }
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
    );
    const data = await response.json();
    const placeName = data.results[0]?.formatted_address || "";
    setOrigin({ lat, lng, placeName });
  }, []);

  const panToDestination = useCallback(async ({ lat, lng, zoom }) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(14);
    }
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
    );
    const data = await response.json();
    const placeName = data.results[0]?.formatted_address || "";
    setDestination({ lat, lng, placeName });
  }, []);

  const panToStop = useCallback(
    async ({ lat, lng, zoom }) => {
      if (mapRef.current) {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
      }
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
      );
      const data = await response.json();
      const placeName = data.results[0]?.formatted_address || "";
      setWaypoints([...waypoints, { lat, lng, placeName, time: new Date() }]);
    },
    [waypoints]
  );

  // const panToZone = useCallback(async ({ lat, lng, zoom }) => {
  //   if (mapRef.current) {
  //     mapRef.current.panTo({ lat, lng });
  //     mapRef.current.setZoom(14);
  //   }
  //   const response = await fetch(
  //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
  //   );
  //   const data = await response.json();
  //   const placeName = data.results[0]?.formatted_address || "";
  //   setOriginZone({ lat, lng, placeName });
  // }, []);

  const panToPlace = useCallback(async ({ lat, lng, zoom }) => {
    if (mapRef.current) {
      mapRef.current.panToPlace({ lat, lng });
      mapRef.current.setZoom(17);
    }
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
    );
    const data = await response.json();
    const placeName = data.results[0]?.formatted_address || "";
    setOriginPlace({ lat, lng, placeName });
  }, []);

  const renderSearchDestination = () => {
    const searchComponents = [];
    for (let i = 0; i < clickCount; i++) {
      searchComponents.push(
        <SearchStop key={i} panToStop={panToStop} waypoints={waypoints} />
      );
    }
    return searchComponents;
  };
  const onMarkerDragEnd = (type, event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();

    let markerName = "";
    if (type === "origin") {
      setOrigin({ lat, lng, placeName: origin.placeName });
      setSource({ lat, lng, placeName: origin.placeName });
      markerName = origin.placeName;
    } else if (type === "destination") {
      setDestination({ lat, lng, placeName: destination.placeName });
      setDest({ lat, lng, placeName: destination.placeName });
      markerName = destination.placeName;
    } else {
      const updatedWaypoints = [...waypoints];
      markerName = updatedWaypoints[type].placeName;
      updatedWaypoints[type] = { ...updatedWaypoints[type], lat, lng };
      setWaypoints(updatedWaypoints);
    }

    setIsDraggable(false);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSubTabClick = (subTab) => {
    setActiveSubTab(subTab);
  };

  useEffect(() => {
    if (origin) {
      setActiveTab("directions");
    }
  }, [origin]);

  const date = new Date(dates);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const handleStartTripForm = (e) => {
    e.preventDefault();
    let data = {
      sourcePlace: origin?.placeName
        ? origin.placeName
        : directions?.routes[0]?.legs[0]?.start_address ||
          placeNamesTrip?.radius_zonename,
      sourceLatitude: origin?.lat || placeNamesTrip?.radius_latitude,
      sourceLongitude: origin?.lng || placeNamesTrip?.radius_longitude,
      destinationPlace: destination?.placeName
        ? destination?.placeName
        : directions?.routes[0]?.legs[0]?.end_address,
      destinationLatitude: destination?.lat,
      destinationLongitude: destination?.lng,
      totalDistance: distance,
      totalDuration: time,
      trip_name: tripName,
      price: price || 0,
      trip_schedule: {
        driverId: driver.driverId ? driver.driverId : driver,
        vehicleId: vehicle.vehicleId,
        date: [formattedDate],
        estimated_date: dateTime,
      },
    };

    // if (waypoints?.length > 0) {
    data.trip_list = tripDetailsList.map((tripDetail) => ({
      ...tripDetail,
      // reference_number: referencenumber,
      // pin: pin,
      price: "45",
    }));
    // }

    actionAddUpdateTrip(data)
      .then((response) => {
        toast.success(response.data.message);
        navigate("/trip");
      })

      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      });
  };

  const handleItemClick = (item) => {
    setUuid(item.uuid);
    setDatas(item);
    setSelectedVehicle(item.uuid);
    setDeviceDelayData([]);
    setDirectionsForDevice([]);
    setDeviceDelayFilterData([]);
    setDirectionsForFilterDevice([]);
    setVehicleDate("");
    setDirectionsResponse(null);
    setDirectionsData(null);
  };

  const handleCloseItem = () => {
    setUuid();
    setDeviceDelayData([]);
    setDirectionsForDevice([]);
    // setDeviceDelayVehicleTripData([]);
    setDeviceDelayFilterData([]);
    setDirectionsForFilterDevice([]);
    setVehicleDate("");
    // setDirectionsResponse([]);
    // setDirectionsData(null);
  };

  const directionsOptionsDelayDevice = useMemo(() => {
    const origin = {
      lat: parseFloat(datas?.currentLatitude),
      lng: parseFloat(datas?.currentLogitude),
    };

    const destinations =
      devicedelayData?.map((deviceDelay) => ({
        lat: parseFloat(deviceDelay?.latitude),
        lng: parseFloat(deviceDelay?.longitude),
        speed: parseInt(deviceDelay?.speed),
      })) || [];

    return {
      origin: origin,
      destinations: destinations,
      travelMode: "DRIVING",
    };
  }, [datas.currentLatitude, datas.currentLogitude, devicedelayData]);

  const directionsDelayDevice = useCallback((result, status) => {
    if (status === "OK") {
      setDirectionsForDevice((prevDirections) => [...prevDirections, result]);
    } else {
      console.error("Error fetching directions:", status);
      console.error("Result:", result);
    }
  }, []);

  useEffect(() => {
    if (
      directionsOptionsDelayDevice.origin &&
      directionsOptionsDelayDevice.destinations.length > 0
    ) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsOptionsDelayDevice.destinations.forEach((destination) => {
        directionsService.route(
          {
            origin: directionsOptionsDelayDevice.origin,
            destination: destination,
            travelMode: directionsOptionsDelayDevice.travelMode,
          },
          directionsDelayDevice
        );
      });
    }
  }, [directionsOptionsDelayDevice, directionsDelayDevice]);

  const directionsDelayFilterDevice = useCallback((result, status) => {
    if (status === "OK") {
      setDirectionsForFilterDevice((prevDirections) => [
        ...prevDirections,
        result,
      ]);

      // const steps = result.routes[0].legs.flatMap(leg => leg.steps);
      // setDirectionsSteps(steps);
    } else {
      console.error("Error fetching directions:", status);
    }
  }, []);

  const directionsOptionsDelayDeviceFilter = useMemo(() => {
    const origin = {
      lat: parseFloat(datas.currentLatitude),
      lng: parseFloat(datas.currentLogitude),
    };

    const destinations =
      devicedelayFilterData?.map((deviceDelay) => ({
        lat: parseFloat(deviceDelay?.latitude),
        lng: parseFloat(deviceDelay?.longitude),
        speed: parseInt(deviceDelay?.speed),
      })) || [];

    return {
      origin: origin,
      destinations: destinations,
      travelMode: "DRIVING",
    };
  }, [datas.currentLatitude, datas.currentLogitude, devicedelayFilterData]);

  useEffect(() => {
    if (
      directionsOptionsDelayDeviceFilter?.origin &&
      directionsOptionsDelayDeviceFilter?.destinations.length > 0 &&
      vehicleDate
    ) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsOptionsDelayDeviceFilter?.destinations.forEach(
        (destination) => {
          directionsService.route(
            {
              origin: directionsOptionsDelayDeviceFilter.origin,
              destination: destination,

              travelMode: directionsOptionsDelayDeviceFilter.travelMode,
            },
            directionsDelayFilterDevice
          );
        }
      );
    }
  }, [
    directionsOptionsDelayDeviceFilter,
    directionsDelayFilterDevice,
    vehicleDate,
  ]);

  const formatVehicleStoppedTime = (hour, minute) => {
    const parsedHour = parseInt(hour, 10);
    const parsedMinute = parseInt(minute, 10);

    const stoppedTime = moment().set({
      hour: parsedHour,
      minute: parsedMinute,
      second: 0,
    });

    return stoppedTime.format("HH:mm:ss");
  };

  const handleChangeStart = (date) => {
    setVehicleDatesRoute(date);
  };

  // useEffect(() => {
  //   if (vehicleDates) {
  //     let data = {
  //       device_imei: datas.device_imei,
  //       startDate: vehicleDates,
  //     };
  //     getDeviceFilterList(data)
  //       .then((response) => {
  //         setDeviceDelayFilterData(response.data.data);
  //         setDirectionsForFilterDevice([]);
  //         setDeviceDelayData([]);
  //         setDirectionsForDevice([]);
  //       })
  //       .catch((err) => {
  //         toast.error(Utils.getErrorMessage(err));
  //       });
  //   } else {
  //     setDeviceDelayFilterData([]);
  //     setDirectionsForFilterDevice([]);

  //     setDeviceDelayData([]);
  //     setDirectionsForDevice([]);
  //   }
  // }, [datas.device_imei, vehicleDates]);

  const handleAddZoneForm = (e) => {
    e.preventDefault();
    if (geoFenceRadius?.radius) {
      let data = {
        vehicle_radius: geoFenceRadius?.radius,
        radius_latitude: geoFenceRadius?.center?.lat,
        radius_longitude: geoFenceRadius?.center?.lng,
        radius_zonename: zone,
        area_type: 1,
      };
      addZonedevice(data)
        .then((response) => {
          toast.success(response.data.message);
          if (response.data.status === 200) {
            setGeoFenceRadius(null);
            setOrigin(null);
            let paramsObj = { ...params };
            paramsObj.is_reload = !paramsObj.is_reload;
            setParams(paramsObj);
          }

          navigate("/places");
        })

        .catch((error) => {
          toast.error(Utils.getErrorMessage(error));
        });
    }
  };

  const handleAddPlaceForm = (e) => {
    e.preventDefault();
    if (originPlace?.lat && originPlace?.lng) {
      let data = {
        radius_latitude: originPlace?.lat,
        radius_longitude: originPlace?.lng,
        radius_zonename: place,
        place_id: selectedPlace.value,
        area_type: 2,
      };
      addZonedevice(data)
        .then((response) => {
          toast.success(response.data.message);
          if (response.data.status === 200) {
            setGeoFenceRadius(null);
            setOrigin(null);
            let paramsObj = { ...params };
            paramsObj.is_reload = !paramsObj.is_reload;
            setParams(paramsObj);
          }
          navigate("/places");
        })

        .catch((error) => {
          toast.error(Utils.getErrorMessage(error));
        });
    }
  };

  const onDeleteZoneDevice = (e, item) => {
    e.preventDefault();
    setdeleteZoneID(item.uuid);
    setDelZoneModal(true);
  };

  const handleZoneClose = () => {
    setDelZoneModal(false);
  };

  const onDestroyZoneDevice = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: deleteZoneID };
    deleteZonedevice(data)
      .then((response) => {
        toast.success(response.data.message);
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        setdeleteZoneID("");
        setDelZoneModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(() => {
    const selectedDriver = DriverList.find(
      (d) => d.value === vehicle?.vehicleId
    );

    if (selectedDriver) {
      setDriver(selectedDriver.value);
    } else {
      setDriver(null);
    }
  }, [vehicle?.vehicleId]);

  const preselectedTime = new Date(); // Start with current date/time
  preselectedTime.setHours(14); // Set hours to 14 (2 PM)
  preselectedTime.setMinutes(30);

  const onResetmarker = () => {
    setOrigin("");
    setDestination("");
    setWaypoints([]);
    setWaypointsMap([]);
    setDirections(null);
    setPrice("");
    setDistance("");
    setDuration("");
    setSource("");
    setDest("");
  };

  const convertMinutesToHHMM = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    return `${String(hours).padStart(2, "0")}:${String(
      remainingMinutes
    ).padStart(2, "0")}`;
  };

  const formattedTimeDuration = convertMinutesToHHMM(
    deviceTripTotal?.totalDuration
  );

  const formattedLastTimeDuration = convertMinutesToHHMM(
    deviceLastTripTotal?.totalDuration
  );



  const handlePlaceClick = (e, item) => {
    setActiveTab("directions");
    setPlaceNamesTrip(item);
  };

  const getArrowIcon = (rotationAngle) => {
    return {
      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      scale: 5,
      fillColor: "green",
      fillOpacity: 1.0,
      strokeColor: "#ffffff",
      strokeWeight: 2,
      rotation: parseInt(rotationAngle),
    };
  };

  return (
    <>
      {vehicleLoader === true && (
        <div
          className="d-flex justify-content-center align-items-center position-fixed top-0 bottom-0 start-0 end-0 z-3"
          style={{ zIndex: "99", backgroundColor: "#ffffffc2" }}
        >
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <div className="map-section">
        <div style={{ width: "100%", height: "990" }}>
          {isLoaded ? (
            <div>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={initialZoom}
                onClick={onMapClick}
                onMapLoad={onMapLoad}
                onUnmount={onUnmount}
                options={{
                  ...mapOptions,
                  gestureHandling: "auto",
                  scrollwheel: true,
                }}
              >
                {originZone && (
                  <DrawingManager
                    onLoad={(drawingManager) => {
                      drawingManager.setOptions({
                        drawingControl: true,
                        drawingControlOptions: {
                          position:
                            window.google.maps.ControlPosition.TOP_CENTER,
                          drawingModes: [
                            window.google.maps.drawing.OverlayType.CIRCLE,
                          ],
                        },
                        circleOptions: {
                          clickable: true,
                          editable: true,
                          draggable: true,
                          strokeColor: "#26aae16e",
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: "#26aae147",
                          fillOpacity: 0.35,
                          zIndex: 1,
                        },
                      });

                      const drawingControlButton = document.querySelector(
                        ".gm-style-mtc > div > div > div"
                      );
                      if (drawingControlButton) {
                        drawingControlButton.style.width = "40px";
                        drawingControlButton.style.height = "40px";
                      }
                    }}
                    onCircleComplete={onCircleComplete}
                  />
                )}

                {zoneDeviceList?.map((item) => {
                  if (item.area_type === "1") {
                    const centerZone = {
                      lat: parseFloat(item.radius_latitude),
                      lng: parseFloat(item.radius_longitude),
                    };

                    const scaleValue = Math.sqrt(item.vehicle_radius);

                    return (
                      <Marker
                        key={item.id}
                        position={centerZone}
                        icon={{
                          path: window.google.maps.SymbolPath.CIRCLE,
                          scale: scaleValue,
                          strokeColor: "#26aae16e",
                          fillColor: "#26aae147",
                          fillOpacity: 1,
                          strokeWeight: 2,
                        }}
                      />
                    );
                  }
                })}

                {zoneDeviceList?.map((item) => {
                  if (item.area_type === "2") {
                    const centerZone = {
                      lat: parseFloat(item.radius_latitude),
                      lng: parseFloat(item.radius_longitude),
                    };

                    const image = getImageByPlaceId(item?.place_id);

                    return (
                      <Marker
                        key={item.id}
                        position={centerZone}
                        onClick={(e) => handlePlaceClick(e, item)}
                        icon={{
                          url: image,
                          scaledSize: new window.google.maps.Size(36, 36),
                        }}
                      />
                    );
                  }
                })}

                {!directions && origin && (
                  <Marker
                    position={{ lat: origin?.lat, lng: origin?.lng }}
                    draggable={isDraggable}
                    onDragEnd={(event) => onMarkerDragEnd("origin", event)}
                    icon={{
                      url: GreenFlag,
                      scaledSize: new window.google.maps.Size(40, 40),
                    }}
                  />
                )}

                {!directions && destination && (
                  <Marker
                    position={{
                      lat: destination?.lat,
                      lng: destination?.lng,
                    }}
                    draggable={isDraggable}
                    onDragEnd={(event) => onMarkerDragEnd("destination", event)}
                    icon={{
                      url: RedFlag,
                      scaledSize: new window.google.maps.Size(40, 40),
                    }}
                  />
                )}

                {directionsData && (
                  <Polyline
                    path={directionsData}
                    options={{
                      strokeColor: "red",
                      strokeOpacity: 2.0,
                      strokeWeight: 10,
                    }}
                  />
                )}
                {directionsResponse && (
                  <DirectionsRenderer directions={directionsResponse} />
                )}

                {!directions &&
                  waypoints?.map((waypoint, index) => (
                    <Marker
                      key={index}
                      position={{ lat: waypoint.lat, lng: waypoint.lng }}
                      draggable={isDraggable}
                      onDragEnd={(event) => onMarkerDragEnd(index, event)}
                    />
                  ))}

                {directions && (
                  <>
                    <Marker
                      position={{ lat: origin?.lat, lng: origin?.lng }}
                      draggable={true}
                      onDragEnd={(event) => onMarkerDragEnd("origin", event)}
                    />

                    <Marker
                      position={{
                        lat: destination?.lat,
                        lng: destination?.lng,
                      }}
                      draggable={true}
                      onDragEnd={(event) =>
                        onMarkerDragEnd("destination", event)
                      }
                    />

                    {waypoints?.map((waypoint, index) => (
                      <Marker
                        key={index}
                        position={{ lat: waypoint.lat, lng: waypoint.lng }}
                        draggable={true}
                        onDragEnd={(event) => onMarkerDragEnd(index, event)}
                      />
                    ))}
                  </>
                )}

                <DirectionsService
                  options={directionsOptions}
                  callback={directionsCallback}
                  draggable
                />
                {/* )} */}
                {directions && <DirectionsRenderer directions={directions} />}
                {directionsForDevice && (
                  <>
                    {devicedelayData?.map((deviceData, index) => {
                      const position = {
                        lat: parseFloat(deviceData?.latitude),
                        lng: parseFloat(deviceData?.longitude),
                      };

                      const formattedDateTime = moment(
                        deviceData.timestamp
                      ).format("MMMM Do YYYY, HH:mm");

                      const formattedTime = formatVehicleStoppedTime(
                        deviceData.vehicleStopedForHour,
                        deviceData.vehicleStopedForMinute
                      );

                      return (
                        <React.Fragment key={index}>
                          <Marker
                            key={index}
                            position={position}
                            onClick={() => {
                              setSelectedDevice(deviceData);
                            }}
                            icon={{
                              url:
                                deviceData.vehicle_stopped === 1 ? BlueKey : "",
                              scaledSize: new window.google.maps.Size(25, 30),
                            }}
                          />

                          <Marker
                            key={index}
                            position={position}
                            onClick={() => {
                              setSelectedMarker({
                                position: position,
                                type: "position",
                              });
                            }}
                            icon={getArrowIcon(deviceData?.angle || 90)}
                          />

                          {selectedDevice === deviceData && (
                            <InfoWindow
                              position={position}
                              onCloseClick={() => {
                                setSelectedDevice(null);
                              }}
                            >
                              <div>
                                <h3
                                  className="title-h3"
                                  style={{
                                    justifyContent: "center",
                                  }}
                                >
                                  {datas.vehicleName}
                                </h3>
                                <h3
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                    marginTop: "-10px",
                                  }}
                                >
                                  <i class="bi bi-person me-2"></i>
                                  {datas.driver_name || "-"}
                                </h3>
                                <hr
                                  style={{
                                    width: "100%",
                                    margin: "0",
                                  }}
                                />
                                <h3
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    marginTop: "10px",
                                  }}
                                >
                                  <i className="bi bi-clock me-2"></i>
                                  {formattedDateTime || "-"}
                                </h3>

                                <hr
                                  style={{
                                    width: "100%",
                                    margin: "0",
                                  }}
                                />

                                <h3
                                  style={{
                                    fontSize: "11px",
                                    fontWeight: "600",
                                    marginLeft: "114px",
                                    marginTop: "10px",
                                  }}
                                >
                                  Stopped For:
                                  {formattedTime || "-"}
                                </h3>
                              </div>
                            </InfoWindow>
                          )}
                        </React.Fragment>
                      );
                    })}
                    {directionsForDevice?.map((directions, index) => {
                      return (
                        <React.Fragment key={index}>
                          <DirectionsRenderer
                            directions={directions}
                            options={{
                              polylineOptions: {
                                strokeColor: "#27AAE2",
                                strokeOpacity: 0.8,
                                strokeWeight: 5,
                              },
                              suppressMarkers: true,
                            }}
                          />
                         
                          {selectedMarker && (
                            <InfoWindow
                              position={selectedMarker.position}
                              onCloseClick={() => {
                                setSelectedMarker(null);
                              }}
                            >
                              <div>
                                <h3
                                  className="title-h3"
                                  style={{
                                    justifyContent: "center",
                                  }}
                                >
                                  {datas.vehicleName}
                                </h3>
                                <h3
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                    marginTop: "-10px",
                                  }}
                                >
                                  <i class="bi bi-person me-2"></i>
                                  {datas.driver_name || "-"}
                                </h3>

                                <hr
                                  style={{
                                    width: "100%",
                                    margin: "0",
                                  }}
                                />
                                <h3
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    marginTop: "10px",
                                  }}
                                >
                                  <i class="bi bi-speedometer me-2"></i>
                                  {datas.velocity || 0} MPH
                                </h3>
                                <hr
                                  style={{
                                    width: "100%",
                                    margin: "0",
                                  }}
                                />
                                <h3
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    marginTop: "10px",
                                  }}
                                >
                                  <i class="bi bi-speedometer2 me-2"></i>
                                  {datas.total_mileage || 0} KM
                                </h3>

                                <hr
                                  style={{
                                    width: "100%",
                                    margin: "0",
                                  }}
                                />

                                <h3
                                  style={{
                                    fontSize: "11px",
                                    fontWeight: "600",
                                    marginLeft: "114px",
                                    marginTop: "10px",
                                  }}
                                ></h3>
                              </div>
                            </InfoWindow>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </>
                )}

                {vehicleLists?.map((item, index) => {
                  if (
                    item?.currentLatitude !== null &&
                    item?.currentLogitude !== null
                  ) {
                    return (
                      <div key={index}>
                        <Marker
                          position={{
                            lat: parseFloat(item?.currentLatitude),
                            lng: parseFloat(item?.currentLogitude),
                          }}
                          zoom={1}
                          options={{
                            icon: {
                              url: item.vehicle_image,
                              scaledSize: new window.google.maps.Size(70, 70),
                            },
                          }}
                          key={item?.uuid}
                          className={`search-item ${
                            selectedVehicle === item?.uuid
                              ? "selected"
                              : "not-traveling"
                          }`}
                          onClick={() => handleItemClick(item)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}

                {directionsForFilterDevice && (
                  <>
                    {devicedelayFilterData?.map((deviceData, index) => {
                      const position = {
                        lat: parseFloat(deviceData.latitude),
                        lng: parseFloat(deviceData.longitude),
                      };

                      const formattedDateTime = moment(
                        deviceData.timestamp
                      ).format("MMMM Do YYYY, HH:mm");

                      const formattedTime = formatVehicleStoppedTime(
                        deviceData.vehicleStopedForHour,
                        deviceData.vehicleStopedForMinute
                      );

                      return (
                        <React.Fragment key={index}>
                          <Marker
                            key={index}
                            position={position}
                            onClick={() => {
                              setSelectedDevice(deviceData);
                            }}
                            icon={{
                              url:
                                deviceData.vehicle_stopped === 1 ? BlueKey : "",
                              scaledSize: new window.google.maps.Size(25, 30),
                            }}
                          />

                          <Marker
                            key={index}
                            position={position}
                            onClick={() => {
                              setSelectedMarker({
                                position: position,
                                type: "position",
                              });
                            }}
                            icon={getArrowIcon(deviceData?.angle || 90)}
                          />

                          {selectedDevice === deviceData && (
                            <InfoWindow
                              position={position}
                              onCloseClick={() => {
                                setSelectedDevice(null);
                              }}
                            >
                              <div>
                                <h3
                                  className="title-h3"
                                  style={{
                                    justifyContent: "center",
                                  }}
                                >
                                  {datas.vehicleName}
                                </h3>
                                <h3
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                    marginTop: "-10px",
                                  }}
                                >
                                  <i class="bi bi-person me-2"></i>
                                  {datas.driver_name || "-"}
                                </h3>
                                <hr
                                  style={{
                                    width: "100%",
                                    margin: "0",
                                  }}
                                />
                                <h3
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    marginTop: "10px",
                                  }}
                                >
                                  <i className="bi bi-clock me-2"></i>
                                  {formattedDateTime || "-"}
                                </h3>

                                <hr
                                  style={{
                                    width: "100%",
                                    margin: "0",
                                  }}
                                />
                                <h3
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    marginTop: "10px",
                                  }}
                                >
                                  <i class="bi bi-speedometer me-2"></i>
                                  {datas.velocity || 0} MPH
                                </h3>
                                <hr
                                  style={{
                                    width: "100%",
                                    margin: "0",
                                  }}
                                />
                                <h3
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    marginTop: "10px",
                                  }}
                                >
                                  <i class="bi bi-speedometer2 me-2"></i>
                                  {datas.total_mileage || 0} KM
                                </h3>

                                <hr
                                  style={{
                                    width: "100%",
                                    margin: "0",
                                  }}
                                />

                                <h3
                                  style={{
                                    fontSize: "11px",
                                    fontWeight: "600",
                                    marginLeft: "114px",
                                    marginTop: "10px",
                                  }}
                                >
                                  Stopped For:
                                  {formattedTime || "-"}
                                </h3>

                                {/* Add more details as needed */}
                              </div>
                            </InfoWindow>
                          )}
                        </React.Fragment>
                      );
                    })}
                    {directionsForFilterDevice?.map((directions, index) => {
                      return (
                        <>
                          <DirectionsRenderer
                            key={index}
                            directions={directions}
                            options={{
                              polylineOptions: {
                                strokeColor: "#27AAE2",
                                strokeOpacity: 0.8,
                                strokeWeight: 5,
                              },
                              suppressMarkers: true,
                            }}
                          />

                          {selectedMarker && (
                            <InfoWindow
                              position={selectedMarker.position}
                              onCloseClick={() => {
                                setSelectedMarker(null);
                              }}
                            >
                              <div>
                                <h3
                                  className="title-h3"
                                  style={{
                                    justifyContent: "center",
                                  }}
                                >
                                  {datas.vehicleName}
                                </h3>
                                <h3
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                    marginTop: "-10px",
                                  }}
                                >
                                  <i class="bi bi-person me-2"></i>
                                  {datas.driver_name || "-"}
                                </h3>

                                <hr
                                  style={{
                                    width: "100%",
                                    margin: "0",
                                  }}
                                />
                                <h3
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    marginTop: "10px",
                                  }}
                                >
                                  <i class="bi bi-speedometer me-2"></i>
                                  {datas.velocity || 0} MPH
                                </h3>
                                <hr
                                  style={{
                                    width: "100%",
                                    margin: "0",
                                  }}
                                />
                                <h3
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    marginTop: "10px",
                                  }}
                                >
                                  <i class="bi bi-speedometer2 me-2"></i>
                                  {datas.total_mileage || 0} KM
                                </h3>

                                <hr
                                  style={{
                                    width: "100%",
                                    margin: "0",
                                  }}
                                />

                                <h3
                                  style={{
                                    fontSize: "11px",
                                    fontWeight: "600",
                                    marginLeft: "114px",
                                    marginTop: "10px",
                                  }}
                                ></h3>

                                {/* Add more details as needed */}
                              </div>
                            </InfoWindow>
                          )}
                        </>
                      );
                    })}
                  </>
                )}
              </GoogleMap>
            </div>
          ) : (
            <></>
          )}
          <div className="leaflet-control-vehicle">
            <div className="tabs">
              <nav className="tab-nav">
                <ul className="tabs-vehicle">
                  <li
                    className={`nav-link ${
                      activeTab === "search" ? "active" : ""
                    }`}
                    role="tab"
                    onClick={(e) => {
                      e.preventDefault();
                      handleTabClick("search");
                    }}
                  >
                    <span data-href="#tab-1">Search</span>
                  </li>
                  {/* <li
                  className={`nav-link ${
                    activeTab === "vehicles" ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleTabClick("vehicles");
                  }}
                >
                  <span data-href="#tab-2">Vehicles</span>
                </li> */}
                  <li
                    className={`nav-link ${
                      activeTab === "directions" ? "active" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleTabClick("directions");
                    }}
                  >
                    <span data-href="#tab-3">Directions</span>
                  </li>
                  <li
                    className={`nav-link ${
                      activeTab === "addzone" ? "active" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleTabClick("addzone");
                    }}
                  >
                    <span data-href="#tab-4">Areas</span>
                  </li>
                </ul>
              </nav>
              <div className="dashboard-block tab-content" id="nav-tabContent">
                <div
                  className={`tab-pane fade ${
                    activeTab === "search" ? "show active" : ""
                  }`}
                  id="tab-1"
                >
                  <div className="tabs">
                    <nav className="tab-nav" style={{ display: "none" }}>
                      <ul className="tabs-vehicle-suv">
                        <li className="active">
                          {/* <span data-href="#sub-tab-1">Vehicles</span> */}
                        </li>
                        <li className="d-none">
                          <span data-href="#sub-tab-2">Places</span>
                        </li>
                        <li className="d-none">
                          <span data-href="#sub-tab-3">Address</span>
                        </li>
                      </ul>
                    </nav>
                    <div className="tab active" id="sub-tab-1">
                      <div className="now-block">
                        <div className="datepicke-col">
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <DatePicker
                              value={vehicleDateRoute}
                              onChange={handleChangeStart}
                              sort
                              format="YYYY-MM-DD"
                              calendarPosition="bottom-center"
                              placeholder="Now"
                              style={{
                                border: "1px solid #CCCCCC",
                                borderRadius: "5px",
                                height: "40px",
                                margin: "1px 0",
                                padding: "2px 5px",
                                width: "301px",
                                paddingRight: "30px",
                              }}
                            />
                            <div className="date-icon">
                              <i class="bi bi-calendar"></i>
                            </div>
                            {/* <div class="vehicles-increase-decrease">
                            <button
                              type="button"
                              class="btn btn-decrease disabled"
                            >
                              {" "}
                              -1{" "}
                            </button>
                            <button
                              type="button"
                              class="btn btn-increase disabled"
                            >
                              {" "}
                              +1{" "}
                            </button>
                          </div> */}
                          </div>
                        </div>
                        <div className="button-col">
                          <div className="btn-group">
                            <button
                              // className="btn path-mode"
                              className={`btn path-mode ${
                                selectedVehicle ? "active" : "disabled"
                              }`}
                              disabled={!selectedVehicle}
                              style={{
                                backgroundColor: selectedVehicle
                                  ? "#0dcaf0"
                                  : "#cccccc",
                                borderColor: selectedVehicle
                                  ? "#0dcaf0"
                                  : "#cccccc",
                              }}
                              onClick={showRoute}
                            >
                              <img src={VehicleIcon} alt="vehicle" />
                            </button>

                            <button
                              className="btn fleet-mode"
                              onClick={handleCloseItem}
                              style={{ marginLeft: "10px" }}
                            >
                              <i
                                class="bi bi-x"
                                style={{ fontSize: "15px" }}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className="search-row">
                      <div className="search-bar">
                        <i className="bi bi-search" />
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                        />
                      </div>
                      <button className="btn btn-fleet">FLEET</button>
                    </div> */}
                      <div className="search-col" style={{ marginTop: "15px" }}>
                        <span
                          data-href="#sub-tab-1"
                          className="Vehicles-block dropdown-block"
                          onClick={handleToggleClick}
                        >
                          {isOpen ? (
                            <i class="bi bi-chevron-up"></i>
                          ) : (
                            <i class="bi bi-chevron-down"></i>
                          )}
                          Vehicles
                        </span>
                        {isOpen && (
                          <div className="search-item-container">
                            {vehicleLoader === true && (
                              <div className="text-center mt-4">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            )}
                            {!vehicleLoader && vehicleLists.length === 0 && (
                              <div className="text-center mt-3">
                                No Vehicle Available.
                              </div>
                            )}
                            {vehicleLists.map((item, index) => (
                              <>
                                {item.device_imei && (
                                  <div
                                    className={`search-item ${
                                      selectedVehicle === item.uuid
                                        ? "selected"
                                        : "not-traveling"
                                    }`}
                                    key={index}
                                    onClick={() => handleItemClick(item)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <i className="icon-radio">
                                      <img
                                        src={
                                          parseInt(item.ignition) === 1
                                            ? RadioGreenIcon
                                            : RadioIcon
                                        }
                                        alt="Radio Icon"
                                      />
                                    </i>

                                    <span>{item.vehicleName}</span>
                                  </div>
                                )}
                              </>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="tab" id="sub-tab-2">
                      <h2>This is the second sub tab!</h2>
                    </div>
                    <div className="tab" id="sub-tab-3">
                      <h2>This is the third sub tab!</h2>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "vehicles" ? "show active" : ""
                  }`}
                  id="tab-2"
                >
                  {/* <div className="search-col">
                  {vehicleLists?.map((item, index) => {
                    return (
                      <div className="search-item not-traveling" key={index}>
                        <i className="icon-radio">
                          {item?.device_imei ? (
                            <img src={RadioGreenIcon} alt="..." />
                          ) : (
                            <img src={RadioIcon} alt="..." />
                          )}
                        </i>
                        <div
                          key={item.uuid}
                          className={`search-item ${
                            selectedVehicle === item.uuid
                              ? "selected"
                              : "not-traveling"
                          }`}
                          onClick={() => handleItemClick(item)}
                          style={{ cursor: "pointer" }}
                        >
                          <span>{item.vehicleName}</span>
                        </div>
                      </div>
                    );
                  })}
                </div> */}
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === origin || activeTab === "directions"
                      ? "show active"
                      : ""
                  }`}
                  id="tab-3"
                >
                  {placeNamesTrip?.radius_zonename ? (
                    <div className="nav-flag">
                      <div className="nav-li">
                        <img src={GreenFlag} alt="Green Flag" />
                        <h4 style={{ fontSize: "13px" }}>
                          {placeNamesTrip?.radius_zonename}
                        </h4>
                      </div>
                    </div>
                  ) : source ? (
                    <div className="nav-flag">
                      <div className="nav-li">
                        <img src={GreenFlag} alt="Green Flag" />
                        <h4 style={{ fontSize: "13px" }}>
                          {origin?.placeName || source?.placeName}
                        </h4>
                      </div>
                    </div>
                  ) : (
                    <Search panTo={panTo} />
                  )}

                  {dest ? (
                    <>
                      <div className="nav-flag" style={{ marginTop: "-31px" }}>
                        <div className="nav-li">
                          <img src={RedFlag} alt="red flag" />
                          <h4 style={{ fontSize: "13px" }}>
                            {destination?.placeName || destination?.placeName}
                          </h4>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <SearchDestination panToDestination={panToDestination} />
                    </>
                  )}

                  {renderSearchDestination()}

                  {waypointsMap?.length > 0 ? (
                    <div className="nav-li">
                      <i
                        className="bi bi-geo-alt"
                        style={{ padding: "10px" }}
                      ></i>
                      <h4
                        style={{
                          fontSize: "13px",
                          marginTop: "-22px",
                          marginLeft: "34px",
                        }}
                      >
                        Stops
                      </h4>

                      {waypoints?.map((item, index) => (
                        <h4
                          key={index}
                          style={{
                            fontSize: "13px",
                            marginLeft: "20px",
                          }}
                        >
                          <i
                            className="bi bi-geo-alt"
                            style={{ padding: "10px" }}
                          ></i>{" "}
                          {item.placeName || "-"}
                        </h4>
                      ))}
                    </div>
                  ) : (
                    origin &&
                    destination && (
                      <div
                        style={{
                          margin: "10px",
                          marginTop: "-15px",
                        }}
                      >
                        <i className="bi bi-geo-alt me-2"></i>
                        <button
                          onClick={handleButtonClick}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                        >
                          Add Destination
                        </button>
                      </div>
                    )
                  )}

                  <div style={{ margin: "8px" }}>
                    <div
                      className="card total-card"
                      style={{ backgroundColor: "#def0f9" }}
                    >
                      <div className="card-body">
                        <div>
                          <h4 style={{ fontSize: "12px", fontWeight: "600" }}>
                            Total Distance & Travel
                          </h4>
                        </div>
                        <div className="row">
                          <div
                            className="col"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            Distance
                          </div>
                          <div
                            className="col"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            {distance}km
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            Time to travel
                          </div>
                          <div
                            className="col"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            {duration}
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            Price
                          </div>
                          <div
                            className="col"
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            ${price}
                          </div>
                        </div>
                        {origin && destination && (
                          <div>
                            <h4
                              style={{
                                fontSize: "14px",
                                display: "flex",
                                justifyContent: "end",
                                marginTop: "10px",
                              }}
                            >
                              <button
                                onClick={onMoreDetails}
                                style={{
                                  border: "none",
                                  backgroundColor: "transparent",
                                  color: " #1c4ab7",
                                  marginBottom: "-14px",
                                }}
                              >
                                View details{" "}
                                <i
                                  className="bi bi-eye"
                                  style={{ marginTop: "2px" }}
                                ></i>
                              </button>
                            </h4>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      padding: "10px",
                    }}
                  >
                    {origin && destination && (
                      <Button
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#DEF0F9",
                          border: "none",
                          color: "black",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                        onClick={onScheduleOpen}
                      >
                        Schedule Trip
                      </Button>
                    )}

                    {origin && destination && (
                      <Button
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#DEF0F9",
                          border: "none",
                          color: "black",
                          fontWeight: "600",
                          fontSize: "15px",
                          marginLeft: "10px",
                        }}
                        onClick={onResetmarker}
                      >
                        Reset
                      </Button>
                    )}
                  </div>
                </div>

                <div
                  className={`tab-pane fade ${
                    activeTab === "addzone" ? "show active" : ""
                  }`}
                  id="tab-4"
                >
                  <nav className="tab-nav">
                    <ul className="sub-tabs tabs-vehicle">
                      {" "}
                      {/* Sub-tabs container */}
                      <li
                        className={`sub-tab ${
                          activeSubTab === "Zone" ? "active" : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubTabClick("Zone");
                        }}
                      >
                        <span>Zone</span>
                      </li>
                      <li
                        className={`sub-tab ${
                          activeSubTab === "Places" ? "active" : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubTabClick("Places");
                        }}
                      >
                        <span data-href="#sub-tab-1">Places</span>
                      </li>
                    </ul>
                  </nav>
                  <div
                    className="dashboard-block tab-content"
                    id="nav-tabContent"
                  >
                    <div
                      className={`tab-pane fade ${
                        activeSubTab === "Zone" ? "show active" : ""
                      }`}
                      id="sub-tab-1"
                    >
                      <Form method={`post`} onSubmit={handleAddZoneForm}>
                        <div className="col-md-12">
                          <i
                            className="bi bi-geo me-2"
                            style={{
                              marginLeft: "10px",
                            }}
                          ></i>
                          <Label
                            style={{
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            Zone
                          </Label>
                          <Input
                            type="text"
                            value={zone}
                            onChange={handleZoneChange}
                            placeholder="Enter name"
                            style={{
                              width: "91%",
                              marginLeft: "13px",
                            }}
                          />
                        </div>
                        <div className="col-md-12">
                          <SearchZone panToZone={panToZone} />
                        </div>

                        <div
                          className="col-md-12"
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginLeft: "-7px",
                          }}
                        >
                          {geoFenceRadius?.radius && (
                            <Button
                              color="success btn-info add-zone"
                              type={`submit`}
                              className={`btn-square`}
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                marginLeft: "-15px",
                              }}
                            >{`Add Zone`}</Button>
                          )}
                        </div>
                      </Form>

                      <div className="search-col">
                        <span
                          data-href="#sub-tab-1"
                          className="Vehicles-block dropdown-block2"
                          onClick={handleToggleZoneClick}
                        >
                          {isOpenZone ? (
                            <i class="bi bi-chevron-up"></i>
                          ) : (
                            <i class="bi bi-chevron-down"></i>
                          )}
                          Vehicles Zone
                        </span>
                        {isOpenZone && (
                          <div className="search-item-container options-item">
                            {zoneDeviceList.map((item, index) => {
                              if (item.area_type !== "1") return null;

                              return (
                                <div
                                  className="search-item"
                                  key={index}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="row">
                                    <i
                                      onClick={(e) =>
                                        onDeleteZoneDevice(e, item)
                                      }
                                      className="bi bi-x icon-x"
                                      style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        fontSize: "24px",
                                        color: "#048fa7",
                                      }}
                                    ></i>
                                    <div className="col-md-12">
                                      <span>
                                        <img
                                          src={GeoFense}
                                          style={{
                                            width: "23px",
                                            height: "23px",
                                          }}
                                          alt=""
                                        />
                                        <span style={{ marginLeft: "5px" }}>
                                          {item.radius_zonename}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeSubTab === "Places" ? "show active" : ""
                      }`}
                      id="sub-tab-1"
                    >
                      <Form method={`post`} onSubmit={handleAddPlaceForm}>
                        <div className="col-md-12">
                          <i
                            className="bi bi-geo me-2"
                            style={{
                              marginLeft: "10px",
                            }}
                          ></i>
                          <Label
                            style={{
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            Place
                          </Label>
                          <Input
                            type="text"
                            value={place}
                            onChange={handlePlaceChange}
                            placeholder="Enter name"
                            style={{
                              width: "91%",
                              marginLeft: "13px",
                            }}
                          />
                        </div>
                        <div className="col-md-12">
                          <SearchPlace
                            panToPlace={panToPlace}
                            setOriginplace={setOriginPlace}
                          />
                        </div>
                        <div className="col-md-12">
                          <div
                            style={{
                              width: "290px",
                              padding: "10px",
                            }}
                          >
                            <Select
                              options={placesList}
                              value={selectedPlace}
                              onChange={setSelectedPlace}
                              getOptionLabel={(option) => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={option.image}
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: 10,
                                    }}
                                    alt=""
                                  />
                                  {option.label}
                                </div>
                              )}
                              getOptionValue={(option) => option.value}
                              placeholder="Select a Place"
                            />
                          </div>
                        </div>

                        {originPlace?.lat && originPlace?.lng && (
                          <div
                            className="col-md-12"
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              marginLeft: "-7px",
                            }}
                          >
                            <Button
                              color="success btn-info add-zone"
                              type={`submit`}
                              className={`btn-square`}
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                marginLeft: "-15px",
                              }}
                            >{`Add Place`}</Button>
                          </div>
                        )}
                      </Form>

                      <div className="search-col">
                        <span
                          data-href="#sub-tab-1"
                          className="Vehicles-block dropdown-block2"
                          onClick={handleTogglePlaceClick}
                        >
                          {isOpenPlace ? (
                            <i class="bi bi-chevron-up"></i>
                          ) : (
                            <i class="bi bi-chevron-down"></i>
                          )}
                          Vehicles Place
                        </span>
                        {isOpenPlace && (
                          <div className="search-item-container options-item">
                            {zoneDeviceList.map((item, index) => {
                              if (item.area_type !== "2") return null;

                              const placeName = getPlaceName(item.place_id);

                              return (
                                <div
                                  className="search-item"
                                  key={index}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="row">
                                    <i
                                      onClick={(e) =>
                                        onDeleteZoneDevice(e, item)
                                      }
                                      className="bi bi-x icon-x"
                                      style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        fontSize: "24px",
                                        color: "#048fa7",
                                      }}
                                    ></i>
                                    <div className="col-md-12">
                                      <span>
                                        <img
                                          src={placeName}
                                          style={{
                                            width: "25px",
                                            height: "20px",
                                          }}
                                          alt=""
                                        />
                                        <span style={{ margin: "5px" }}>
                                          {item.radius_zonename}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {uuid && (
            <div className="hamburger-map">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h4 style={{ fontSize: "18px", fontWeight: "600" }}>
                  {deviceDetails.vehicleName}
                </h4>
                <span>
                  <i
                    class="bi bi-x"
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "15px",
                      top: "6px",
                      fontSize: "26px",
                    }}
                    onClick={handleCloseItem}
                  ></i>
                </span>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Speedometer
                  value={deviceDetails.speed}
                  currentValueText={deviceDetails.total_odpmeter}
                  fontFamily="squada-one"
                  width={150}
                  height={150}
                >
                  <Background />
                  <Arc />
                  <Needle />
                  <Progress />
                  <Marks fontSize={12} />
                  <Indicator fontSize={12} />
                </Speedometer>
              </div>
              <div className="device-item not-traveling device-item-blck">
                <div className="input-group-dashboard">
                  {/* <i class="bi bi-funnel-fill"></i> */}
                  <input
                    type="date"
                    id="vehicleDate"
                    className="form-control"
                    value={vehicleDate}
                    onChange={(e) => setVehicleDate(e.target.value)}
                    placeholder="Vehicle Date"
                    style={{ marginBottom: "-15px" }}
                  />
                </div>
              </div>
              <div className="device-search-col">
                <div className="device-item not-traveling">
                  <span className="device-b">Driver:</span>
                  <span className="divice-d" style={{ marginLeft: "4px" }}>
                    {deviceDetails.driver_name}
                  </span>
                </div>
                <div className="device-item not-traveling">
                  <span className="device-b">Fuel Level:</span>
                  <span className="divice-d">
                    {deviceDetails.fuel_level || 0}
                  </span>
                </div>
                <div className="device-item not-traveling">
                  <span className="device-b">Plate No:</span>
                  <span className="divice-d">
                    {deviceDetails.vehicle_plate_no}
                  </span>
                </div>
                <div className="device-item not-traveling">
                  <span className="device-b">Speed:</span>
                  <span className="divice-d">{deviceDetails.speed}</span>
                </div>
              </div>
            </div>
          )}
          {devicedelayVehicleTripData?.length > 0 ? (
            <div className="card card-footer">
              <div className="card-footer" style={{ paddingTop: 0 }}>
                <div className="row">
                  <div className="panel-heading">
                    <div className="col-left">
                      <h3>
                        <span>{datas.vehicleName}</span>
                      </h3>
                      <h3 style={{ marginLeft: "10px" }}>
                        <strong>Driver:</strong>
                        <span style={{ marginLeft: "4px" }}>
                          {datas.driver_name}
                        </span>
                      </h3>
                      <h3 style={{ marginLeft: "10px" }}>
                        <strong>Total Mileage:</strong>
                        {deviceTripTotal?.totalKilometer != null &&
                        !isNaN(deviceTripTotal?.totalKilometer) ? (
                          <span style={{ marginLeft: "4px" }}>
                            {parseFloat(deviceTripTotal.totalKilometer).toFixed(
                              2
                            )}{" "}
                            Km
                          </span>
                        ) : (
                          <span>0 Km</span>
                        )}
                      </h3>
                      <h3 style={{ marginLeft: "10px" }}>
                        <strong>Total Duration:</strong>
                        <span style={{ marginLeft: "4px" }}>
                          {formattedTimeDuration}
                        </span>
                      </h3>
                    </div>
                    {devicedelayVehicleTripData.length > 0 && (
                      <div className="col-right">
                        <ul className="div-ul">
                          <li
                            className="active"
                            style={{ cursor: "pointer" }}
                            onClick={handleVehicleTripOpen}
                          >
                            View All Trip
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="block-one-div">
                    {devicedelayVehicleTripData
                      ?.slice(0, 4)
                      .map((vehicletripData) => {
                        let formattedStartDate = moment(
                          vehicletripData.tripStartTime
                        ).format("D MMM HH:mm");
                        let formattedEndDate = moment(
                          vehicletripData?.tripEndTime
                        ).format("D MMM HH:mm");

                        const formattedTimeDurationTime = convertMinutesToHHMM(
                          vehicletripData?.tripDuration
                        );

                        const extractAreaName = (address) => {
                          const parts = address?.split(",");
                          const lastPart =
                            parts?.length > 2
                              ? parts[parts?.length - 2].trim()
                              : "";
                          const areaName = lastPart?.split(" ").pop();
                          return areaName;
                        };

                        const areaName = extractAreaName(
                          vehicletripData?.arrivalName
                        );
                        const areaNames = extractAreaName(
                          vehicletripData?.departureName
                        );

                        return (
                          <Button
                            onClick={() => {
                              setTripData(vehicletripData);
                              handleRouteDisplay(vehicletripData);
                            }}
                            key={vehicletripData.trip}
                          >
                            <div className="col-cv" key={vehicletripData.trip}>
                              <h3
                                style={{ fontSize: "13px", fontWeight: "600" }}
                              >
                                {vehicletripData?.trip}{" "}
                                {vehicletripData?.tripKilometer} Km -{" "}
                                {formattedTimeDurationTime}
                              </h3>
                              <h4 className="icon-col-1">
                                <img src={GreenFlag} alt="Green Flag" />
                                <span style={{ fontSize: "12px" }}>
                                  {formattedStartDate || "-"} {areaName}
                                </span>
                              </h4>
                              <h4 className="icon-col-1">
                                <img src={RedFlag} alt="Red Flag" />
                                {vehicletripData?.tripEndTime === "" ? (
                                  <span style={{ fontSize: "12px" }}>-</span>
                                ) : (
                                  <span style={{ fontSize: "12px" }}>
                                    {formattedEndDate} {areaNames}
                                  </span>
                                )}
                              </h4>
                            </div>
                          </Button>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          ) : devicedelayVehicleAllLastTripData?.length > 0 ? (
            <div className="card card-footer">
              <div className="card-footer" style={{ paddingTop: 0 }}>
                <div className="row">
                  <div className="panel-heading">
                    <div className="col-left">
                      <h3>
                        <span>{datas.vehicleName}</span>
                      </h3>
                      <h3 style={{ marginLeft: "10px" }}>
                        <strong>Driver:</strong>
                        <span style={{ marginLeft: "4px" }}>
                          {datas.driver_name}
                        </span>
                      </h3>
                      <h3 style={{ marginLeft: "10px" }}>
                        <strong>Total Mileage:</strong>
                        {deviceLastTripTotal?.totalKilometer != null &&
                        !isNaN(deviceLastTripTotal?.totalKilometer) ? (
                          <span style={{ marginLeft: "4px" }}>
                            {parseFloat(
                              deviceLastTripTotal?.totalKilometer
                            ).toFixed(2)}{" "}
                            Km
                          </span>
                        ) : (
                          <span>0 Km</span>
                        )}
                      </h3>
                      <h3 style={{ marginLeft: "10px" }}>
                        <strong>Total Duration:</strong>
                        <span style={{ marginLeft: "4px" }}>
                          {formattedLastTimeDuration}
                        </span>
                      </h3>
                    </div>
                    {devicedelayVehicleAllLastTripData.length > 0 && (
                      <div className="col-right">
                        <ul className="div-ul">
                          <li
                            className="active"
                            style={{ cursor: "pointer" }}
                            onClick={handleVehicleTripOpen}
                          >
                            View All Trip
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="block-one-div">
                    {devicedelayVehicleAllLastTripData
                      ?.slice(0, 4)
                      .map((vehicletripData) => {
                        let formattedStartDate = moment(
                          vehicletripData.tripStartTime
                        ).format("D MMM HH:mm");
                        let formattedEndDate = moment(
                          vehicletripData?.tripEndTime
                        ).format("D MMM HH:mm");

                        const formattedTimeDurationTime = convertMinutesToHHMM(
                          vehicletripData?.tripDuration
                        );

                        const extractAreaName = (address) => {
                          const parts = address?.split(",");
                          const lastPart =
                            parts?.length > 2
                              ? parts[parts?.length - 2].trim()
                              : "";
                          const areaName = lastPart?.split(" ").pop();
                          return areaName;
                        };

                        const areaName = extractAreaName(
                          vehicletripData?.arrivalName
                        );
                        const areaNames = extractAreaName(
                          vehicletripData?.departureName
                        );

                        return (
                          <Button
                            onClick={() => {
                              setTripData(vehicletripData);
                              handleRouteDisplay(vehicletripData);
                            }}
                            key={vehicletripData.trip}
                          >
                            <div className="col-cv" key={vehicletripData.trip}>
                              <h3
                                style={{ fontSize: "13px", fontWeight: "600" }}
                              >
                                {vehicletripData?.trip}{" "}
                                {vehicletripData?.tripKilometer} Km -{" "}
                                {formattedTimeDurationTime}
                              </h3>
                              <h4 className="icon-col-1">
                                <img src={GreenFlag} alt="Green Flag" />
                                <span style={{ fontSize: "12px" }}>
                                  {formattedStartDate || "-"} {areaName}
                                </span>
                              </h4>
                              <h4 className="icon-col-1">
                                <img src={RedFlag} alt="Red Flag" />
                                {vehicletripData?.tripEndTime === "" ? (
                                  <span style={{ fontSize: "12px" }}>-</span>
                                ) : (
                                  <span style={{ fontSize: "12px" }}>
                                    {formattedEndDate} {areaNames}
                                  </span>
                                )}
                              </h4>
                            </div>
                          </Button>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="card card-footer">
              <div className="card-footer" style={{ paddingTop: 0 }}>
                <div
                  className="row"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "70px",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "32px",
                    }}
                  >
                    No Vehicle Trip Found
                  </span>
                </div>
              </div>
            </div>
          )}

          {uuid && devicedelayFilterData?.length === 0 && (
            <div class="card  card-footer">
              <div class="card-footer" style={{ paddingTop: 0 }}>
                <div
                  className="row"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "70px",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "32px",
                    }}
                  >
                    No Route Found
                  </span>
                </div>
              </div>
            </div>
          )}

          <Modal isOpen={delModal} style={{ marginTop: "250px" }}>
            <ModalHeader>{`View Details`}</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col">
                  <div>
                    Total Distance:
                    <span style={{ color: "gray" }}> {distance}</span>
                  </div>
                </div>
                <div className="col">
                  <div>
                    Total Time:
                    <span style={{ color: "gray" }}> {duration}</span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div>
                    <i
                      className="bi bi-flag-fill me-2"
                      style={{ color: "#7ED328" }}
                    ></i>
                    <span style={{ color: "gray" }}>
                      {origin?.placeName || "-"}
                    </span>
                  </div>
                </div>
              </div>
              <table
                className="table"
                style={{ marginBottom: "33px", marginTop: "15px" }}
              >
                <thead>
                  <tr></tr>
                </thead>
                <tbody>
                  {finalResult?.map((item, i) => {
                    return (
                      <tr>
                        <th scope="row">{item.index}</th>
                        <td>{item.distance.text}</td>
                        <td>{item.duration.text}</td>
                        <td>{item.start_address}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="row">
                <div className="col">
                  <div>
                    <i
                      className="bi bi-flag-fill me-2"
                      style={{ color: "red" }}
                    ></i>
                    <span style={{ color: "gray" }}>
                      {destination?.placeName || "-"}
                    </span>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type={`button`}
                outline
                onClick={onMoreDetailsClose}
                className={`btn-square`}
              >{`Close`}</Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={scheduleModal} style={{ marginTop: "170px" }}>
            <div className="modal-content" style={{ width: "140%" }}>
              <ModalHeader>{`Schedule Trip`}</ModalHeader>
              <Form method={`post`} onSubmit={handleStartTripForm}>
                <ModalBody>
                  <div className="row">
                    <div className="col-xl-6">
                      <div>
                        Total Distance:
                        <span style={{ color: "gray" }}> {distance}</span>
                      </div>
                    </div>
                    <div
                      className="col-xl-6"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <div>
                        Total Time:
                        <span style={{ color: "gray" }}> {duration}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-xl-12">
                      <div>
                        {/* <i
                        className="bi bi-flag-fill me-2"
                        style={{ color: "#7ED328" }}
                      ></i>Start */}
                        <span style={{ marginLeft: "7px", fontWeight: "500" }}>
                          Start
                        </span>
                        <span style={{ color: "gray", marginLeft: "35px" }}>
                          {origin?.placeName || placeNamesTrip?.radius_zonename}
                        </span>
                      </div>
                    </div>
                  </div>
                  <table
                    className="table"
                    style={{ marginBottom: "33px", marginTop: "15px" }}
                  >
                    <thead>
                      <tr></tr>
                    </thead>
                    <tbody>
                      {finalResult?.map((item, index) => {
                        return (
                          <tr>
                            {/* <th scope="row" key={index}>{item.index}</th> */}
                            {/* <td>
                            <i className="bi bi-geo-alt me-2"></i>
                          </td> */}
                            <td
                              key={index}
                              style={{ width: "70px", fontWeight: "500" }}
                            >{`Stop ${index + 1}`}</td>
                            <td style={{ color: "gray" }}>
                              {item.start_address}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="row">
                    <div className="col-xl-12">
                      <div>
                        {/* <i
                              className="bi bi-flag-fill me-2"
                              style={{ color: "red" }}
                            ></i>End */}
                        <span style={{ marginLeft: "7px", fontWeight: "500" }}>
                          End
                        </span>
                        <span style={{ color: "gray", marginLeft: "35px" }}>
                          {destination?.placeName || "-"}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div className="row mt-2">
                      <div className="col-xl-6">
                        <div>
                          <div className="mb-2">
                            <i className="bi bi-truck me-2"></i>
                            <span
                              style={{ fontSize: "16px", fontWeight: "600" }}
                            >
                              Vehicle
                            </span>
                          </div>
                          <Select
                            options={vehicleList}
                            value={vehicleList?.find(
                              (option) => option.value === vehicle
                            )}
                            onChange={(selectedOption) =>
                              setVehicle((prevData) => ({
                                ...prevData,
                                vehicleId: selectedOption.value,
                              }))
                            }
                            placeholder={"Select Vehicle"}
                          />
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div>
                          <div className="mb-2">
                            <i className="bi bi-person me-2"></i>
                            <span
                              style={{ fontSize: "16px", fontWeight: "600" }}
                            >
                              Driver
                            </span>
                          </div>
                          <Select
                            options={DriverList}
                            value={DriverList.find(
                              (option) => option.value === driver
                            )}
                            onChange={(selectedOption) =>
                              setDriver((prevData) => ({
                                ...prevData,
                                driverId: selectedOption.value,
                              }))
                            }
                            placeholder={"Select Driver"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row mt-2">
                      <div className="col-xl-6">
                        <div className="mb-2">
                          <i className="bi bi-clock me-2"></i>
                          <span style={{ fontSize: "16px", fontWeight: "600" }}>
                            Trip Date & Time
                          </span>
                        </div>
                        <DatePicker
                          selected={dates}
                          onChange={handleChangeDate}
                          dateFormat="yyyy-MM-dd"
                          calendarPosition="bottom-center"
                          placeholder="Select Date"
                          style={{
                            border: "1px solid #CCCCCC",
                            borderRadius: "5px",
                            height: "40px",
                            margin: "1px 0",
                            padding: "2px 5px",
                            width: "301px",
                            paddingRight: "30px",
                          }}
                        />
                      </div>

                      <div className="col-xl-6">
                        <div className="mb-2">
                          <i className="bi bi-clock me-2"></i>
                          <span style={{ fontSize: "16px", fontWeight: "600" }}>
                            Estimated Date & Time
                          </span>
                        </div>
                        <DatePicker
                          selected={dateTime}
                          onChange={handleChangeDateTime}
                          showTimeSelect
                          timeIntervals={15}
                          timeFormat="HH:mm"
                          dateFormat="yyyy-MM-dd HH:mm"
                          calendarPosition="bottom-center"
                          placeholder="Select Date"
                          style={{
                            border: "1px solid #CCCCCC",
                            borderRadius: "5px",
                            height: "40px",
                            margin: "1px 0",
                            padding: "2px 5px",
                            width: "301px",
                            paddingRight: "30px",
                          }}
                        />
                        {error && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginTop: "5px",
                            }}
                          >
                            Estimated date & time must be after trip date &
                            time.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row mt-2">
                      <div className="col-xl-6">
                        <div>
                          <div className="mb-2">
                            <i className="bi bi-shop me-2"></i>
                            <span
                              style={{ fontSize: "16px", fontWeight: "600" }}
                            >
                              Trip Name
                            </span>
                          </div>

                          <input
                            type="text"
                            id="tripName"
                            className="form-control"
                            value={tripName}
                            onChange={(e) => setTripName(e.target.value)}
                            placeholder="Trip Name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    type={`button`}
                    outline
                    onClick={onScheduleClose}
                    className={`btn-square`}
                  >{`Close`}</Button>
                  <Button
                    color="success btn-info"
                    type={`submit`}
                    className={`btn-square`}
                  >{`Schedule Trip`}</Button>
                </ModalFooter>
              </Form>
            </div>
          </Modal>

          <Modal isOpen={vehicleTripModal} style={{ marginTop: "170px" }}>
            <div className="modal-content" style={{ width: "140%" }}>
              <ModalHeader>
                {" "}
                {vehicleDate
                  ? "Vehcile Details History"
                  : "Vehicle Trip Details"}
              </ModalHeader>
              <ModalBody>
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <input
                      type="date"
                      id="vehicleDate"
                      className="form-control"
                      value={vehicleDate}
                      onChange={(e) => setVehicleDate(e.target.value)}
                      placeholder="Vehicle Date"
                    />
                  </div>
                </div>
                <div className="table-responsive">
                  <div className="page-table-row">
                    <table
                      className={`table table-striped shadow-none ${
                        isLoading ? "loading" : ""
                      }`}
                    >
                      <thead>
                        <tr>
                          <th scope={`col`}>{`Trip`}</th>
                          <th scope={`col`}>{`Departure Time`}</th>
                          <th scope={`col`}>{`Arrival Time`}</th>
                          <th scope={`col`}>{`Stop`}</th>
                          {/* <th scope={`col`}>{`Arrival OdOmeter`}</th> */}
                        </tr>
                      </thead>

                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan={6} className="text-center">
                              <div
                                className="spinner-border text-primary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {devicedelayVehicleTripData &&
                              devicedelayVehicleTripData?.map((item, i) => {
                                let formattedStartDate = moment(
                                  item.tripStartTime
                                ).format("YYYY-MM-DD HH:mm:ss");
                                let formattedEndDate = moment(
                                  item.tripEndTime
                                ).format("YYYY-MM-DD HH:mm:ss");
                                const startTime = moment(item.tripStartTime);
                                const endTime = moment(item.tripEndTime);
                                const duration = moment.duration(
                                  endTime.diff(startTime)
                                );

                                const formattedDuration = `${Math.floor(
                                  duration.asHours()
                                )}Hour:${duration.minutes()}Minutes`;

                                return (
                                  <>
                                    <tr>
                                      <td>{item.trip}</td>
                                      <td>{formattedStartDate}</td>
                                      {item.tripEndTime === "" ? (
                                        <td>-</td>
                                      ) : (
                                        <td>{formattedEndDate || "-"}</td>
                                      )}
                                      {item.tripEndTime === "" ? (
                                        <td>00:00:00</td>
                                      ) : (
                                        <td>{formattedDuration || "-"}</td>
                                      )}
                                      {/* <td>
                            {item?.logs?.map((logItem, index) => (
                              <td>{logItem[0]?.total_odpmeter}</td>
                            ))}
                          </td> */}
                                    </tr>
                                  </>
                                );
                              })}
                          </>
                        )}

                        {devicedelayVehicleTripData?.length === 0 && (
                          <tr>
                            <td
                              colSpan={7}
                              className={`text-center`}
                            >{`Record Not Found`}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  type={`button`}
                  outline
                  onClick={handleVehicleTripClose}
                  className={`btn-square btn-sm`}
                >{`Close`}</Button>
              </ModalFooter>
            </div>
          </Modal>

          <Modal isOpen={delZoneModal}>
            <ModalHeader>{`Delete Redius Zone`}</ModalHeader>
            <ModalBody>
              <p
                className={`mb-0`}
              >{`Are you sure you want to delete this Redius Zone?`}</p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type={`button`}
                disabled={isDelButtonLoad}
                outline
                onClick={handleZoneClose}
                className={`btn-square`}
              >{`Close`}</Button>
              {!isDelButtonLoad && (
                <Button
                  color="secondary"
                  type={`button`}
                  className={`btn-square`}
                  onClick={onDestroyZoneDevice}
                >{`Delete`}</Button>
              )}
              {isDelButtonLoad && (
                <Button
                  color="secondary"
                  type={`button`}
                  disabled={true}
                  className={`btn-square`}
                >
                  <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
                </Button>
              )}
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </>
  );
};

const Search = ({ panTo, setOrigin }) => {
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      if (results.length === 0) {
        throw new Error("No results found");
      }
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
      setOrigin({ lat, lng, time: new Date() });
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup style={{ padding: "10px", paddingBottom: "0" }}>
          <i className="bi bi-geo me-2"></i>
          <Label style={{ fontSize: "16px", fontWeight: "600" }}>Source</Label>
          <Input
            type="text"
            value={value}
            onChange={handleInput}
            placeholder="Search your location"
          />
        </FormGroup>
      </Form>
      {status === "OK" && (
        <ListGroup>
          {data.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

const SearchZone = ({ panToZone, setOriginZone }) => {
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      if (results.length === 0) {
        throw new Error("No results found");
      }
      const { lat, lng } = await getLatLng(results[0]);
      panToZone({ lat, lng });
      setOriginZone({ lat, lng, time: new Date() });
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup style={{ padding: "10px", paddingBottom: "0" }}>
          <i className="bi bi-geo me-2"></i>
          <Label style={{ fontSize: "16px", fontWeight: "600" }}>
            Search Location
          </Label>
          <Input
            type="text"
            value={value}
            onChange={handleInput}
            placeholder="Search your location"
          />
        </FormGroup>
      </Form>
      {status === "OK" && (
        <ListGroup>
          {data.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

const SearchPlace = ({ panToPlace, setOriginplace }) => {
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      if (results.length === 0) {
        throw new Error("No results found");
      }
      const { lat, lng } = await getLatLng(results[0]);
      panToPlace({ lat, lng });
      setOriginplace({ lat, lng, time: new Date() });
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup style={{ padding: "10px", paddingBottom: "0" }}>
          <i className="bi bi-geo me-2"></i>
          <Label style={{ fontSize: "16px", fontWeight: "600" }}>
            Search Location
          </Label>
          <Input
            type="text"
            value={value}
            onChange={handleInput}
            placeholder="Search your location"
          />
        </FormGroup>
      </Form>
      {status === "OK" && (
        <ListGroup>
          {data.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

const SearchDestination = ({ panToDestination, setDestination }) => {
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panToDestination({ lat, lng });

      setDestination({ lat, lng, time: new Date() });
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup
          style={{
            padding: "10px",
            marginBottom: "0rem !important",
            marginTop: "-20px",
          }}
        >
          <i className="bi bi-geo-alt me-2"></i>
          <Label style={{ fontSize: "16px", fontWeight: "600" }}>
            Destination
          </Label>

          <Input
            type="text"
            value={value}
            onChange={handleInput}
            placeholder="Search your location"
          />
        </FormGroup>
      </Form>
      {status === "OK" && (
        <ListGroup>
          {data.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

const SearchStop = ({ panToStop, setWaypoints, waypoints }) => {
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panToStop({ lat, lng });
      setWaypoints([...waypoints, { lat, lng, time: new Date() }]);
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup
          style={{
            padding: "10px",
            marginBottom: "0rem !important",
            marginTop: "-20px",
          }}
        >
          <i className="bi bi-geo-alt me-2"></i>
          <Label style={{ fontSize: "16px", fontWeight: "600" }}>Stop</Label>

          <Input
            type="text"
            value={value}
            onChange={handleInput}
            placeholder="Search your location"
          />
        </FormGroup>
      </Form>
      {status === "OK" && (
        <ListGroup>
          {data.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default Dashboard;
