import axiosInstance from "./AxiosInstance";

export function actionAddOrderState(data) {
    return axiosInstance.post(`/order-stat/create`, data);
}

export function actionUpdateOrderState(data) {
    return axiosInstance.post(`/order-stat/update`,data)
}

export function getOrderStateList(data) {
    return axiosInstance.post(`/order-stat/list`, data);
}

export function actionDelOrderState(data) {
    return axiosInstance.post(`/order-stat/delete`, data);
}
