import React from 'react';
import { useNavigate } from "react-router-dom";

const ForbiddenPage = () => {
    const navigate = useNavigate();
    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="text-center">
                <h1 className="mb-4">403 - Forbidden</h1>
                <p>You don't have permission to access this page.</p>
                <button className="btn btn-dark" onClick={() => {
                    navigate('/');
                }}>Home
                </button>
            </div>
        </div>
    );
};

export default ForbiddenPage;
