import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus-i.svg";

import Select from "react-select";
import {
  createAddDriverLicense,
  destoryDriverLicense,
  getDriverListLicense,
} from "../../services/EmployeeService";
import { getAccountLicensesList } from "../../services/ManagementService";

const tableLengthList = configDb.data.dataTableLength;

const LicenseDriver = ({ setActiveTab }) => {
  const navigate = useNavigate();
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [vehicleList, setVehicleList] = useState([]);
  const [licenseClassList, setLicenseClassList] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [delSubscription, setDelSubscription] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [licensedata, setLicenseData] = useState({
    license_class_id: "",
    license_number: "",
    expire_date: "",
  });
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let DriverIdss = localStorage.getItem("driverIds");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    driver_id: DriverIdss,
  });

  useEffect(
    function () {
      let data = params;
      getAccountLicensesList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].licenses_class,
            });
          }
          setLicenseClassList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getDriverListLicense(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setVehicleList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const onAddSubscription = (e) => {
    e.preventDefault();
    setLicenseData({
      license_class_id: "",
      license_number: "",
      expire_date: "",
    });
    setModifyModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelSubscription("");
    setDelModal(false);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelSubscription(item.uuid);
    setDelModal(true);
  };

  const onDestroyRole = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delSubscription };
    destoryDriverLicense(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelSubscription("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleVehicleForm = (e) => {
    e.preventDefault();

    let data = {
      driver_id: DriverIdss,
      license_class_id: licensedata?.license_class_id,
      license_number: licensedata?.license_number,
      expire_date: licensedata?.expire_date,
    };

    let updateData = {
      license_class_id: licensedata?.license_class_id,
      license_number: licensedata?.license_number,
      expire_date: licensedata?.expire_date,
      uuid: licensedata.uuid,
    };

    if (licensedata?.uuid) {
      modifySubscription(createAddDriverLicense, updateData);
    } else {
      modifySubscription(createAddDriverLicense, data);
    }
  };

  const modifySubscription = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setLicenseData({
          vehicle_class: "",
          max_speed: "",
          driver_cost: "",
          vehicle_cost: "",
        });
        setModifyModal(false);
        setIsModifyButtonLoad(false);
        setActiveTab("emergency");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const onModifyModalClose = (e) => {
    setLicenseData({
      license_class_id: "",
      license_number: "",
      expire_date: "",
    });
    setModifyModal(false);
  };

  const onEditSubscription = (e, item) => {
    e.preventDefault();
    setLicenseData({
      uuid: item.uuid,

      license_class_id: item?.license_class_id,
      license_number: item?.license_number,
      expire_date: item?.expire_date,
    });

    setModifyModal(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="inner-header py-3">
            <div className="left-block">{/* <h5>Working Period</h5> */}</div>

            <div className="right-block w-auto">
              {allPermissionsList.findIndex((e) => e.name == "modify_vehicle") >
                -1 && (
                <Button
                  type={`button`}
                  onClick={onAddSubscription}
                  className={`add-btn btn  float-right`}
                >
                  {`Add`}
                  <i className="plus-circle">
                    <img src={plusIcon} alt="" className="plus-icons" />
                  </i>
                </Button>
              )}
            </div>
          </div>

          <div className="container-event">
            <div className="page-header-row">
              <div className="row">
                <div className="col">
                  <div className="row d-flex justify-content-between company-section"></div>
                </div>
              </div>
            </div>

            <div className="page-table-row general-ac">
              <table
                className={`table table-striped ${isLoading ? "loading" : ""}`}
                width="100%"
              >
                <thead>
                  <tr>
                    <th scope={`col`}>{`License Class`}</th>
                    <th scope={`col`}>{`License Number`}</th>
                    <th scope={`col`}>{`Expiry Date`}</th>
                    <th scope={`col`}>{`Action`}</th>
                  </tr>
                </thead>
                <tbody>
                  {vehicleList &&
                    vehicleList?.data?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td style={{ cursor: "pointer" }}>
                            {item.class_name || "-"}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.license_number}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.expire_date}
                          </td>

                          <td style={{ cursor: "pointer" }}>
                            <button
                              type={`button`}
                              onClick={(e) => onEditSubscription(e, item)}
                              className={`btn btn-info add-btn-circle me-2`}
                            >
                              <i className="bi bi-pencil-fill"></i>
                            </button>

                            <button
                              type={`button`}
                              onClick={(e) => onDeleteModalOpen(e, item)}
                              className="btn btn-danger add-btn-circle btn-circle me-2"
                            >
                              <i className="bi bi-trash-fill"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}

                  {vehicleList && vehicleList?.data?.length === 0 && (
                    <tr>
                      <td
                        colSpan={
                          allPermissionsList.findIndex(
                            (e) => e.name == "modify_vehicle"
                          ) > -1 ||
                          allPermissionsList.findIndex(
                            (e) => e.name == "delete_vehicle"
                          ) > -1
                            ? 7
                            : 7
                        }
                        className={`text-center`}
                      >{`Record Not Found`}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className={`float-right`}>
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Create License</ModalHeader>
        <Form method={`post`} onSubmit={handleVehicleForm}>
          <ModalBody>
            <FormGroup>
              <Label className="col-form-label">{"License Class"}</Label>

              <div className="input-div">
                <Select
                  options={licenseClassList}
                  value={licenseClassList?.find(
                    (option) => option.value === licensedata
                  )}
                  onChange={(selectedOption) =>
                    setLicenseData((prevData) => ({
                      ...prevData,
                      license_class_id: selectedOption.value,
                    }))
                  }
                  placeholder={"Select License Class"}
                />
              </div>
            </FormGroup>

            <FormGroup>
              <Label className="col-form-label">{"License Number"}</Label>

              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={licensedata.license_number}
                  onChange={(e) => {
                    setLicenseData({
                      ...licensedata,
                      license_number: e.target.value,
                    });
                  }}
                  name={`license_number`}
                  maxLength={10}
                  placeholder="License Number"
                />
              </div>
            </FormGroup>

            <FormGroup>
              <Label className="col-form-label">{"Expiry Date"}</Label>

              <div className="input-div">
                <input
                  type="date"
                  id=""
                  className="form-control"
                  value={licensedata?.expire_date}
                  onChange={(e) => {
                    setLicenseData({
                      ...licensedata,
                      expire_date: e.target.value,
                    });
                  }}
                  placeholder="Expiry Date"
                />
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete License`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this License?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroyRole}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default LicenseDriver;
