import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Label } from "reactstrap";
import DatePicker from "react-multi-date-picker";
import { getVehicleList } from "../../services/VehicleService";
import Select from "react-select";
import ConfigDB from "../../config";
import {
  createCost,
  getEntitiesAllList,
  getFixedAllCostList,
} from "../../services/ManagementService";
import { toast } from "react-toastify";
import Utils from "../../utils";

const AddCost = () => {
  const navigate = useNavigate();
  const tableLengthList = ConfigDB.data.dataTableLength;

  const [activeTab, setActiveTab] = useState("general");
  const [costData, setCostData] = useState({
    vehicle_id: "",
    entity_id: "",
    cost_type_id: "",
    total_cost: "",
    odometer: "",
    location: "",
    document: "",
    country: "",
    cost_description: "",
    notes: "",
    date: "",
  });
  const [dates, setDates] = useState("");
  const [vehicleList, setVehicleList] = useState([]);
  const [entitiesAllList, setEntitiesAllList] = useState([]);
  const [fixedCosAlltList, setfixedAllCostList] = useState([]);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  useEffect(
    function () {
      let data = params;
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );
  useEffect(
    function () {
      let data = params;
      getEntitiesAllList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].entities_name,
            });
          }

          setEntitiesAllList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      getFixedAllCostList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setfixedAllCostList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleChangeDate = (date) => {
    setDates(date);
  };

  const date = new Date(dates);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const handleCostForm = (e) => {
    e.preventDefault();

    let data = {
      vehicle_id: costData.vehicle_id,
      entity_id: costData.entity_id,
      cost_type_id: costData.cost_type_id,
      total_cost: costData.total_cost,
      odometer: costData.odometer,
      location: costData.location,
      document: costData.document,
      country: costData.country,
      cost_description: costData.cost_description,
      notes: costData.notes,
      date: formattedDate,
    };
    createCost(data)
      .then((response) => {
        toast.success(response.data.message);
        navigate("/company");
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      });
  };

  return (
    <div>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>New Cost</h5>
        </div>
      </div>
      <div className="leaflet-control-management">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "general" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("general");
                }}
              >
                <span data-href="#tab-1">General</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "additionalInformation" ? "active" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("additionalInformation");
                }}
              >
                <span data-href="#tab-2">Additional Information</span>
              </li>
            </ul>
          </nav>

          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "general" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <div className="container-event-profle">
                <ul className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                  <button
                    onClick={() => {
                      navigate("/management");
                    }}
                    style={{ border: "none" }}
                  >
                    <i
                      className="bi bi-arrow-left bi-2x"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </button>
                </ul>
                <div className="profle-block tab-content" id="nav-tabContent">
                  <div>
                    <Form method={`post`} onSubmit={handleCostForm}>
                      <div className="row">
                        <div className="col-lg-12 mb-4 mb-sm-5">
                          <div className="row">
                            <div className="col-xl-4">
                              <Label className="col-form-label">
                                {"Date"}
                                <span className={`text-danger ml-1`}>*</span>
                              </Label>
                              <DatePicker
                                selected={dates}
                                onChange={handleChangeDate}
                                dateFormat="yyyy-MM-dd"
                                calendarPosition="bottom-center"
                                placeholder="Select Date"
                                style={{
                                  border: "1px solid #CCCCCC",
                                  borderRadius: "5px",
                                  height: "40px",
                                  margin: "1px 0",
                                  padding: "2px 5px",
                                  width: "557px",
                                  paddingRight: "30px",
                                }}
                              />
                            </div>

                            <div className="col-xl-4">
                              <FormGroup>
                                <Label className="col-form-label">
                                  {"Vehicle"}
                                  <span className={`text-danger ml-1`}>*</span>
                                </Label>
                                <Select
                                  options={vehicleList}
                                  value={vehicleList?.find(
                                    (option) => option.value === costData
                                  )}
                                  onChange={(selectedOption) =>
                                    setCostData((prevData) => ({
                                      ...prevData,
                                      vehicle_id: selectedOption.value,
                                    }))
                                  }
                                  placeholder={"Select Vehicle"}
                                />
                              </FormGroup>
                            </div>

                            <div className="col-xl-4">
                              <FormGroup>
                                <Label className="col-form-label">
                                  {"Entities"}
                                  <span className={`text-danger ml-1`}>*</span>
                                </Label>
                                <Select
                                  options={entitiesAllList}
                                  value={entitiesAllList?.find(
                                    (option) => option.value === costData
                                  )}
                                  onChange={(selectedOption) =>
                                    setCostData((prevData) => ({
                                      ...prevData,
                                      entity_id: selectedOption.value,
                                    }))
                                  }
                                  placeholder={"Select Entities"}
                                />
                              </FormGroup>
                            </div>

                            <div className="col-xl-4">
                              <FormGroup>
                                <Label className="col-form-label">
                                  {"Cost Type"}
                                  <span className={`text-danger ml-1`}>*</span>
                                </Label>
                                <Select
                                  options={fixedCosAlltList}
                                  value={fixedCosAlltList?.find(
                                    (option) => option.value === costData
                                  )}
                                  onChange={(selectedOption) =>
                                    setCostData((prevData) => ({
                                      ...prevData,
                                      cost_type_id: selectedOption.value,
                                    }))
                                  }
                                  placeholder={"Select Cost Type"}
                                />
                              </FormGroup>
                            </div>
                            <div className="col-xl-4">
                              <FormGroup>
                                <Label className="col-form-label">
                                  {"Total Cost"}
                                  <span className={`text-danger ml-1`}>*</span>
                                </Label>
                                <div className="input-div">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={costData.total_cost}
                                    onChange={(e) => {
                                      setCostData({
                                        ...costData,
                                        total_cost: e.target.value,
                                      });
                                    }}
                                    name={`total_cost`}
                                    maxLength={30}
                                    placeholder="Total Cost"
                                  />
                                </div>
                              </FormGroup>
                            </div>
                            <div className="col-xl-4">
                              <FormGroup>
                                <Label className="col-form-label">
                                  {"Odometer"}
                                  <span className={`text-danger ml-1`}>*</span>
                                </Label>
                                <div className="input-div">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={costData.odometer}
                                    onChange={(e) => {
                                      setCostData({
                                        ...costData,
                                        odometer: e.target.value,
                                      });
                                    }}
                                    name={`odometer`}
                                    maxLength={30}
                                    placeholder="Odometer"
                                  />
                                </div>
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "11px",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square`}
                >{`Submit`}</Button>
              </div>
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "additionalInformation" ? "show active" : ""
              }`}
              id="tab-2"
            >
              <div className="container-event-profle">
                <ul className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                  <button
                    onClick={() => {
                      navigate("/management");
                    }}
                    style={{ border: "none" }}
                  >
                    <i
                      className="bi bi-arrow-left bi-2x"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </button>
                </ul>
                <div className="profle-block tab-content" id="nav-tabContent">
                  <div>
                    <form method={`post`} onSubmit={handleCostForm}>
                      <div className="row">
                        <div className="col-lg-12 mb-4 mb-sm-5">
                          <div className="row">
                            <div className="col-xl-4">
                              <FormGroup>
                                <Label className="col-form-label">
                                  {"Document"}
                                  <span className={`text-danger ml-1`}>*</span>
                                </Label>
                                <div className="input-div">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={costData.document}
                                    onChange={(e) => {
                                      setCostData({
                                        ...costData,
                                        document: e.target.value,
                                      });
                                    }}
                                    name={`document`}
                                    maxLength={30}
                                    placeholder="Document"
                                  />
                                </div>
                              </FormGroup>
                            </div>
                            <div className="col-xl-4">
                              <FormGroup>
                                <Label className="col-form-label">
                                  {"Location"}
                                  <span className={`text-danger ml-1`}>*</span>
                                </Label>
                                <div className="input-div">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={costData.location}
                                    onChange={(e) => {
                                      setCostData({
                                        ...costData,
                                        location: e.target.value,
                                      });
                                    }}
                                    name={`location`}
                                    maxLength={30}
                                    placeholder="Location"
                                  />
                                </div>
                              </FormGroup>
                            </div>

                            <div className="col-xl-4">
                              <FormGroup>
                                <Label className="col-form-label">
                                  {"Country"}
                                  <span className={`text-danger ml-1`}>*</span>
                                </Label>
                                <div className="input-div">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={costData.country}
                                    onChange={(e) => {
                                      setCostData({
                                        ...costData,
                                        country: e.target.value,
                                      });
                                    }}
                                    name={`country`}
                                    maxLength={30}
                                    placeholder="Country"
                                  />
                                </div>
                              </FormGroup>
                            </div>

                            <div className="col-xl-4">
                              <FormGroup>
                                <Label className="col-form-label">
                                  {"Cost description"}
                                  <span className={`text-danger ml-1`}>*</span>
                                </Label>
                                <div className="input-div">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={costData.cost_description}
                                    onChange={(e) => {
                                      setCostData({
                                        ...costData,
                                        cost_description: e.target.value,
                                      });
                                    }}
                                    name={`cost_description`}
                                    maxLength={30}
                                    placeholder="Cost description"
                                  />
                                </div>
                              </FormGroup>
                            </div>

                            <div className="col-xl-4">
                              <FormGroup>
                                <Label className="col-form-label">
                                  {"Notes"}
                                  <span className={`text-danger ml-1`}>*</span>
                                </Label>
                                <div className="input-div">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={costData.notes}
                                    onChange={(e) => {
                                      setCostData({
                                        ...costData,
                                        notes: e.target.value,
                                      });
                                    }}
                                    name={`notes`}
                                    maxLength={30}
                                    placeholder="Notes"
                                  />
                                </div>
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "11px",
            }}
          >
            <Button
              color="success btn-info"
              type={`submit`}
              className={`btn-square`}
            >{`Submit`}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCost;
