import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ConfigDB from "../../config";
import Select from "react-select";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { createAddUserGeneral } from "../../services/EmployeeService";
import validator from "validator";
import {
  actionLanguageList,
  actionTimeAllZoneList,
} from "../../services/Common";
import {
  getDepartmentAllList,
  getSegmentAllList,
} from "../../services/ManagementService";
import { actionChangePassword } from "../../services/ProfileService";

const GeneralFilters = ({ setActiveTab, setGeneralData, addFiltersData }) => {
  const [employeeData, setEmployeeData] = useState({
    name: "",
  });

  useEffect(() => {
    if (addFiltersData?.uuid) {
      setEmployeeData(addFiltersData);
    }
  }, []);

  const handleEmployeeForm = (e) => {
    e.preventDefault();
    setGeneralData(employeeData);

    // Change to 'vehicle' tab on form submit
    setActiveTab("vehicle");
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleEmployeeForm}>
              <h4>General</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">{"Filter name"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={
                          addFiltersData?.uuid
                            ? employeeData?.name
                            : employeeData?.name
                        }
                        onChange={(e) => {
                          setEmployeeData({
                            ...employeeData,
                            name: e.target.value,
                          });
                        }}
                        name={`name`}
                        placeholder="Filter name"
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Next`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralFilters;
