import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

import {getSubscriptionList} from "../../services/SubscriptionService";
import Utils from "../../utils";
import {ConfigDB} from "../../config";
import {subscriptionPlanActions} from "../../store";
import {useDispatch} from "react-redux";

function SubscriptionPopup() {
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(function () {
        let data = {
            limit: 3,
            page: 1,
            search: "",
        };
        setIsLoading(true);
        getSubscriptionList(data)
            .then(response => {
                if (response?.data?.data?.length > 0) {
                    setSubscriptionList(response.data.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                toast.error(Utils.getErrorMessage(err));
            });
    }, []);

    const onGetStart = async (item) => {
        await dispatch(subscriptionPlanActions.subscriptionPlan(item.id));
        navigate("/payment");
    };
    return (
        <>
            <div className="subscriptions-blox">
                <div className="promos">
                    {subscriptionList.length > 0 && subscriptionList?.map((item, index) =>
                        <div className={`promo ${index == Math.floor(subscriptionList.length / 2) ? 'scale' : ''}`}>
                            <div className="text-center"><span className="price">${item.price}
                            </span>/{ConfigDB?.data?.SUBSCRIPTION_TYPE_LIST?.find(e => e.value == item.type)?.label}
                            </div>
                            <p>{item.subscription}</p>
                            <span>{item.subscription_desc}</span>

                            <button className="buy mt-4" onClick={() => onGetStart(item)}>Get Started</button>
                        </div>
                    )}

                </div>
            </div>
        </>
    );
}

export default SubscriptionPopup;