import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

const Counter = () => {
  return (
    <div>
      <section className="container-section">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-3">
              <div className="counter-box colored">
                <CountUp duration={1} className="counter" end={2147} />
                <i className="bi bi-plus-lg" />
                <div className="description">Vehicles</div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="counter-box colored">
                <CountUp duration={1} className="counter" end={850} />

                <i className="bi bi-plus-lg" />
                <div className="description">GPS Companies</div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="counter-box colored">
                <CountUp duration={1} className="counter" end={84} />

                <i className="bi bi-plus-lg" />
                <div className="description">Resellers</div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="counter-box colored">
                <CountUp duration={1} className="counter" end={75} />
                <i className="bi bi-plus-lg" />
                <div className="description">Countries</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Counter;
