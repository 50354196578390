import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Button } from "reactstrap";
import ConfigDB from "../../config";
import { getVehicleList } from "../../services/VehicleService";
import Select from "react-select";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  getDepartmentAllList,
  getSegmentAllList,
} from "../../services/ManagementService";
import { actionCountryList } from "../../services/Common";
import validator from "validator";
import { createAddDriverPersonal } from "../../services/EmployeeService";

const PersonalInfoDriver = ({ setActiveTab, driverDetails }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;

  const [professonalData, setProfessonalData] = useState({
    id_passport_number: "",
    full_name: "",
    dob: "",
    address: "",
    postcode: "",
    city: "",
    country: "",
    marital_status: "",
  });
  // const [departmentList, setDepartmentList] = useState([]);
  // const [segementList, setSegementList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  let errorsObj = { id_passport_number: "", postcode: " " };
  const [errors, setErrors] = useState(errorsObj);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  useEffect(() => {
    if (driverDetails) {
      setProfessonalData({
        id_passport_number: driverDetails?.id_passport_number,
        full_name: driverDetails?.full_name,
        dob: driverDetails?.dob,
        address: driverDetails?.address,
        postcode: driverDetails?.postcode,
        city: driverDetails?.city,
        marital_status: driverDetails?.marital_status,
        country: driverDetails?.country,
      });
    }
  }, [driverDetails]);

  useEffect(function () {
    actionCountryList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCountryList(list);
      })
      .catch((err) => {});
  }, []);
  let DriverId = localStorage.getItem("driverId");

  const handleDrivingForm = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };

    if (
      !validator.isNumeric(professonalData.id_passport_number, {
        no_symbols: true,
      })
    ) {
      errorObj.id_passport_number = "Only numbers allowed";
      error = true;
    } else {
      errorObj.id_passport_number = "";
    }

    if (
      !validator.isNumeric(professonalData.postcode, {
        no_symbols: true,
      })
    ) {
      errorObj.postcode = "Only numbers allowed";
      error = true;
    } else {
      errorObj.postcode = "";
    }

    setErrors(errorObj);

    if (error) return;

    let data = {
      uuid: driverDetails?.uuid ? driverDetails?.uuid : DriverId,
      id_passport_number: professonalData?.id_passport_number,
      full_name: professonalData?.full_name,
      dob: professonalData?.dob,
      address: professonalData?.address,
      postcode: professonalData?.postcode,
      city: professonalData?.city,
      country: professonalData?.country,
      marital_status: professonalData?.marital_status,
    };

    // if (AccountDetails?.route?.uuid) {
    //   modifymanagament(createAccountRoute, updateData);
    // } else {
    modifyDriver(createAddDriverPersonal, data);
    // }
  };

  const modifyDriver = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("tachnograph");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method={`post`} onSubmit={handleDrivingForm}>
              <h4>Personal Info</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Passport Number"}
                    </Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={professonalData.id_passport_number}
                        onChange={(e) => {
                          setProfessonalData({
                            ...professonalData,
                            id_passport_number: e.target.value,
                          });
                        }}
                        name={`id_passport_number`}
                        placeholder="Passport Number"
                      />
                    </div>
                    {errors.id_passport_number && (
                      <span className="input-error">
                        {errors.id_passport_number}
                      </span>
                    )}
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Full Name"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={professonalData.full_name}
                        onChange={(e) => {
                          setProfessonalData({
                            ...professonalData,
                            full_name: e.target.value,
                          });
                        }}
                        name={`full_name`}
                        placeholder="Full Name"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Employee Date Of Birth"}
                    </Label>
                    <div className="input-div">
                      <input
                        type="date"
                        id=""
                        className="form-control"
                        value={professonalData?.dob}
                        onChange={(e) => {
                          setProfessonalData({
                            ...professonalData,
                            dob: e.target.value,
                          });
                        }}
                        placeholder="Employee Date Of Birth"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Address"}</Label>
                    <div className="input-div">
                      <textarea
                        className="form-control"
                        type="text"
                        value={professonalData.address}
                        onChange={(e) => {
                          setProfessonalData({
                            ...professonalData,
                            address: e.target.value,
                          });
                        }}
                        name={`professonal_situation`}
                        placeholder="Address"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Post Code"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={professonalData.postcode}
                        onChange={(e) => {
                          setProfessonalData({
                            ...professonalData,
                            postcode: e.target.value,
                          });
                        }}
                        name={`postcode`}
                        placeholder="Post Code"
                        maxLength={6}
                      />
                    </div>
                    {errors.postcode && (
                      <span className="input-error">{errors.postcode}</span>
                    )}
                  </div>
                  <div className="col-md-4">
                    <Label className="col-form-label">{"City"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={professonalData.city}
                        onChange={(e) => {
                          setProfessonalData({
                            ...professonalData,
                            city: e.target.value,
                          });
                        }}
                        name={`city`}
                        placeholder="City"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Country"}</Label>
                    <div className="input-div">
                      <Select
                        options={countryList}
                        value={countryList.find(
                          (option) => option.value === professonalData.country
                        )}
                        onChange={(selectedOption) =>
                          setProfessonalData((prevData) => ({
                            ...prevData,
                            country: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Country"}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <Label className="col-form-label">{"Marital Status"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={professonalData.marital_status}
                        onChange={(e) => {
                          setProfessonalData({
                            ...professonalData,
                            marital_status: e.target.value,
                          });
                        }}
                        name={`marital_status`}
                        placeholder="Marital Status"
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoDriver;
