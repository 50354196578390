import React, { useEffect, useState } from "react";

import { Button, Form, FormGroup, Label } from "reactstrap";

import configDb from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

import { actionUpdateTachographVehicle } from "../../services/VehicleService";

import Select from "react-select";

import { useNavigate } from "react-router-dom";
import TimePicker from "react-time-picker";
import { createAccountRoute } from "../../services/ManagementService";

const tableLengthList = configDb.data.dataTableLength;

const DriverType = [
  {
    value: "1",
    label: "None",
  },
  { value: "2", label: "Enforce path" },
  { value: "3", label: "Send destination only" },
];

const NavigatorType = [
  {
    value: "1",
    label: "No",
  },
  { value: "2", label: "Always" },
  {
    value: "3",
    label: "Only if there are no other routes already assigned to the vehicle ",
  },
];

const WorkingType = [
  {
    value: "1",
    label: "As defined in route",
  },
  { value: "2", label: "Always" },
];

const geographicType = [
  {
    value: "1",
    label: "Near the start and end places ",
  },
  { value: "2", label: "Inside the start and end places" },
];

const driverType = [
  {
    value: "1",
    label: "Driver at the start of the route",
  },
  { value: "2", label: "Driver who finished the route" },
];

const numberHalfType = [
  { value: "1", label: "01" },
  { value: "2", label: "02" },
  { value: "3", label: "03" },
  { value: "4", label: "04" },
  { value: "5", label: "05" },
  { value: "6", label: "06" },
  { value: "7", label: "07" },
  { value: "8", label: "08" },
  { value: "9", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
];

const numberHourType = [
  { value: "1", label: "1" },
  { value: "2", label: "1" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "31", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
  { value: "49", label: "49" },
  { value: "50", label: "50" },
  { value: "51", label: "51" },
  { value: "52", label: "52" },
  { value: "53", label: "53" },
  { value: "54", label: "54" },
  { value: "55", label: "55" },
  { value: "56", label: "56" },
  { value: "57", label: "57" },
  { value: "58", label: "58" },
  { value: "59", label: "59" },
];

const RouteAccount = ({ setActiveTab, AccountDetails }) => {
  const navigate = useNavigate();

  const [routeData, setRouteData] = useState({
    send_route_wfm_nav: "",
    departure_earlier: 0,
    departure_earlier_time_minus: "",
    departure_later: 0,
    departure_later_time_minus: "",
    departure_later_alert_immediately: 0,
    arrive_earlier: 0,
    arrive_earlier_time_minus: "",
    arrive_later: 0,
    arrive_later_time_minus: "",
    arrive_later_alert_immediately: 0,
    set_destination_driver_navigator: "",
    show_seconds_in_time_selectors: "",
    consider_working_period_all_routes: "",
    use_corridors_in_all_steps: 0,
    use_corridors_in_all_steps_km: "",
    percentage_estimated_trip_time: "",
    start_end_trips_must_be: "",
    maximum_distance_place_center: "",
    driver_association_route: "",
    minor_delay_time: "",
    major_delay_time: "",
    minor_time_gap: "",
    major_time_gap: "",
    before_start_time: "",
    after_start_time: "",
    fixed_time_interval: "",
    minor_delay_time_half: "",
    major_delay_time_half: "",
    minor_time_gap_half: "",
    major_time_gap_half: "",
    departure_earlier_time_minus_half: "",
    departure_later_time_minus_half: "",
    arrive_earlier_time_minus_half: "",
    arrive_later_time_minus_half: "",
    before_start_time_half: "",
    after_start_time_half: "",
    fixed_time_interval_half: "",
  });

  const handleCheckTimeSelector = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setRouteData({
      ...routeData,
      show_seconds_in_time_selectors: newValue,
    });
  };

  const handleCheckAllStep = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setRouteData({
      ...routeData,
      use_corridors_in_all_steps: newValue,
    });
  };

  const handleCheckDepartureEarlier = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setRouteData({
      ...routeData,
      departure_earlier: newValue,
    });
  };

  const handleCheckDepartureArrive = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setRouteData({
      ...routeData,
      arrive_later_alert_immediately: newValue,
    });
  };

  const handleCheckDepartureAlertImmediately = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setRouteData({
      ...routeData,
      departure_later_alert_immediately: newValue,
    });
  };

  const handleCheckDepartureLater = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setRouteData({
      ...routeData,
      departure_later: newValue,
    });
  };

  const handleCheckArriveEarlier = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setRouteData({
      ...routeData,
      arrive_earlier: newValue,
    });
  };

  const handleCheckArriveLater = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setRouteData({
      ...routeData,
      arrive_later: newValue,
    });
  };

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
  });

  useEffect(() => {
    if (AccountDetails) {
      setRouteData({
        send_route_wfm_nav: AccountDetails?.route?.send_route_wfm_nav,
        departure_later: AccountDetails?.route?.departure_later,
        arrive_later_alert_immediately:
          AccountDetails?.route?.arrive_later_alert_immediately,
        set_destination_driver_navigator:
          AccountDetails?.route?.set_destination_driver_navigator,
        show_seconds_in_time_selectors:
          AccountDetails?.route?.show_seconds_in_time_selectors,
        consider_working_period_all_routes:
          AccountDetails?.route?.consider_working_period_all_routes,
        use_corridors_in_all_steps:
          AccountDetails?.route?.use_corridors_in_all_steps,
        percentage_estimated_trip_time:
          AccountDetails?.route?.percentage_estimated_trip_time,
        start_end_trips_must_be: AccountDetails?.route?.start_end_trips_must_be,
        maximum_distance_place_center:
          AccountDetails?.route?.maximum_distance_place_center,
        driver_association_route:
          AccountDetails?.route?.driver_association_route,
        minor_delay_time: AccountDetails?.route?.minor_delay_time_id,
        major_delay_time: AccountDetails?.route?.major_delay_time_id,
        minor_time_gap: AccountDetails?.route?.minor_time_gap_id,
        major_time_gap: AccountDetails?.route?.major_time_gap_id,
        departure_earlier_time_minus:
          AccountDetails?.route?.departure_earlier_time_minus_id,
        departure_later_time_minus:
          AccountDetails?.route?.departure_later_time_minus_id,
        arrive_earlier_time_minus:
          AccountDetails?.route?.arrive_earlier_time_minus_id,
        arrive_later_time_minus:
          AccountDetails?.route?.arrive_later_time_minus_id,
        before_start_time: AccountDetails?.route?.before_start_time_id,
        after_start_time: AccountDetails?.route?.after_start_time_id,
        fixed_time_interval: AccountDetails?.route?.fixed_time_interval_id,
        minor_delay_time_half: AccountDetails?.route?.minor_delay_time_half,
        major_delay_time_half: AccountDetails?.route?.major_delay_time_half,
        minor_time_gap_half: AccountDetails?.route?.minor_time_gap_half,
        major_time_gap_half: AccountDetails?.route?.major_time_gap_half,
        departure_earlier_time_minus_half:
          AccountDetails?.route?.departure_earlier_time_minus_half,
        departure_later_time_minus_half:
          AccountDetails?.route?.departure_later_time_minus_half,
        arrive_earlier_time_minus_half:
          AccountDetails?.route?.arrive_earlier_time_minus_half,
        arrive_later_time_minus_half:
          AccountDetails?.route?.arrive_later_time_minus_half,
        before_start_time_half: AccountDetails?.route?.before_start_time_half,
        after_start_time_half: AccountDetails?.route?.after_start_time_half,
        fixed_time_interval_half:
          AccountDetails?.route?.fixed_time_interval_half,
      });
    }
  }, [AccountDetails]);

  const handleDrivingForm = (e) => {
    e.preventDefault();

    let data = {
      send_route_wfm_nav: routeData?.send_route_wfm_nav,
      departure_earlier: routeData?.departure_earlier,
      departure_later: routeData?.departure_later,
      departure_later_time_minus: routeData?.departure_later_time_minus,
      departure_later_alert_immediately:
        routeData?.departure_later_alert_immediately,
      arrive_earlier: routeData?.arrive_earlier,
      arrive_later: routeData?.arrive_later,
      arrive_later_alert_immediately: routeData?.arrive_later_alert_immediately,
      set_destination_driver_navigator:
        routeData?.set_destination_driver_navigator,
      show_seconds_in_time_selectors: routeData?.show_seconds_in_time_selectors,
      consider_working_period_all_routes:
        routeData?.consider_working_period_all_routes,
      use_corridors_in_all_steps: routeData?.use_corridors_in_all_steps,
      use_corridors_in_all_steps_km: routeData?.use_corridors_in_all_steps_km,
      percentage_estimated_trip_time: routeData?.percentage_estimated_trip_time,
      start_end_trips_must_be: routeData?.start_end_trips_must_be,
      maximum_distance_place_center: routeData?.maximum_distance_place_center,
      driver_association_route: routeData?.driver_association_route,
      minor_delay_time: routeData?.minor_delay_time,
      major_delay_time: routeData?.major_delay_time,
      minor_time_gap: routeData?.minor_time_gap,
      major_time_gap: routeData?.major_time_gap,
      departure_earlier_time_minus: routeData?.departure_earlier_time_minus,
      arrive_earlier_time_minus: routeData?.arrive_earlier_time_minus,
      arrive_later_time_minus: routeData?.arrive_later_time_minus,
      before_start_time: routeData?.before_start_time,
      after_start_time: routeData?.after_start_time,
      fixed_time_interval: routeData?.fixed_time_interval,
      minor_delay_time_half: routeData?.minor_delay_time_half,
      major_delay_time_half: routeData?.major_delay_time_half,
      minor_time_gap_half: routeData?.minor_time_gap_half,
      major_time_gap_half: routeData?.major_time_gap_half,
      departure_earlier_time_minus_half:
        routeData?.departure_earlier_time_minus_half,
      departure_later_time_minus_half:
        routeData?.departure_later_time_minus_half,
      arrive_earlier_time_minus_half: routeData?.arrive_earlier_time_minus_half,
      arrive_later_time_minus_half: routeData?.arrive_later_time_minus_half,
      before_start_time_half: routeData?.before_start_time_half,
      after_start_time_half: routeData?.after_start_time_half,
      fixed_time_interval_half: routeData?.fixed_time_interval_half,
    };

    let updateData = {
      uuid: AccountDetails?.route?.uuid,
      send_route_wfm_nav: routeData?.send_route_wfm_nav,
      departure_earlier: routeData?.departure_earlier,
      departure_later: routeData?.departure_later,
      departure_later_alert_immediately:
        routeData?.departure_later_alert_immediately,
      arrive_earlier: routeData?.arrive_earlier,
      arrive_later: routeData?.arrive_later,
      arrive_later_alert_immediately: routeData?.arrive_later_alert_immediately,
      set_destination_driver_navigator:
        routeData?.set_destination_driver_navigator,
      show_seconds_in_time_selectors: routeData?.show_seconds_in_time_selectors,
      consider_working_period_all_routes:
        routeData?.consider_working_period_all_routes,
      use_corridors_in_all_steps: routeData?.use_corridors_in_all_steps,
      use_corridors_in_all_steps_km: routeData?.use_corridors_in_all_steps_km,
      percentage_estimated_trip_time: routeData?.percentage_estimated_trip_time,
      start_end_trips_must_be: routeData?.start_end_trips_must_be,
      maximum_distance_place_center: routeData?.maximum_distance_place_center,
      driver_association_route: routeData?.driver_association_route,
      minor_delay_time: routeData?.minor_delay_time,
      major_delay_time: routeData?.major_delay_time,
      minor_time_gap: routeData?.minor_time_gap,
      major_time_gap: routeData?.major_time_gap,
      departure_earlier_time_minus: routeData?.departure_earlier_time_minus,
      departure_later_time_minus: routeData?.departure_later_time_minus,
      arrive_earlier_time_minus: routeData?.arrive_earlier_time_minus,
      arrive_later_time_minus: routeData?.arrive_later_time_minus,
      before_start_time: routeData?.before_start_time,
      after_start_time: routeData?.after_start_time,
      fixed_time_interval: routeData?.fixed_time_interval,
      minor_delay_time_half: routeData?.minor_delay_time_half,
      major_delay_time_half: routeData?.major_delay_time_half,
      minor_time_gap_half: routeData?.minor_time_gap_half,
      major_time_gap_half: routeData?.major_time_gap_half,
      departure_earlier_time_minus_half:
        routeData?.departure_earlier_time_minus_half,
      departure_later_time_minus_half:
        routeData?.departure_later_time_minus_half,
      arrive_earlier_time_minus_half: routeData?.arrive_earlier_time_minus_half,
      arrive_later_time_minus_half: routeData?.arrive_later_time_minus_half,
      before_start_time_half: routeData?.before_start_time_half,
      after_start_time_half: routeData?.after_start_time_half,
      fixed_time_interval_half: routeData?.fixed_time_interval_half,
    };
    if (AccountDetails?.route?.uuid) {
      modifymanagament(createAccountRoute, updateData);
    } else {
      modifymanagament(createAccountRoute, data);
    }
  };

  const modifymanagament = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("driver");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const selctedDriver = driverType?.find(
    (option) =>
      option.value === JSON.stringify(routeData.driver_association_route)
  );

  const selctedroute = DriverType?.find(
    (option) => option.value === JSON.stringify(routeData.send_route_wfm_nav)
  );

  const selcteddriverNavigator = NavigatorType?.find(
    (option) =>
      option.value ===
      JSON.stringify(routeData.set_destination_driver_navigator)
  );

  const selctedworkingperiod = WorkingType.find(
    (option) =>
      option.value ===
      JSON.stringify(routeData.consider_working_period_all_routes)
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method={`post`} onSubmit={handleDrivingForm}>
              <h5>Send Route to WFM/NAV</h5>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Send route to WFM/NAV mode"}
                      </Label>
                      <div className="mb-3">
                        <Select
                          options={DriverType}
                          value={selctedroute}
                          onChange={(selectedOption) =>
                            setRouteData((prevData) => ({
                              ...prevData,
                              send_route_wfm_nav: selectedOption.value,
                            }))
                          }
                          placeholder={"Select Send route to WFM/NAV mode"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h5>Colour Codes</h5>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-3">
                      <Label className="col-form-label d-flex">
                        {"Minor delay"}
                        <div
                          style={{
                            background: "rgb(245, 166, 35)",
                            width: "65px",
                            height: "15px",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                            marginLeft: "10px",
                            marginTop: "4px",
                          }}
                        ></div>
                      </Label>
                      <div className="mb-3 row">
                          <div className="col-md-4 selectionOption">
                              <Select
                                  options={numberHalfType}
                                  value={numberHalfType?.find(
                                      (option) =>
                                          option.value === routeData.minor_delay_time
                                  )}
                                  onChange={(selectedOption) =>
                                      setRouteData((prevData) => ({
                                          ...prevData,
                                          minor_delay_time: selectedOption.value,
                                      }))
                                  }
                                  placeholder={"00"}
                              />
                          </div>
                          <div className="col-md-4 selectionOption">
                              <Select
                                  options={numberHourType}
                                  value={numberHourType?.find(
                                      (option) =>
                                          option.value ===
                                          routeData.minor_delay_time_half
                                  )}
                                  onChange={(selectedOption) =>
                                      setRouteData((prevData) => ({
                                          ...prevData,
                                          minor_delay_time_half: selectedOption.value,
                                      }))
                                  }
                                  placeholder={"00"}
                              />
                              {/* <input
                          type="number"
                          id="time"
                          name="time"
                          value={time}
                          onChange={handleChange}
                          className="form-control"
                        /> */}
                          </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <Label className="col-form-label d-flex">
                        {"Major delay"}
                        <div
                          style={{
                            background: "rgb(224, 45, 54)",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                            width: "65px",
                            height: "15px",
                            marginLeft: "10px",
                            marginTop: "4px",
                          }}
                        ></div>
                      </Label>
                      <div className="mb-3 row" >
                          <div className="col-md-4 selectionOption">
                              <Select
                                  options={numberHalfType}
                                  value={numberHalfType?.find(
                                      (option) =>
                                          option.value ===
                                          routeData.major_delay_time
                                  )}
                                  onChange={(selectedOption) =>
                                      setRouteData((prevData) => ({
                                          ...prevData,
                                          major_delay_time: selectedOption.value,
                                      }))
                                  }
                                  placeholder={"00"}
                              />
                          </div>
                          <div className="col-md-4 selectionOption">
                              <Select
                                  options={numberHourType}
                                  value={numberHourType?.find(
                                      (option) =>
                                          option.value ===
                                          routeData.major_delay_time_half
                                  )}
                                  onChange={(selectedOption) =>
                                      setRouteData((prevData) => ({
                                          ...prevData,
                                          major_delay_time_half: selectedOption.value,
                                      }))
                                  }
                                  placeholder={"00"}
                              />
                          </div>
                        {/* <input
                        type="number"
                        id="time"
                        name="time"
                        value={time}
                        onChange={handleChange}
                        className="form-control"
                      /> */}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <Label className="col-form-label d-flex">
                        {"Minor time gap to vehicle in front"}
                        <div
                          style={{
                            background: "rgb(245, 166, 35)",
                            width: "65px",
                            height: "15px",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                            marginLeft: "10px",
                            marginTop: "4px",
                          }}
                        ></div>
                      </Label>
                      <div className="mb-3 row" style={{ display: "flex" }}>
                          <div className="col-md-4 selectionOption">
                              <Select
                                  options={numberHalfType}
                                  value={numberHalfType?.find(
                                      (option) =>
                                          option.value ===
                                          routeData.minor_time_gap
                                  )}
                                  onChange={(selectedOption) =>
                                      setRouteData((prevData) => ({
                                          ...prevData,
                                          minor_time_gap: selectedOption.value,
                                      }))
                                  }
                                  placeholder={"00"}
                              />
                          </div>
                          <div className="col-md-4 selectionOption">
                              <Select
                                  options={numberHourType}
                                  value={numberHourType?.find(
                                      (option) =>
                                          option.value ===
                                          routeData.minor_time_gap_half
                                  )}
                                  onChange={(selectedOption) =>
                                      setRouteData((prevData) => ({
                                          ...prevData,
                                          minor_time_gap_half: selectedOption.value,
                                      }))
                                  }
                                  placeholder={"00"}
                              />
                              {/* <input
                        type="number"
                        id="time"
                        name="time"
                        value={time}
                        onChange={handleChange}
                        className="form-control"
                      /> */}
                          </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <Label className="col-form-label d-flex">
                        {"Major time gap to vehicle in front"}
                        <div
                          style={{
                            background: "rgb(224, 45, 54)",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                            width: "65px",
                            height: "15px",
                            marginLeft: "10px",
                            marginTop: "4px",
                          }}
                        ></div>
                      </Label>
                      <div className="mb-3 row" style={{ display: "flex" }}>
                          <div className="col-md-4 selectionOption">
                              <Select
                                  options={numberHalfType}
                                  value={numberHalfType?.find(
                                      (option) =>
                                          option.value ===
                                          routeData.major_time_gap
                                  )}
                                  onChange={(selectedOption) =>
                                      setRouteData((prevData) => ({
                                          ...prevData,
                                          major_time_gap: selectedOption.value,
                                      }))
                                  }
                                  placeholder={"00"}
                              />
                          </div>
                          <div className="col-md-4 selectionOption">
                              <Select
                                  options={numberHourType}
                                  value={numberHourType?.find(
                                      (option) =>
                                          option.value ===
                                          routeData.major_time_gap_half
                                  )}
                                  onChange={(selectedOption) =>
                                      setRouteData((prevData) => ({
                                          ...prevData,
                                          major_time_gap_half: selectedOption.value,
                                      }))
                                  }
                                  placeholder={"00"}
                              />
                              {/* <input
                              type="number"
                              id="time"
                              name="time"
                              value={time}
                              onChange={handleChange}
                              className="form-control"
                            /> */}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h5>Aleart</h5>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div
                    className="row"
                    style={{
                      border: "solid #eee 1px",
                      borderRadius: "5px",
                      paddingBottom: "12px",
                    }}
                  >
                    <div className="col-md-6">
                      <Label className="col-form-label">
                        Alert if departure happens earlier than the planned time
                        minus
                      </Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="departure_earlier"
                            checked={routeData.departure_earlier === 1}
                            onChange={handleCheckDepartureEarlier}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="departure_earlier"
                          >
                            Departure earlier
                          </label>
                        </div>
                      </div>
                      {routeData.departure_earlier === 1 && (
                        <>
                          <div className="col-md-6">
                            <div className="mb-3" style={{ display: "flex" }}>
                              <Select
                                options={numberHalfType}
                                value={numberHalfType?.find(
                                  (option) =>
                                    option.value ===
                                    routeData.departure_earlier_time_minus
                                )}
                                onChange={(selectedOption) =>
                                  setRouteData((prevData) => ({
                                    ...prevData,
                                    departure_earlier_time_minus:
                                      selectedOption.value,
                                  }))
                                }
                                placeholder={"00"}
                              />
                              <Select
                                options={numberHourType}
                                value={numberHourType?.find(
                                  (option) =>
                                    option.value ===
                                    routeData.departure_earlier_time_minus_half
                                )}
                                onChange={(selectedOption) =>
                                  setRouteData((prevData) => ({
                                    ...prevData,
                                    departure_earlier_time_minus_half:
                                      selectedOption.value,
                                  }))
                                }
                                placeholder={"00"}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">
                        Alert if departure happens later than the planned time
                        plus
                      </Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="departure_later"
                            checked={routeData.departure_later === 1}
                            onChange={handleCheckDepartureLater}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="departure_later"
                          >
                            Departure later
                          </label>
                        </div>
                      </div>
                      {routeData.departure_later === 1 && (
                        <>
                          <div className="row">
                            <div className="col-md-6">
                              {/* <div className="mb-3">
                                <TimePicker
                                  onChange={handleChangeDepartureLater}
                                  value={timedeparturelater}
                                  format={`hh:mm a`}
                                  className={`react-picker form-control`}
                                />
                              </div> */}

                              <div className="input-div">
                                <div
                                  className="mb-3 row"

                                >
                                  <div className="col-md-4 selectionOption">
                                      <Select
                                          options={numberHalfType}
                                          value={numberHalfType?.find(
                                              (option) =>
                                                  option.value ===

                                                  routeData.departure_later_time_minus

                                          )}
                                          onChange={(selectedOption) =>
                                              setRouteData((prevData) => ({
                                                  ...prevData,
                                                  departure_later_time_minus:
                                                  selectedOption.value,
                                              }))
                                          }
                                          placeholder={"00"}
                                      />
                                  </div>
                                    <div className="col-md-4 selectionOption">
                                        <Select
                                            options={numberHourType}
                                            value={numberHourType?.find(
                                                (option) =>
                                                    option.value ===

                                                    routeData.departure_later_time_minus_half

                                            )}
                                            onChange={(selectedOption) =>
                                                setRouteData((prevData) => ({
                                                    ...prevData,
                                                    departure_later_time_minus_half:
                                                    selectedOption.value,
                                                }))
                                            }
                                            placeholder={"00"}
                                        />
                                    </div>


                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="input-div">
                                <div className="form-check mt-44">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="departure_later_alert_immediately"
                                    checked={
                                      routeData.departure_later_alert_immediately ===
                                      1
                                    }
                                    onChange={
                                      handleCheckDepartureAlertImmediately
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="departure_later_alert_immediately"
                                  >
                                    Alert immediately
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">
                        Alert if arrival happens earlier than the planned time
                        minus
                      </Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="arrive_earlier"
                            checked={routeData.arrive_earlier === 1}
                            onChange={handleCheckArriveEarlier}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="arrive_earlier"
                          >
                            Arrival earlier
                          </label>
                        </div>
                      </div>
                      {routeData.arrive_earlier === 1 && (
                        <div className="col-md-6">
                          <Label className="col-form-label d-flex">
                            Fixed time interval
                          </Label>
                          <div className="mb-3 row" style={{ display: "flex" }}>
                              <div className="col-md-4 selectionOption">
                                  <Select
                                      options={numberHalfType}
                                      value={numberHalfType?.find(
                                          (option) =>
                                              option.value ===

                                              routeData.arrive_earlier_time_minus

                                      )}
                                      onChange={(selectedOption) =>
                                          setRouteData((prevData) => ({
                                              ...prevData,
                                              arrive_earlier_time_minus:
                                              selectedOption.value,
                                          }))
                                      }
                                      placeholder={"00"}
                                  />
                              </div>
                              <div className="col-md-4 selectionOption">
                                  <Select
                                      options={numberHourType}
                                      value={numberHourType?.find(
                                          (option) =>
                                              option.value ===

                                              routeData.arrive_earlier_time_minus_half

                                      )}
                                      onChange={(selectedOption) =>
                                          setRouteData((prevData) => ({
                                              ...prevData,
                                              arrive_earlier_time_minus_half:
                                              selectedOption.value,
                                          }))
                                      }
                                      placeholder={"00"}
                                  />
                              </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">
                        Alert if arrival happens later than the planned time
                        plus
                      </Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="arrive_later"
                            checked={routeData.arrive_later === 1}
                            onChange={handleCheckArriveLater}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="arrive_later"
                          >
                            Arrival later
                          </label>
                        </div>
                      </div>
                      {routeData.arrive_later === 1 && (
                        <>
                          <div className="row">
                            <div className="col-md-6">
                              <Label className="col-form-label d-flex">
                                Fixed time interval
                              </Label>
                              {/* <div className="mb-3">
                                <TimePicker
                                  onChange={handleChangeArriveLater}
                                  value={timearrivelater}
                                  format={`hh:mm a`}
                                  className={`react-picker form-control`}
                                />
                              </div> */}

                              <div className="input-div">
                                <div
                                  className="mb-3 row"
                                  style={{ display: "flex" }}
                                >
                                    <div className="col-md-4 selectionOption">
                                        <Select
                                            options={numberHalfType}
                                            value={numberHalfType?.find(
                                                (option) =>
                                                    option.value ===

                                                    routeData.arrive_later_time_minus

                                            )}
                                            onChange={(selectedOption) =>
                                                setRouteData((prevData) => ({
                                                    ...prevData,
                                                    arrive_later_time_minus:
                                                    selectedOption.value,
                                                }))
                                            }
                                            placeholder={"00"}
                                        />
                                    </div>
                                    <div className="col-md-4 selectionOption">
                                        <Select
                                            options={numberHourType}
                                            value={numberHourType?.find(
                                                (option) =>
                                                    option.value ===

                                                    routeData.arrive_later_time_minus_half

                                            )}
                                            onChange={(selectedOption) =>
                                                setRouteData((prevData) => ({
                                                    ...prevData,
                                                    arrive_later_time_minus_half:
                                                    selectedOption.value,
                                                }))
                                            }
                                            placeholder={"00"}
                                        />
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mt-40">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="arrive_later_alert_immediately"
                                  checked={
                                    routeData.arrive_later_alert_immediately ===
                                    1
                                  }
                                  onChange={handleCheckDepartureArrive}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="arrive_later_alert_immediately"
                                >
                                  Alert immediately
                                </label>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Navigator input</h5>
                      <Label className="col-form-label">
                        {
                          "Set destination when driver presses go on the Navigator"
                        }
                      </Label>
                      <div className="mb-3">
                        <Select
                          options={NavigatorType}
                          value={selcteddriverNavigator}
                          onChange={(selectedOption) =>
                            setRouteData((prevData) => ({
                              ...prevData,
                              set_destination_driver_navigator:
                                selectedOption.value,
                            }))
                          }
                          placeholder={"Select navigator"}
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <h5>Settings</h5>
                      <div className="show-box">
                        <Label className="col-form-label">
                          Show seconds in time selectors
                        </Label>
                        <div className="input-div">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="show_seconds_in_time_selectors"
                              checked={
                                routeData.show_seconds_in_time_selectors === 1
                              }
                              onChange={handleCheckTimeSelector}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="show_seconds_in_time_selectors"
                            >
                              Show seconds in time selectors
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <h5>Working period</h5>
                      <Label className="col-form-label">
                        {"Consider the working period for all routes"}
                      </Label>
                      <div className="mb-3">
                        <Select
                          options={WorkingType}
                          value={selctedworkingperiod}
                          onChange={(selectedOption) =>
                            setRouteData((prevData) => ({
                              ...prevData,
                              consider_working_period_all_routes:
                                selectedOption.value,
                            }))
                          }
                          placeholder={"Select working period"}
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <h5>Corridor</h5>
                      <div className="show-box">
                        <Label className="col-form-label">
                          Use corridors in all steps of the route
                        </Label>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="input-div">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="use_corridors_in_all_steps"
                                  checked={
                                    routeData.use_corridors_in_all_steps === 1
                                  }
                                  onChange={handleCheckAllStep}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="use_corridors_in_all_steps"
                                >
                                  Use corridors in all steps of the route
                                </label>
                              </div>
                            </div>
                          </div>

                          {routeData.use_corridors_in_all_steps === 1 && (
                            <div className="col-md-4">
                              <div className="input-div">
                                <input
                                  className="form-control formCntrl"
                                  type="text"
                                  value={
                                    routeData.use_corridors_in_all_steps_km
                                  }
                                  onChange={(e) => {
                                    setRouteData({
                                      ...routeData,
                                      use_corridors_in_all_steps_km:
                                        e.target.value,
                                    });
                                  }}
                                  name={`use_corridors_in_all_steps_km`}
                                  maxLength={10}
                                  placeholder="Km"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>
              <div className="add-vehicle-rw mb-4">
                <div className="row">
                  <div className="col-md-6">
                    <h5>Start tolerance</h5>
                    <div className="row">
                      <div className="col-md-6">
                        <Label className="col-form-label d-flex">
                          {"Before start time"}
                        </Label>
                        <div className="mb-3 row" style={{ display: "flex" }}>
                            <div className="col-md-4 selectionOption">
                                <Select
                                    options={numberHalfType}
                                    value={numberHalfType?.find(
                                        (option) =>
                                            option.value ===
                                            routeData.before_start_time
                                    )}
                                    onChange={(selectedOption) =>
                                        setRouteData((prevData) => ({
                                            ...prevData,
                                            before_start_time: selectedOption.value,
                                        }))
                                    }
                                    placeholder={"00"}
                                />
                            </div>
                            <div className="col-md-4 selectionOption">
                                <Select
                                    options={numberHourType}
                                    value={numberHourType?.find(
                                        (option) =>
                                            option.value ===
                                            routeData.before_start_time_half
                                    )}
                                    onChange={(selectedOption) =>
                                        setRouteData((prevData) => ({
                                            ...prevData,
                                            before_start_time_half: selectedOption.value,
                                        }))
                                    }
                                    placeholder={"00"}
                                />
                            </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Label className="col-form-label d-flex">
                          {"After start time"}
                        </Label>
                        <div className="mb-3 row" style={{ display: "flex" }}>
                            <div className="col-md-4 selectionOption">
                                <Select
                                    options={numberHalfType}
                                    value={numberHalfType?.find(
                                        (option) =>
                                            option.value ===
                                            routeData.after_start_time
                                    )}
                                    onChange={(selectedOption) =>
                                        setRouteData((prevData) => ({
                                            ...prevData,
                                            after_start_time: selectedOption.value,
                                        }))
                                    }
                                    placeholder={"00"}
                                />
                            </div>
                            <div className="col-md-4 selectionOption">
                                <Select
                                    options={numberHourType}
                                    value={numberHourType?.find(
                                        (option) =>
                                            option.value ===
                                            routeData.after_start_time_half
                                    )}
                                    onChange={(selectedOption) =>
                                        setRouteData((prevData) => ({
                                            ...prevData,
                                            after_start_time_half: selectedOption.value,
                                        }))
                                    }
                                    placeholder={"00"}
                                />
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h5>End tolerance</h5>
                    <FormGroup>
                      <div className="row">
                        <div className="col-md-6">
                          <Label className="col-form-label d-flex">
                            {"Fixed time interval"}
                          </Label>
                          <div className="mb-3 row" style={{ display: "flex" }}>
                              <div className="col-md-4 selectionOption">
                                  <Select
                                      options={numberHalfType}
                                      value={numberHalfType?.find(
                                          (option) =>
                                              option.value ===
                                              routeData.fixed_time_interval
                                      )}
                                      onChange={(selectedOption) =>
                                          setRouteData((prevData) => ({
                                              ...prevData,
                                              fixed_time_interval: selectedOption.value,
                                          }))
                                      }
                                      placeholder={"00"}
                                  />
                              </div>
                              <div className="col-md-4 selectionOption">
                                  <Select
                                      options={numberHourType}
                                      value={numberHourType?.find(
                                          (option) =>
                                              option.value ===

                                              routeData.fixed_time_interval_half

                                      )}
                                      onChange={(selectedOption) =>
                                          setRouteData((prevData) => ({
                                              ...prevData,
                                              fixed_time_interval_half:
                                              selectedOption.value,
                                          }))
                                      }
                                      placeholder={"00"}
                                  />
                              </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <Label className="col-form-label">
                            {"Variable percentage estimated trip time"}
                          </Label>
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={routeData.percentage_estimated_trip_time}
                              onChange={(e) => {
                                setRouteData({
                                  ...routeData,
                                  percentage_estimated_trip_time:
                                    e.target.value,
                                });
                              }}
                              name={`percentage_estimated_trip_time`}
                              maxLength={10}
                              placeholder="Variable percentage estimated trip time"
                            />
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </div>

              <h5>Geographic Tolerance</h5>

              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Start and end of trips must be"}
                      </Label>
                      <div className="mb-3">
                        <Select
                          options={geographicType}
                          value={geographicType.find(
                            (option) =>
                              option.value === routeData.start_end_trips_must_be
                          )}
                          onChange={(selectedOption) =>
                            setRouteData((prevData) => ({
                              ...prevData,
                              start_end_trips_must_be: selectedOption.value,
                            }))
                          }
                          placeholder={"Select working period"}
                        />
                      </div>
                    </div>
                    {routeData.start_end_trips_must_be === "1" && (
                      <div className="col-md-4">
                        <Label className="col-form-label">
                          {"Maximum distance from place center"}
                        </Label>
                        <div className="input-div">
                          <input
                            className="form-control"
                            type="text"
                            value={routeData.maximum_distance_place_center}
                            onChange={(e) => {
                              setRouteData({
                                ...routeData,
                                maximum_distance_place_center: e.target.value,
                              });
                            }}
                            name={`maximum_distance_place_center`}
                            maxLength={10}
                            placeholder="00,00Km"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </FormGroup>

              <h5>Driver Assignment</h5>

              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Driver association to the route"}
                      </Label>
                      <div className="mb-3">
                        <Select
                          options={driverType}
                          value={selctedDriver}
                          onChange={(selectedOption) =>
                            setRouteData((prevData) => ({
                              ...prevData,
                              driver_association_route: selectedOption.value,
                            }))
                          }
                          placeholder={"Select route"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouteAccount;
