import React, { useState, useEffect } from "react";

import { Button, Form, FormGroup, Label } from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

import {
  actionUpdateManagementVehicle,
  actionUpdateTachographVehicle,
} from "../../services/VehicleService";

import Select from "react-select";

import { useNavigate } from "react-router-dom";
import { createAccountCalculateTripCost } from "../../services/ManagementService";

const tableLengthList = configDb.data.dataTableLength;

const DriverType = [
  {
    value: "1",
    label: "Cost per mileage",
  },
  { value: "2", label: "Cost per hour" },
  // { value: "3", label: "Always" },
];

const CalculationOptions = ({ setActiveTab }) => {
  const navigate = useNavigate();

  const [calculationData, setCalculationData] = useState({
    calculate_trip_costs: "",
  });

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
  });

  const handleDrivingForm = (e) => {
    e.preventDefault();

    let data = {
      calculate_trip_costs: calculationData?.calculate_trip_costs,
    };

    let updateData = {
      calculate_trip_costs: calculationData?.calculate_trip_costs,
    };

    modifymanagament(createAccountCalculateTripCost, data);
  };

  const modifymanagament = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("direction");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleDrivingForm}>
              <FormGroup>
                <div className="row">
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Calculate trip costs using"}
                    </Label>
                    <div className="mb-3">
                      <Select
                        options={DriverType}
                        value={DriverType.find(
                          (option) =>
                            option.value ===
                            calculationData.calculate_trip_costs
                        )}
                        onChange={(selectedOption) =>
                          setCalculationData((prevData) => ({
                            ...prevData,
                            calculate_trip_costs: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Calculate trip costs"}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculationOptions;
