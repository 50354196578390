import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import configDb from "../config";
import Utils from "../utils";

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({name, initialState, reducers});

export const authActions = {...slice.actions, ...extraActions};
export const authReducer = slice.reducer;

function createInitialState() {
    let data = Utils.loginDetail();
    return {
        value: data
    }
}

function createReducers() {
    return {
        setAuth
    };

    function setAuth(state, action) {
        state.value = action.payload;
    }
}

function createExtraActions() {
    return {
        login: login(),
        logout: logout()
    };

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            function (arg, {dispatch}) {
                dispatch(authActions.setAuth(arg));
            }
        );
    }

    function logout() {
        return createAsyncThunk(
            `${name}/logout`,
            function (arg, {dispatch}) {
                dispatch(authActions.setAuth(null));
                localStorage.removeItem(configDb.data.login_data);
            }
        );
    }
}