import React from "react";
import jsPDF from "jspdf";
import logo from "../../assets/images/Logo.png";

const InvoiceComponent = ({ item }) => {
  const downloadPDF = () => {
    const doc = new jsPDF();

    // Add the logo
    doc.addImage(logo, "PNG", 10, 10, 20, 20);

    doc.setFontSize(20);
    doc.text("Invoice Details", 50, 50);
    doc.setDrawColor(0);
    doc.setLineWidth(1);
    doc.line(10, 55, 200, 55);

    doc.setFontSize(12);

    const startY = 65;
    const lineHeight = 3;
    const columnWidth = 100;

    const details = [
      `Trip Name: ${item.trip_name || "-"}`,
      `Customer Name: ${item.customer_name || "-"}`,
      `Customer Contact: ${item.customer_contact || "-"}`,
      `Invoice Paid Amount: ${item.invoice_paid_amount || "-"}`,
    ];

    details.forEach((detail, index) => {
      const row = Math.floor(index / 2);
      const col = index % 2;
      const xPosition = 10 + col * columnWidth;
      const yPosition = startY + row * lineHeight * 2;

      doc.text(detail, xPosition, yPosition);
    });

    doc.setFontSize(10);
    doc.text(
      "Thank you for your business!",
      20,
      startY + (Math.ceil(details.length / 2) + 1) * lineHeight * 2
    );
    doc.text(
      "This invoice is generated automatically.",
      20,
      startY + (Math.ceil(details.length / 2) + 2) * lineHeight * 2
    );

    doc.save(`invoice_${item.id}.pdf`);
  };

  return (
    <button
      type="button"
      onClick={downloadPDF}
      className="btn btn-info add-btn-circle me-2"
    >
      <i className="bi bi-file-earmark-pdf-fill"></i>
    </button>
  );
};

export default InvoiceComponent;
