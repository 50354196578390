import axiosInstance from "./AxiosInstance";

export function actionAddUpdateTrip(data) {
  return axiosInstance.post(`/trip/create`, data);
}

export function getTripList(data) {
  return axiosInstance.post(`/trip/list`, data);
}

export function actionDelTrip(data) {
  return axiosInstance.post(`/trip/delete`, data);
}

export function getDriverList(data) {
  return axiosInstance.post(`/driver-list`, data);
}

export function getTripDetails(data) {
  return axiosInstance.post(`/trip/details`, data);
}

export function actionDelStop(data) {
  return axiosInstance.post(`/trip-stop/delete`, data);
}

export function actionDelSchedual(data) {
  return axiosInstance.post(`/trip-schedule-date/delete`, data);
}

export function actionAddSchedual(data) {
  return axiosInstance.post(`/schedule/create`, data);
}

export function actionUpdateTrip(data) {
  return axiosInstance.post(`/trip/update`, data);
}

export function actionOrderList(data) {
  return axiosInstance.post(`trip/order-list`, data);
}

export function actionOrderDetails(data) {
  return axiosInstance.post(`trip/order-details`, data);
}

export function actionOrderStatusChange(data) {
  return axiosInstance.post(`/trip/order-status`, data);
}

export function getDeviceDelay(data) {
  return axiosInstance.post(`/device-relay/list`, data);
}

export function addCustomerDetails(data) {
  return axiosInstance.post(`/trip/add-order`, data);
}

export function orderTracking(data) {
  return axiosInstance.post(`/tracking-order`, data);
}

export function addZonedevice(data) {
  return axiosInstance.post(`/device-geo-fence`, data);
}

export function getZonedeviceList(data) {
  return axiosInstance.post(`/device-geo-fence-list`, data);
}

export function deleteZonedevice(data) {
  return axiosInstance.post(`/device-geo-fence/delete`, data);
}

export function getInvoiceAllList(data) {
  return axiosInstance.post(`/invoice/invoice-list`, data);
}

export function getInvoiceDetails(data) {
  return axiosInstance.post(`/invoice/invoice-details`, data);
}
