import React from 'react';
import {useNavigate} from "react-router-dom";


const NotFoundPage = () => {
    const navigate = useNavigate();
    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="text-center">
                <h1 className="mb-4">404 - Not Found</h1>
                <p>The page you're looking for doesn't exist.</p>
                <button className={`btn btn-dark`} onClick={() => {
                    navigate('/');
                }}>Home
                </button>
            </div>
        </div>
    );
};

export default NotFoundPage;