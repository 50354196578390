import React, { useState } from "react";
import Vehicle from "../vehicle/index";
import Employee from "../employee/index";
import Alarm from "../alarm";
import Places from "../location/index";
import Organization from "./organization";
import Account from "./Account";
import VehicleClass from "../vehicle_class/index";
import ConfigDB from "../../config";
import Filter from "./Filter";
import Drivers from "../employee/Drivers";
import Segment from "./Segment";
import OrganizationMain from "./OrganizationMain";
import { Alert } from "reactstrap";
import Notification from "../notification";

const Administration = () => {
  const [activeTab, setActiveTab] = useState("account");
  const [activeSubTab, setActiveSubTab] = useState("department");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSubTabClick = (subTab) => {
    setActiveSubTab(subTab);
  };

  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);
  const isAdminRole = JsonParse?.user_role === ConfigDB.data.ROLE_ADMIN;

  return (
    <div>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Administration</h5>
        </div>
      </div>
      <div className="leaflet-control-management managementui-tab">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "account" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("account");
                }}
              >
                <span data-href="#tab-1">Account</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "vehicle" ? "active" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("vehicle");
                }}
              >
                <span data-href="#tab-2">Vehicles</span>
              </li>

              <li
                className={`nav-link ${activeTab === "driver" ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("driver");
                }}
              >
                <span data-href="#tab-2">Driver</span>
              </li>

              <li
                className={`nav-link ${activeTab === "alarms" ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("alarms");
                }}
              >
                <span data-href="#tab-4">Alarms</span>
              </li>
              <li
                className={`nav-link ${activeTab === "areas" ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("areas");
                }}
              >
                <span data-href="#tab-5">Areas</span>
              </li>
              <li
                className={`nav-link ${
                  activeTab === "organization" ? "active" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("organization");
                }}
              >
                <span data-href="#tab-6">Organization</span>
              </li>
              {isAdminRole ? (
                <li
                  className={`nav-link ${
                    activeTab === "vehicleClass" ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleTabClick("vehicleClass");
                  }}
                >
                  <span data-href="#tab-7">Vehicle Class</span>
                </li>
              ) : null}

              <li
                className={`nav-link ${activeTab === "filter" ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("filter");
                }}
              >
                <span data-href="#tab-6">Filter</span>
              </li>

              <li
                className={`nav-link ${activeTab === "users" ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("users");
                }}
              >
                <span data-href="#tab-3">Users</span>
              </li>
            </ul>
          </nav>
          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "account" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <Account />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "vehicle" ? "show active" : ""
              }`}
              id="tab-2"
            >
              <Vehicle />
              {/* <AddVehicle /> */}
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "driver" ? "show active" : ""
              }`}
              id="tab-2"
            >
              <Drivers />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "users" ? "show active" : ""
              }`}
              id="tab-3"
            >
              <Employee />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "alarms" ? "show active" : ""
              }`}
              id="tab-2"
            >
              <Notification />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "areas" ? "show active" : ""
              }`}
              id="tab-2"
            >
              <Places />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "organization" ? "show active" : ""
              }`}
              id="tab-2"
            >
              <OrganizationMain />
            </div>
            {isAdminRole ? (
              <div
                className={`tab-pane fade ${
                  activeTab === "vehicleClass" ? "show active" : ""
                }`}
                id="tab-7"
              >
                <VehicleClass />
              </div>
            ) : null}

            <div
              className={`tab-pane fade ${
                activeTab === "filter" ? "show active" : ""
              }`}
              id="tab-2"
            >
              <Filter setActiveTab={setActiveTab} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Administration;
