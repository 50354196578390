import React, { useState } from "react";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

import loginLogoImg from "../../assets/images/logo.svg";
import { forgotPassword } from "../../services/AuthService";
import Utils from "../../utils";
import * as validator from "validator";

function ForgotPassword() {
  let errorsObj = { email: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [email, setEmail] = useState("");
  // const [isLoading, setIsLoading] = useState(false);

  const onForgotPassword = (e) => {
    e.preventDefault();
    let errorObj = { ...errorsObj };
    let error = false;

    if (!email) {
      errorObj.email = "Email address is required";
      error = true;
    } else if (!validator.isEmail(email)) {
      errorObj.email = "Please enter valid email address";
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    let obj = {
      email: email,
    };
    forgotPassword(obj)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      });
  };

  return (
    <div className="login-content">
      <form method={`post`} onSubmit={onForgotPassword}>
        <div className="logo-user">
          <img src={loginLogoImg} alt="login-logo" />
        </div>
        <div className="input-div input-group">
          <input
            className="form-control"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <span className="input-group-text">
            <i className="bi bi-person"></i>
          </span>
          {errors.email && <span className="input-error">{errors.email}</span>}
        </div>
        <div className="button-row">
          <input type="submit" className="btn login" value="Reset Password" />
          <NavLink
            to={`/login`}
            role={`button`}
            className="btn login-outline"
          >{`Back to Login`}</NavLink>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
