import axiosInstance from "./AxiosInstance";

export function actionStoreSubscription(data) {
    return axiosInstance.post(`/subscription/create`, data);
}

export function actionUpdateSubscription(data) {
    return axiosInstance.post(`/subscription/update`, data);
}

export function getSubscriptionList(data) {
    return axiosInstance.post(`/subscription/list`, data);
}

export function actionDelSubscription(data) {
    return axiosInstance.post(`/subscription/delete`, data);
}
