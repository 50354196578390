import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus-i.svg";
import {
  actionClassVehicle,
  actionDelVehicle,
} from "../../services/VehicleService";
import {
  createAccount,
  createAccountDirection,
  getAccountDirectionList,
  getAccountList,
} from "../../services/ManagementService";

import Select from "react-select";
import validator from "validator";

const tableLengthList = configDb.data.dataTableLength;

const VehicleType = [
  { value: "1", label: "Motorcycle" },
  { value: "2", label: "Passenger" },
  { value: "3", label: "Pickup" },
  { value: "4", label: "Van" },
  { value: "5", label: "Ambulance" },
  { value: "6", label: "Fire truck" },
  { value: "7", label: "Minibus" },
  { value: "11", label: "Bus" },
  { value: "12", label: "Truck" },
  { value: "13", label: "Semi-trailer" },
  { value: "14", label: "Construction" },
  { value: "15", label: "Waste collection" },
  { value: "16", label: "Other" },
  { value: "17", label: "Tractor unit" },
  { value: "18", label: "Electric Car" },
];

const DireactionAccount = ({ setActiveTab, AccountDetails }) => {
  const navigate = useNavigate();
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [vehicleList, setVehicleList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [delSubscription, setDelSubscription] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [vehicleData, setVehicleData] = useState({
    uuid: "",
    vehicle_class_id: "",
    max_speed: "",
    driver_cost: "",
    vehicle_cost: "",
  });
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicleClasssList, setVehicleClasssList] = useState([]);

  let errorsObj = { max_speed: "", driver_cost: "", vehicle_cost: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [VehicleValueId, SetVehicleValueId] = useState("");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  useEffect(
    function () {
      let data = { ...params };
      actionClassVehicle(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicle_class_id,
            });
          }

          setVehicleClasssList(list);
        })
        .catch((err) => {});
    },

    []
  );

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getAccountDirectionList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setVehicleList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const onAddSubscription = (e) => {
    e.preventDefault();
    setVehicleData({
      vehicle_class_id: "",
      max_speed: "",
      driver_cost: "",
      vehicle_cost: "",
    });
    setModifyModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelSubscription("");
    setDelModal(false);
  };

  const onDestroyRole = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delSubscription };
    actionDelVehicle(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelSubscription("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleVehicleForm = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };

    if (
      !validator.isNumeric(vehicleData.max_speed, {
        no_symbols: true,
      })
    ) {
      errorObj.max_speed = "Only numbers allowed";
      error = true;
    } else {
      errorObj.max_speed = "";
    }

    if (
      !validator.isNumeric(vehicleData.driver_cost, {
        no_symbols: true,
      })
    ) {
      errorObj.driver_cost = "Only numbers allowed";
      error = true;
    } else {
      errorObj.driver_cost = "";
    }

    if (
      !validator.isNumeric(vehicleData.vehicle_cost, {
        no_symbols: true,
      })
    ) {
      errorObj.vehicle_cost = "Only numbers allowed";
      error = true;
    } else {
      errorObj.vehicle_cost = "";
    }

    setErrors(errorObj);

    if (error) return;

    let data = {
      vehicle_class_id: VehicleValueId,
      max_speed: vehicleData?.max_speed,
      driver_cost: vehicleData?.driver_cost,
      vehicle_cost: vehicleData?.vehicle_cost,
      uuid: AccountDetails?.uuid ? AccountDetails?.uuid : null,
    };

    let updateData = {
      vehicle_class_id: vehicleData?.vehicle_class_id,
      max_speed: vehicleData?.max_speed,
      driver_cost: vehicleData?.driver_cost,
      vehicle_cost: vehicleData?.vehicle_cost,
      uuid: vehicleData?.uuid,
    };

    if (vehicleData?.uuid) {
      modifySubscription(createAccountDirection, updateData);
    } else {
      modifySubscription(createAccountDirection, data);
    }
  };

  const modifySubscription = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setModifyModal(false);
        setIsModifyButtonLoad(false);
        setActiveTab("crm");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const onModifyModalClose = (e) => {
    setVehicleData({
      vehicle_class_id: "",
      max_speed: "",
      driver_cost: "",
      vehicle_cost: "",
    });
    setModifyModal(false);
  };

  const onEditSubscription = (e, item) => {
    e.preventDefault();
    setVehicleData({
      uuid: item?.uuid,
      vehicle_class_id: item?.vehicle_class_id,
      max_speed: item?.max_speed,
      driver_cost: item?.driver_cost,
      vehicle_cost: item?.vehicle_cost,
    });

    setModifyModal(true);
  };

  const onVehicleValueId = (e, item) => {
    e.preventDefault();
    SetVehicleValueId(item);
    setModifyModal(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="inner-header py-3">
            <div className="left-block">
              <div className="col-xl-12">
                <h6 className="mb-2 mt-3">Default Vehicle Class</h6>
                <div className="input-div" style={{ width: "240px" }}>
                  <Select
                    options={VehicleType}
                    value={VehicleType.find(
                      (option) =>
                        option.value ===
                        JSON.stringify(vehicleData.vehicle_class_id)
                    )}
                    onChange={(selectedOption) =>
                      setVehicleData((prevData) => ({
                        ...prevData,
                        vehicle_class_id: selectedOption.value,
                      }))
                    }
                    placeholder={"Other"}
                  />
                </div>
              </div>
            </div>

            <div className="right-block w-auto">
             
            </div>
          </div>

          <div className="container-event">
            <div className="page-header-row">
              <div className="row">
                <div className="col">
                  <div className="row d-flex justify-content-between company-section"></div>
                </div>
              </div>
            </div>

            <div className="page-table-row general-ac">
              <table
                className={`table table-striped ${isLoading ? "loading" : ""}`}
                width="100%"
              >
                <thead>
                  <tr>
                    <th scope={`col`}>{`Vehicle Class`}</th>
                    <th scope={`col`}>{`Max.Speed`}</th>
                    <th scope={`col`}>{`Driver Cost`}</th>
                    <th scope={`col`}>{`Vehicle Cost`}</th>
                    <th scope={`col`}>{`Action`}</th>
                  </tr>
                </thead>
                <tbody>
                  {VehicleType &&
                    VehicleType.map((item, i) => {
                      // Determine if we have a matching vehicle entry
                      const matchedVehicle = vehicleList?.data?.find(
                        (vehicle) => vehicle.vehicle_class_id === item.value
                      );

                      return (
                        <tr key={i}>
                          <td style={{ cursor: "pointer" }}>
                            {item.label || "-"}
                          </td>
                          {matchedVehicle ? (
                            <>
                              <td style={{ cursor: "pointer" }}>
                                {matchedVehicle.max_speed}
                              </td>
                              <td style={{ cursor: "pointer" }}>
                                {matchedVehicle.driver_cost}
                              </td>
                              <td style={{ cursor: "pointer" }}>
                                {matchedVehicle.vehicle_cost}
                              </td>
                              <td style={{ cursor: "pointer" }}>
                                <button
                                  type="button"
                                  onClick={(e) =>
                                    onEditSubscription(e, matchedVehicle)
                                  }
                                  className="btn btn-info add-btn-circle me-2 editBtn"
                                >
                                  <i className="bi bi-pencil-fill"></i>
                                </button>
                              </td>
                            </>
                          ) : (
                            <>
                              <td style={{ cursor: "pointer" }}>{"-"}</td>
                              <td style={{ cursor: "pointer" }}>{"-"}</td>
                              <td style={{ cursor: "pointer" }}>{"-"}</td>
                              <td style={{ cursor: "pointer" }}>
                                <button
                                  type="button"
                                  onClick={(e) =>
                                    onVehicleValueId(e, item.value)
                                  }
                                  className="btn btn-info add-btn-circle me-2 editBtn"
                                >
                                  <i className="bi bi-pencil-fill"></i>
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className={`float-right`}>
              {/* <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              /> */}
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modifyModal}>
        <ModalHeader>
          {vehicleData?.uuid ? "Edit Direction" : "Create Direaction"}
        </ModalHeader>
        <Form method={`post`} onSubmit={handleVehicleForm}>
          <ModalBody>
            <FormGroup>
              <Label className="col-form-label">{"Max.Speed"}</Label>

              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.max_speed}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      max_speed: e.target.value,
                    });
                  }}
                  name={`max_speed`}
                  maxLength={4}
                  placeholder="km/h"
                />
              </div>
              {errors.max_speed && (
                <span className="input-error">{errors.max_speed}</span>
              )}
            </FormGroup>

            <FormGroup>
              <Label className="col-form-label">{"Vehicle Cost"}</Label>

              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.vehicle_cost}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      vehicle_cost: e.target.value,
                    });
                  }}
                  name={`vehicle_cost`}
                  maxLength={5}
                  placeholder="€/h"
                />
              </div>
              {errors.vehicle_cost && (
                <span className="input-error">{errors.vehicle_cost}</span>
              )}
            </FormGroup>

            <FormGroup>
              <Label className="col-form-label">{"Driver Cost"}</Label>

              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.driver_cost}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      driver_cost: e.target.value,
                    });
                  }}
                  name={`driver_cost`}
                  maxLength={5}
                  placeholder="€/km"
                />
                {errors.driver_cost && (
                  <span className="input-error">{errors.driver_cost}</span>
                )}
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Vehicle`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this vehicle?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroyRole}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DireactionAccount;
