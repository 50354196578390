import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ConfigDB from "../../config";
import {
  actionAddSchedual,
  actionDelSchedual,
  actionDelStop,
  actionOrderStatusChange,
  actionUpdateTrip,
  addCustomerDetails,
  getDriverList,
  getTripDetails,
} from "../../services/TripService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { getVehicleList } from "../../services/VehicleService";
import Select from "react-select";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tooltip,
} from "reactstrap";
import { useSelector } from "react-redux";
import { getSettingDetails } from "../../services/SettingService";
import moment from "moment";
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getOrderStateList } from "../../services/OrderStateService";

const tableLengthList = ConfigDB.data.dataTableLength;
const containerStyle = {
  width: "100%",
  height: "274px",
};

const center = {
  lat: 23.02436178148717,
  lng: 72.570038014801548,
  placeName: "Ahmedabad",
};

const initialZoom = 15;

const libraries = ["places", "drawing"];

const TripDetails = () => {
  const { uuid } = useParams();
  const [tripDetails, setTripDetails] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [DriverList, setDriverList] = useState([]);
  const [driver, setDriver] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [dates, setDates] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [waypoints, setWaypoints] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const [tripDetailsList, setTripDetailsList] = useState([]);
  const [modifyModal, setModifyModal] = useState(false);
  const [modifyModalSchedual, setModifyModalSchedual] = useState(false);
  const [ID, setID] = useState(null);
  const [settingDetails, setsettingDetails] = useState([]);
  const [PriceDetails, setPriceDetails] = useState("");
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });
  const [delModal, setDelModal] = useState(false);
  const [delSubStop, setDelSubStop] = useState("");
  const [delschedulModal, setDelschedulModal] = useState(false);
  const [delSchedul, setDelSchedul] = useState("");
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);
  const [priceDescription, setPriceDescription] = useState("");
  const [distance, setDistance] = useState(0);
  const [duration, setDuration] = useState(0);
  const [time, setTime] = useState(0);
  const [price, setPrice] = useState("");
  const [demo, setDemo] = useState({});
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: ConfigDB.data.GOOGLE_MAP_KEY,
    libraries,
  });
  const [statusList, setStatusList] = useState([]);

  const [isVisible, setIsVisible] = useState(true);
  const [customerData, setCustomerData] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(0);
  const [selectedStatuses, setSelectedStatuses] = useState({});

  const [selectedStatus, setSelectedStatus] = useState({});

  const handleCheckboxChange = () => {
    setIsChecked((prev) => (prev === 0 ? 1 : 0));
  };

  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    invoice_paid_amount: "",
    address: "",
  });

  const handleChangeCutsomer = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const onEditSchedualTrip = (e, item) => {
    setCustomerData(item);
    setIsVisible(!isVisible);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      id: customerData.id,
      customer_name: formData.name,
      customer_contact: formData.contact,
      invoice_paid_amount: formData.invoice_paid_amount,
      invoice_paid_by: isChecked,
      invoice_received_status: isChecked,
    };

    addCustomerDetails(data)
      .then((response) => {
        toast.success(response.data.message);
        setIsVisible(true);

        let datas = {
          uuid: uuid,
          date: state.state.date,
          trip_id: state.state.trip_id,
        };
        setIsLoading(true);
        getTripDetails(datas)
          .then((response) => {
            setTripDetails(response.data.data.tripData);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(Utils.getErrorMessage(err));
          });
      })

      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      });
  };

  const allPermissionsList = useSelector((x) => x.permission.value);
  const mapOptions = {
    styles: [
      {
        elementType: "geometry",
        stylers: [{ color: "#f5f5f5" }],
      },
      {
        elementType: "labels.icon",
        stylers: [{ visibility: "on" }],
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [{ color: "##f0eef0" }],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }],
      },
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#bdd6a4" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#bdd6a4", visibility: "off" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e", visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#dadada" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#616161" }],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [{ color: "#e5e5e5" }],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#b8edff" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#b8edff" }],
      },

      {
        featureType: "poi.medical",
        elementType: "geometry",
        stylers: [{ color: "#f0eef0" }],
      },

      {
        featureType: "poi.medical",
        elementType: "geometry.fill",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  const format = "YYYY-MM-DD";
  const navigate = useNavigate();
  const mapRef = useRef();

  const datePickerRef = useRef();

  const state = useLocation();

  const onMapClick = useCallback(
    async (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
      );
      const data = await response.json();
      const placeName = data.results[0]?.formatted_address || "";

      const position = { lat, lng, placeName };

      if (!origin) {
        setOrigin(position);
      } else if (!destination) {
        setDestination(position);
      } else {
        setWaypoints([...waypoints, position]);
      }
    },
    [origin, destination, waypoints]
  );

  const calculatePrice = (totalDistance, totalDuration) => {
    const distanceRate =
      settingDetails?.length > 0 ? settingDetails[0].price_per_distance : 0;
    const durationRate =
      settingDetails?.length > 0 ? settingDetails[0].price_per_hour : 0;

    const distancePrice = parseFloat((totalDistance * distanceRate).toFixed(2));

    const durationPrice = parseFloat(
      ((totalDuration * durationRate) / 100).toFixed(2)
    );

    if (distancePrice > durationPrice) {
      return {
        price: distancePrice,
        description: `distance: $${distancePrice.toFixed(2)}`,
      };
    } else {
      return {
        price: durationPrice,
        description: `duration: $${durationPrice.toFixed(2)}`,
      };
    }
  };

  const directionsCallback = useCallback(
    (result, status) => {
      if (result !== null && status === "OK") {
        let totalDistance = 0;
        let totalDuration = 0;

        const legs = result.routes[0].legs;

        const triplistDetails = legs
          .filter((leg, index, self) => {
            const isDuplicate = self
              .slice(0, index)
              .some(
                (prevLeg) =>
                  prevLeg.start_location.lat() === leg.start_location.lat() &&
                  prevLeg.start_location.lng() === leg.start_location.lng()
              );

            return !isDuplicate && leg.distance.value !== 0;
          })
          .map((leg) => ({
            latitude: leg.start_location.lat(),
            longitude: leg.start_location.lng(),
            distance: (leg.distance.value / 1000).toFixed(2),
            duration: parseInt(leg.duration.text),
            sourcePlace: leg.start_address,
            destinationPlace: leg.end_address,
            destination_latitude: leg.end_location.lat(),
            destination_longitude: leg.end_location.lng(),
          }));

        result.routes[0].legs.forEach((leg) => {
          totalDistance += leg.distance.value;
          totalDuration += leg.duration.value;
        });

        const totalDistanceText = (totalDistance / 1000).toFixed(2);

        const hours = Math.floor(totalDuration / 3600);
        const minutes = Math.floor((totalDuration % 3600) / 60);
        const totalDurationText = `${hours}h:${minutes}m`;

        function calculateTotalDuration(hours, minutes) {
          return hours * 60 + minutes;
        }

        const hoursTime = hours;
        const minutesTime = minutes;

        const totalTime = calculateTotalDuration(hoursTime, minutesTime);
        const totalDurationTime = `${totalTime}`;

        const totalDistanceInKm = (totalDistance / 1000).toFixed(2);

        const { price, description } = calculatePrice(
          totalDistanceInKm,
          totalDuration
        );

        setPrice(price);
        setTime(totalDurationTime);
        setPriceDescription(description);
        setDistance(totalDistanceText);
        setDuration(totalDurationText);
        setDirections(result);
        setTripDetailsList(triplistDetails);
      }
    },
    [settingDetails]
  );

  const panTo = useCallback(
    async ({ lat, lng, zoom }) => {
      if (mapRef.current) {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
      }
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
      );
      const data = await response.json();
      const placeName = data.results[0]?.formatted_address || "";
      setOrigin({
        lat: lat || tripDetails?.source_latitude,
        lng: lng || tripDetails?.source_longitude,
        placeName: placeName || tripDetails?.sourcePlace,
      });
    },
    [tripDetails]
  );

  useEffect(() => {
    if (tripDetails) {
      setOrigin({
        lat: parseFloat(tripDetails?.source_latitude),
        lng: parseFloat(tripDetails?.source_longitude),
      });

      setDestination({
        lat: parseFloat(tripDetails?.destination_latitude),
        lng: parseFloat(tripDetails?.destination_longitude),
      });

      const waypointsArray =
        tripDetails?.trip_details?.map((detail) => ({
          lat: parseFloat(detail?.source_latitude),
          lng: parseFloat(detail?.source_longitude),
          time: new Date(),
          placeName: detail?.source_place,
        })) || [];

      setWaypoints([...waypointsArray]);
    }
  }, [tripDetails]);

  const panToDestination = useCallback(async ({ lat, lng, zoom }) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(14);
    }
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
    );
    const data = await response.json();
    const placeName = data.results[0]?.formatted_address || "";
    setDestination({ lat, lng, placeName });
  }, []);

  const panToStop = useCallback(
    async ({ lat, lng, zoom }) => {
      if (mapRef.current) {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
      }
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
      );
      const data = await response.json();
      const placeName = data.results[0]?.formatted_address || "";
      setWaypoints([...waypoints, { lat, lng, placeName, time: new Date() }]);
    },
    [waypoints]
  );

  const renderSearchDestination = () => {
    const searchComponents = [];

    for (let i = 0; i < clickCount; i++) {
      const tripDetail = tripDetails?.trip_details[i];

      if (tripDetail) {
        searchComponents.push(
          <SearchStop
            key={i}
            panToStop={panToStop}
            waypoints={waypoints}
            tripDetail={tripDetail}
          />
        );
      } else {
        searchComponents.push(
          <SearchStop
            key={i}
            panToStop={panToStop}
            waypoints={waypoints}
            tripDetail={{}} // Empty object for new destination
          />
        );
      }
    }

    return searchComponents;
  };

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const directionsOptions = useMemo(() => {
    return {
      destination,
      origin,
      waypoints: waypoints?.map((waypoint) => ({ location: waypoint })),
      travelMode: "DRIVING",
    };
  }, [origin, destination, waypoints]);

  const onMarkerDragEnd = (index, event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    const updatedWaypoints = [...waypoints];
    updatedWaypoints[index] = { lat, lng };
    // setWaypoints(updatedWaypoints);
  };

  const handleButtonClick = () => {
    setClickCount((prevCount) => prevCount + 1);
  };

  const handleChange = (newDates) => {
    setDates(newDates);
  };

  const handleBlur = () => {
    setIsOpen(false);
  };

  useEffect(
    function () {
      let data = {
        uuid: uuid,
        date: state.state.date,
        trip_id: state.state.trip_id,
      };
      setIsLoading(true);
      getTripDetails(data)
        .then((response) => {
          setTripDetails(response.data.data.tripData);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [uuid]
  );

  useEffect(
    function () {
      let data = params;

      getDriverList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setDriverList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;

      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      getSettingDetails(data)
        .then((response) => {
          setsettingDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const onDeleteStop = (e, item) => {
    e.preventDefault();
    setDelSubStop(item.uuid);
    setDemo(item);
    setDelModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelSubStop("");
    setDelModal(false);
  };

  /***calculation for during updated ***/

  const calculatePriceUpdate = (totalDistanceDelete, totalDurationDelete) => {
    const distanceRate =
      settingDetails?.length > 0 ? settingDetails[0].price_per_distance : 0;
    const durationRate =
      settingDetails?.length > 0 ? settingDetails[0].price_per_hour : 0;

    const distancePrice = parseFloat(
      (totalDistanceDelete * distanceRate).toFixed(2)
    );
    const durationPrice = parseFloat(
      (totalDurationDelete * durationRate).toFixed(2)
    );

    if (distancePrice > durationPrice) {
      return {
        price: distancePrice,
        description: `Distance: $${distancePrice.toFixed(2)}`,
      };
    } else {
      return {
        price: durationPrice,
        description: `Duration: $${durationPrice.toFixed(2)}`,
      };
    }
  };

  const deletedDistance = demo?.total_distance;
  const deletedDuration = demo?.total_duration;

  const totalDistanceDelete =
    tripDetails?.trip_details?.reduce(
      (acc, trip) => acc + trip.total_distance,
      0
    ) || 0;

  const totalFinalDistance = (totalDistanceDelete - deletedDistance).toFixed(2);

  const totalDurationDelete =
    tripDetails?.trip_details?.reduce(
      (acc, trip) => acc + trip.total_duration,
      0
    ) || 0;

  const totalFinalDuration = totalDurationDelete - deletedDuration;
  const totalPrice = calculatePriceUpdate(
    totalFinalDistance,
    totalFinalDuration
  );

  const onDestroyStop = (e) => {
    e.preventDefault();
    let data = {
      trip_uuid: tripDetails?.uuid,
      uuid: delSubStop,
      total_price: totalPrice.price,
      total_distance: totalFinalDistance,
      total_duration: totalFinalDuration,
    };
    actionDelStop(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelSubStop("");
        setDelModal(false);
        setIsDelButtonLoad(false);
        getTripDetails({
          uuid: uuid,
          date: state.state.date,
          trip_id: state.state.trip_id,
        })
          .then((response) => {
            setTripDetails(response.data.data.tripData);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(Utils.getErrorMessage(err));
          });
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const onDeleteSchedul = (e, item) => {
    e.preventDefault();
    setDelSchedul(item.uuid);
    setDelschedulModal(true);
  };

  const onDeleteModalSchedulClose = (e) => {
    e.preventDefault();
    setDelSchedul("");
    setDelschedulModal(false);
  };

  const onDestroySchedual = (e) => {
    e.preventDefault();
    let data = { uuid: delSchedul };
    actionDelSchedual(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelschedulModal(false);
        setIsDelButtonLoad(false);
        setIsLoading(false);
        navigate("/trip");
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const onModifyModalClose = (e) => {
    setModifyModal(false);
  };

  const handleTripForm = (e) => {
    e.preventDefault();
    let data = {
      sourcePlace: origin?.placeName || tripDetails?.source_place,
      sourceLatitude: origin?.lat || tripDetails?.source_latitude,
      sourceLongitude: origin?.lng || tripDetails?.source_longitude,
      destinationPlace:
        destination?.placeName || tripDetails?.destination_place,
      destinationLatitude:
        destination?.lat || tripDetails?.destination_latitude,
      destinationLongitude:
        destination?.lng || tripDetails?.destination_longitude,
      totalDistance: distance,
      totalDuration: time,
      price: price,
      uuid: tripDetails?.uuid,
      tripId: tripDetails?.Id,
      companyId: tripDetails?.companyId,
      trip_list: tripDetails?.trip_details,
    };

    if (tripDetailsList) {
      data.trip_list = tripDetailsList;
    }

    actionUpdateTrip(data)
      .then((response) => {
        toast.success(response.data.message);
        setModifyModal(false);
        getTripDetails({
          uuid: uuid,
          date: state.state.date,
          trip_id: state.state.trip_id,
        })
          .then((response) => {
            setTripDetails(response.data.data.tripData);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(Utils.getErrorMessage(err));
          });
      })

      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      });
  };

  const onAddSubscription = (e) => {
    e.preventDefault();
    setModifyModal(true);
  };

  const onModifyModalSchedualClose = (e) => {
    setModifyModalSchedual(false);
  };

  const Dateselected = Array.isArray(dates)
    ? dates.map((date) => date.format("YYYY-MM-DD"))
    : [];

  const handleSchedualForm = (e) => {
    e.preventDefault();
    let data = {
      trip_schedule: {
        companyId: tripDetails?.companyId,
        trip_id: tripDetails?.id,
        driverId: driver.driverId,
        vehicleId: vehicle.vehicleId,
        date: Dateselected,
      },
    };

    let updateData = {
      trip_schedule: {
        companyId: tripDetails?.companyId,
        trip_id: tripDetails?.id,
        driverId: driver.driverId || driver,
        vehicleId: vehicle.vehicleId || vehicle,
        date:
          Array.isArray(Dateselected) && Dateselected.length !== 0
            ? Dateselected
            : [ID.date],
        uuid: ID.uuid,
      },
    };

    if (ID.uuid) {
      actionAddSchedual(updateData)
        .then((response) => {
          toast.success(response.data.message);
          // setTripDetails(response.data.data);
          setIsLoading(false);
          navigate("/trip");
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      actionAddSchedual(data)
        .then((response) => {
          toast.success(response.data.message);
          setModifyModalSchedual(false);

          getTripDetails({
            uuid: uuid,
            date: state.state.date,
            trip_id: state.state.trip_id,
          })
            .then((response) => {
              // setTripDetails(response.data.data);
              setIsLoading(false);
              navigate("/trip");
            })
            .catch((err) => {
              setIsLoading(false);
              toast.error(Utils.getErrorMessage(err));
            });
        })

        .catch((error) => {
          toast.error(Utils.getErrorMessage(error));
        });
    }
  };

  const onAddSchedual = (e) => {
    e.preventDefault();
    setDriver("");
    setVehicle("");
    setDates("");
    setModifyModalSchedual(true);
    setID("");
  };

  const onEditSchedual = (e, item) => {
    e.preventDefault();
    setDriver(item.driver_id);
    setVehicle(item.vehicle_id);
    setDates(item.date);
    setModifyModalSchedual(true);
    setID(item);
  };

  const ItemComponent = ({ item }) => {
    let badgeColor = "";
    switch (item.name) {
      case "In Progress":
        badgeColor = "bg-warning";
        break;
      case "Cancelled":
        badgeColor = "bg-danger";
        break;
      case "Delivered":
        badgeColor = "bg-success";
        break;
      case "Picked up":
        badgeColor = "bg-info";
        break;
      case "On Route":
        badgeColor = "bg-primary";
        break;
      default:
        badgeColor = "bg-secondary";
    }

    return (
      <span className={`badge rounded-pill btn-warning ${badgeColor}`}>
        {item.name}
      </span>
    );
  };

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500); // Reset the copied state after 1.5 seconds
  };

  useEffect(
    function () {
      let data = params;

      getOrderStateList(data)
        .then((response) => {
          setStatusList(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const handleStatusChange = (itemId, e) => {
    const selectedValue = e.target.value;
    setSelectedStatuses({
      ...selectedStatuses,
      [itemId]: selectedValue,
      selectedValue,
    });

    setIsPopupOpen(true);
  };

  const onstatusChange = (e) => {
    e.preventDefault();
    let data = {
      uuid: selectedStatus?.uuid,
      status_id: selectedStatuses.selectedValue,
    };
    actionOrderStatusChange(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setIsPopupOpen(false);
        let datas = {
          uuid: uuid,
          date: state.state.date,
          trip_id: state.state.trip_id,
        };
        setIsLoading(true);
        getTripDetails(datas)
          .then((response) => {
            setTripDetails(response.data.data.tripData);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(Utils.getErrorMessage(err));
          });
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <>
      <div>
        <div className="inner-header py-3">
          <div className="left-block">
            <h5>Trip Details</h5>
          </div>
        </div>
        <div
          style={{
            padding: "20px",
            marginTop: "30px",
            marginBottom: "-53px",
            display: "flex",
            fontSize: "28px",
          }}
        >
          <Button
            style={{
              color: "#fff",
              backgroundColor: "rgb(114 205 246)",
              borderColor: "rgb(114 205 246)",
            }}
            onClick={() => {
              navigate("/trip");
            }}
          >
            <i className="bi bi-arrow-left"></i>
          </Button>
        </div>

        <div className="container-event-profle">
          <div className="profle-block tab-content" id="nav-tabContent">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="row row-trip-box">
                    <div className="row-edite">
                      <div className="row">
                        <div className="col-xl-6">
                          <Button
                            type={`button`}
                            onClick={onAddSubscription}
                            className={`btn btn-info add-btn-circle me-2`}
                            style={{ borderRadius: "10px" }}
                          >
                            <i className="bi bi-pencil-fill me-2"></i>
                            Edit
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6" style={{ marginTop: "10px" }}>
                      Total Price:
                      <span style={{ color: "gray" }}>
                        {" "}
                        $ {tripDetails?.price}
                      </span>
                    </div>

                    <div
                      className="col-xl-6"
                      style={{
                        // marginTop: "10px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <div>
                        Driver:
                        <span style={{ color: "gray" }}>
                          {" "}
                          {tripDetails?.schedule_list?.map((item) => {
                            return <>{item.driver_name}</>;
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="row-edite" style={{ marginTop: "10px" }}>
                      <div className="col-xl-12">
                        <div>
                          <i
                            className="bi bi-flag-fill me-2"
                            style={{ color: "#7ED328" }}
                          ></i>
                          <span style={{ color: "gray" }}>
                            {tripDetails?.source_place || "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="table-reponsive" style={{ padding: "0" }}>
                      <table
                        className="table tripdetails-table tripdetails-table-2"
                        style={{
                          marginBottom: "33px",
                          marginTop: "15px",
                          border: "solid #47cbf2 1px;",
                        }}
                      >
                        <thead>
                          <tr>
                            <th scope="col" style={{ fontWeight: "600" }}>
                              Mileage
                            </th>
                            <th scope="col" style={{ fontWeight: "600" }}>
                              Duration
                            </th>
                            <th scope="col" style={{ fontWeight: "600" }}>
                              Stop
                            </th>
                            <th scope="col" style={{ fontWeight: "600" }}>
                              Pin
                            </th>

                            <th scope="col" style={{ fontWeight: "600" }}>
                              Reference Number
                            </th>
                            <th scope="col" style={{ fontWeight: "600" }}>
                              Status
                            </th>
                            <th scope="col" style={{ fontWeight: "600" }}>
                              Action
                            </th>
                            <th scope="col" style={{ fontWeight: "600" }}>
                              Status Change
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tripDetails?.trip_details?.map((item, i) => {
                            return (
                              <tr>
                                <td>{item?.total_distance} Km</td>
                                <td>{item?.total_duration} min</td>
                                <td>{item?.source_place}</td>
                                <td>{item?.pin}</td>
                                <td>{item?.reference_number}</td>

                                <td>
                                  <ItemComponent item={item} />
                                </td>

                                <td>
                                  <i
                                    className="bi bi-trash"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => onDeleteStop(e, item)}
                                  ></i>
                                  {item.customer_name === null &&
                                  item.customer_contact === null ? (
                                    <i
                                      onClick={(e) =>
                                        onEditSchedualTrip(e, item)
                                      }
                                      class="bi bi-pencil icon-copy"
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <span className="tooltips">Edits</span>
                                    </i>
                                  ) : (
                                    // <OverlayTrigger placement="bottom" overlay={tooltip} show={copied}>
                                    <CopyToClipboard
                                      text={`https://inroute.neutroveg.com/tracking/order/${item.uuid}`}
                                      onCopy={handleCopy}
                                    >
                                      <i
                                        className="bi bi-copy icon-copy"
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <span className="tooltips">Copied</span>
                                      </i>
                                    </CopyToClipboard>
                                    // </OverlayTrigger>
                                  )}
                                </td>
                                <td>
                                  <select
                                    id="status"
                                    className="form-select"
                                    value={
                                      selectedStatuses[item.id || item.uuid] ||
                                      item.status_id
                                    } // Access individual item's status
                                    onChange={(e) =>
                                      handleStatusChange(
                                        item.id || item.uuid,
                                        e,
                                        setSelectedStatus(item)
                                      )
                                    } // Pass item ID to the handler
                                  >
                                    <option value="">Select status</option>
                                    {statusList.map((status) => (
                                      <option key={status.id} value={status.id}>
                                        {status.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className="row">
                      <div className="col-xl-12">
                        <div>
                          <i
                            className="bi bi-flag-fill me-2"
                            style={{ color: "red" }}
                          ></i>
                          <span style={{ color: "gray" }}>
                            {tripDetails?.destination_place || "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-sm-6"
                        style={{ display: "flex", marginTop: "22px" }}
                      >
                        <div>
                          Total Mileage:
                          <span style={{ color: "gray" }}>
                            {" "}
                            {tripDetails?.total_distance} km
                          </span>
                        </div>
                      </div>

                      <div
                        className="col-sm-6"
                        style={{ display: "flex", marginTop: "22px" }}
                      >
                        <div>
                          Total Duration:
                          <span style={{ color: "gray" }}>
                            {" "}
                            {tripDetails?.total_duration} min
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={!isVisible ? "col-xl-4" : "col-xl-4 d-none"}>
                  <div className="form-hader-trip">
                    <h4>Customer Information</h4>
                  </div>
                  <Form
                    className="customer-information"
                    onSubmit={handleSubmit}
                  >
                    <FormGroup
                      style={{
                        padding: "10px",
                        marginBottom: "0rem !important",
                        marginTop: "-20px",
                      }}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <Label
                            style={{ fontSize: "16px", fontWeight: "600" }}
                          >
                            Name
                          </Label>
                          <Input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChangeCutsomer}
                            placeholder="Name"
                          />
                        </div>
                        <div className="col-md-6">
                          <Label
                            style={{ fontSize: "16px", fontWeight: "600" }}
                          >
                            Contact
                          </Label>
                          <Input
                            type="text"
                            name="contact"
                            value={formData.contact}
                            onChange={handleChangeCutsomer}
                            placeholder="Contact"
                          />
                        </div>

                        <div className="col-md-12">
                          <Label
                            style={{ fontSize: "16px", fontWeight: "600" }}
                          >
                            Invoice Amount
                          </Label>
                          <Input
                            type="text"
                            name="invoice_paid_amount"
                            value={formData.invoice_paid_amount}
                            onChange={handleChangeCutsomer}
                            placeholder="Invoice amount"
                          />
                        </div>

                        <div className="col-md-12">
                          <Label
                            style={{ fontSize: "16px", fontWeight: "600" }}
                          >
                            Address
                          </Label>
                          <Input
                            type="textarea"
                            name="address"
                            rows="2"
                            value={customerData.source_place}
                            disabled
                            placeholder="Address"
                          />
                        </div>

                        <div className="col-md-6">
                          <label>
                            <Input
                              type="checkbox"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                            />
                            <span style={{ padding: "10px" }}>
                              Invoice Amount Paid
                            </span>
                          </label>
                        </div>
                      </div>
                    </FormGroup>
                    <Button
                      color="secondary"
                      type="submit"
                      className="btn-square"
                    >
                      Save
                    </Button>
                  </Form>
                </div>

                <div className="col-xl-6 d-none">
                  <div className="row">
                    <div className="col-xl-9">
                      <div>
                        <h5 style={{ fontSize: "17px", fontWeight: "500" }}>
                          Schedule Trip Details:
                        </h5>
                      </div>
                    </div>

                    <div className="col-xl-3">
                      <div className="addSchedule">
                        <Button
                          color="btn btn-info"
                          type={`submit`}
                          className={`btn-square`}
                          onClick={onAddSchedual}
                        >
                          <i className="bi bi-plus-lg"></i>
                          {`Add Schedule`}
                        </Button>
                      </div>
                    </div>

                    <table
                      className="table tripdetails-table"
                      style={{ marginBottom: "33px", marginTop: "15px" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col"></th>

                          <th scope="col" style={{ fontWeight: "600" }}>
                            Driver Name
                          </th>
                          <th scope="col" style={{ fontWeight: "600" }}>
                            Date
                          </th>
                          <th scope="col" style={{ fontWeight: "600" }}>
                            Vehicle Name
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tripDetails?.schedule_list?.map((item, i) => {
                          return (
                            <tr>
                              <td onClick={(e) => onDeleteSchedul(e, item)}>
                                <i
                                  className="bi bi-trash"
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </td>

                              <td onClick={(e) => onEditSchedual(e, item)}>
                                <i
                                  className="bi bi-pencil"
                                  style={{
                                    marginLeft: "-27px",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </td>

                              <td>{item?.driver_name}</td>
                              <td>{moment(item?.date).format("DD-MM-YYYY")}</td>
                              <td>{item?.vehicle_name}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={delModal}>
          <ModalHeader>{`Delete Stop`}</ModalHeader>
          <ModalBody>
            <p
              className={`mb-0`}
            >{`Are you sure you want to delete this Stop?`}</p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isDelButtonLoad}
              outline
              onClick={onDeleteModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isDelButtonLoad && (
              <Button
                color="secondary"
                type={`button`}
                className={`btn-square`}
                onClick={onDestroyStop}
              >{`Delete`}</Button>
            )}
            {isDelButtonLoad && (
              <Button
                color="secondary"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Modal>

        <Modal isOpen={modifyModal}>
          <ModalHeader>Edit Trip</ModalHeader>
          <Form onSubmit={handleTripForm}>
            <ModalBody>
              <div>
                <div className="row">
                  <div className="row">
                    <div>
                      <Search panTo={panTo} tripDetails={tripDetails} />
                    </div>
                  </div>
                  <div className="row">
                    <div>
                      <SearchDestination
                        panToDestination={panToDestination}
                        tripDetails={tripDetails}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div
                      style={{
                        margin: "10px",
                        marginTop: "-15px",
                        marginTop: "8px",
                      }}
                    >
                      <label
                        style={{
                          border: "none",
                          cursor: "pointer",
                        }}
                        className="btn addDestinationBtn"
                        onClick={handleButtonClick}
                      >
                        <i className="bi bi-geo-alt me-2"></i> Add Destination
                      </label>

                      {renderSearchDestination()}
                    </div>
                  </div>
                </div>
              </div>
              {isLoaded ? (
                <div>
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={initialZoom}
                    onClick={onMapClick}
                    // onMapLoad={onMapLoad}
                    onUnmount={onUnmount}
                    options={mapOptions}
                  >
                    {waypoints.map((waypoint, index) => (
                      <Marker
                        key={index}
                        position={{ lat: waypoint.lat, lng: waypoint.lng }}
                        draggable={true}
                        onDragEnd={(event) => onMarkerDragEnd(index, event)}
                        icon={{
                          url: `https://maps.google.com/mapfiles/ms/icons/${
                            index + 1
                          }_marker.png`,
                        }}
                      />
                    ))}

                    <Marker
                      position={origin}
                      draggable={true}
                      // onDragEnd={onMarkerDragEnd}
                    />
                    {origin && destination && (
                      <DirectionsService
                        options={directionsOptions}
                        callback={directionsCallback}
                        draggable
                      />
                    )}

                    {directions && (
                      <DirectionsRenderer directions={directions} />
                    )}
                  </GoogleMap>
                </div>
              ) : (
                <></>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type={`button`}
                outline
                onClick={onModifyModalClose}
                className={`btn-square`}
              >{`Close`}</Button>
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={modifyModalSchedual}>
          <ModalHeader>
            {ID?.uuid ? `Edit Schedule` : `Create Schedule`}
          </ModalHeader>
          <Form method={`post`} onSubmit={handleSchedualForm}>
            <ModalBody>
              <div>
                <div className="row">
                  <div className="row">
                    <div>
                      <div className="row">
                        <div>
                          <div style={{ marginBottom: "4px" }}>
                            <i className="bi bi-person me-2"></i>
                            <span
                              style={{ fontSize: "16px", fontWeight: "600" }}
                            >
                              Driver
                            </span>
                          </div>
                          <Select
                            options={DriverList}
                            value={DriverList.find(
                              (option) => option.value == driver
                            )}
                            onChange={(selectedOption) =>
                              setDriver((prevData) => ({
                                ...prevData,
                                driverId: selectedOption.value,
                              }))
                            }
                            placeholder={"Select Driver"}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div style={{ marginTop: "10px" }}>
                          <div style={{ marginBottom: "4px" }}>
                            <i className="bi bi-truck me-2"></i>
                            <span
                              style={{ fontSize: "16px", fontWeight: "600" }}
                            >
                              Vehicle
                            </span>
                          </div>
                          <Select
                            options={vehicleList}
                            value={vehicleList?.find(
                              (option) => option.value == vehicle
                            )}
                            onChange={(selectedOption) =>
                              setVehicle((prevData) => ({
                                ...prevData,
                                vehicleId: selectedOption.value,
                              }))
                            }
                            placeholder={"Select Vehicle"}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div style={{ marginTop: "10px" }}>
                          <div style={{ marginBottom: "4px" }}>
                            <i className="bi bi-clock me-2"></i>
                            <span
                              style={{ fontSize: "16px", fontWeight: "600" }}
                            >
                              Date
                            </span>
                          </div>

                          <DatePicker
                            value={dates}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            multiple
                            sort
                            format={format}
                            calendarPosition="bottom-center"
                            plugins={[<DatePanel />]}
                            placeholder="Select Date"
                            style={{
                              border: "1px solid #CCCCCC",
                              borderRadius: "5px",
                              height: "40px",
                              margin: "1px 0",
                              padding: "2px 5px",
                              width: "442px",
                            }}
                            ref={datePickerRef}
                          >
                            <button
                              className="btn btn-primary mb-2"
                              onClick={() =>
                                datePickerRef.current.closeCalendar()
                              }
                            >
                              Close
                            </button>
                          </DatePicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type={`button`}
                outline
                onClick={onModifyModalSchedualClose}
                className={`btn-square`}
              >{`Close`}</Button>
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >
                {ID?.trip_id ? `Edit Schedule ` : `Create Schedule`}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={delschedulModal}>
          <ModalHeader>{`Delete Schedual`}</ModalHeader>
          <ModalBody>
            <p
              className={`mb-0`}
            >{`Are you sure you want to delete this Schedual?`}</p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isDelButtonLoad}
              outline
              onClick={onDeleteModalSchedulClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isDelButtonLoad && (
              <Button
                color="secondary"
                type={`button`}
                className={`btn-square`}
                onClick={onDestroySchedual}
              >{`Delete`}</Button>
            )}
            {isDelButtonLoad && (
              <Button
                color="secondary"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Modal>

        <Modal isOpen={isPopupOpen}>
          <ModalHeader>{`Order Status Change`}</ModalHeader>
          <ModalBody>
            <p
              className={`mb-0`}
            >{`Are you sure you want to order status change?`}</p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              outline
              onClick={handleClosePopup}
              className={`btn-square`}
            >{`Close`}</Button>
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square`}
              onClick={onstatusChange}
            >{`Save`}</Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

const Search = ({ panTo, setOrigin, tripDetails }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (tripDetails && tripDetails.source_place) {
      setValue(tripDetails.source_place);
    }
  }, [tripDetails, setValue]);

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({
        lat: lat || tripDetails?.source_latitude,
        lng: lng || tripDetails?.source_longitude,
      });

      setOrigin({
        lat: lat || tripDetails?.source_latitude,
        lng: lng || tripDetails?.source_longitude,
        time: new Date(),
      });
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup style={{ padding: "10px", marginBottom: "0rem !important" }}>
          <i className="bi bi-geo me-2"></i>
          <Label style={{ fontSize: "16px", fontWeight: "600" }}>Source</Label>
          <Input
            type="text"
            value={value}
            onChange={handleInput}
            // disabled={!ready}
            placeholder="Search your location"
          />
        </FormGroup>
      </Form>
      {status === "OK" && value !== tripDetails?.source_place && (
        <ListGroup>
          {data.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

const SearchDestination = ({
  panToDestination,
  setDestination,
  tripDetails,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  useEffect(() => {
    if (tripDetails && tripDetails.destination_place) {
      setValue(tripDetails.destination_place);
    }
  }, [tripDetails, setValue]);

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panToDestination({
        lat: lat || tripDetails?.destination_latitude,
        lng: lng || tripDetails?.destination_longitude,
      });

      setDestination({
        lat: lat || tripDetails?.destination_latitude,
        lng: lng || tripDetails?.destination_longitude,
        time: new Date(),
      });
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup
          style={{
            padding: "10px",
            marginBottom: "0rem !important",
            marginTop: "-20px",
          }}
        >
          <i className="bi bi-geo-alt me-2"></i>
          <Label style={{ fontSize: "16px", fontWeight: "600" }}>
            Destination
          </Label>

          <Input
            type="text"
            value={value}
            onChange={handleInput}
            // disabled={!ready}
            placeholder="Search your location"
          />
        </FormGroup>
      </Form>
      {status === "OK" && value !== tripDetails?.destination_place && (
        <ListGroup>
          {data.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

const SearchStop = ({ panToStop, setWaypoints, waypoints, tripDetail }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  useEffect(() => {
    if (tripDetail && tripDetail.source_place) {
      setValue(tripDetail.source_place);
    }
  }, [tripDetail, setValue]);

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleClear = () => {
    setValue("");
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panToStop({ lat, lng });

      setWaypoints([
        ...waypoints,
        { lat, lng, time: new Date(), placeName: address },
      ]);
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup
          style={{
            padding: "10px",
            marginBottom: "0rem !important",
            marginTop: "-20px",
          }}
        >
          <div className="stop-class">
            <i className="bi bi-geo-alt me-2"></i>
            <Label style={{ fontSize: "16px", fontWeight: "600" }}>Stop</Label>
          </div>

          <div className="input-des">
            <Input
              type="text"
              value={value}
              onChange={handleInput}
              // disabled={!ready}
              placeholder="Search your location"
            />

            <i className="bi bi-x-circle" onClick={handleClear}></i>
          </div>
        </FormGroup>
      </Form>
      {status === "OK" && value !== tripDetail?.source_place && (
        <ListGroup>
          {data.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default TripDetails;
