import React from "react";
import ConfigDB from "../../config";

const ApiDocument = () => {
  const urlToCopy = `${ConfigDB.data.api_url}order-trip`;
  const handleCopy = () => {
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        console.log("URL copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleCopyParameter = () => {
    const commandText = document.querySelector(".curl-command code").innerText;

    navigator.clipboard
      .writeText(commandText)
      .then(() => {
        const tooltip = document.querySelector(".tooltips");
        tooltip.textContent = "Copied!";
        setTimeout(() => (tooltip.textContent = "Copy cURL command"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy command: ", err);
      });
  };

  return (
    <div  className="container-fluid">
      <div className="row">
      <div className="col-sm-8">
      <div className="api-reference">
        {/* HTTP Status Code Summary Section */}
        <div className="api-reference-box">
          <div className="section-title" style={{ marginBottom:"0", textAlign:"left",paddingBottom:"15px"}} >HTTP Status Code Summary</div>
          <div className="table-container">
            <table className="api-reference-table">
              <thead>
                <tr>
                  <th>Status Code</th>
                  <th>Short Description</th>
                  <th>Detailed Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>200</td>
                  <td>OK</td>
                  <td>Everything worked as expected.</td>
                </tr>
                <tr>
                  <td>400</td>
                  <td>Bad Request</td>
                  <td>
                    The request was unacceptable, often due to missing a
                    required parameter.
                  </td>
                </tr>
                <tr>
                  <td>401</td>
                  <td>Unauthorized</td>
                  <td>No valid API key provided.</td>
                </tr>
                <tr>
                  <td>402</td>
                  <td>Request Failed</td>
                  <td>The parameters were valid but the request failed.</td>
                </tr>
                <tr>
                  <td>403</td>
                  <td>Forbidden</td>
                  <td>
                    The API key doesn’t have permissions to perform the request.
                  </td>
                </tr>
                <tr>
                  <td>404</td>
                  <td>Not Found</td>
                  <td>The requested resource doesn’t exist.</td>
                </tr>
                <tr>
                  <td>409</td>
                  <td>Conflict</td>
                  <td>
                    The request conflicts with another request (perhaps due to
                    using the same idempotent key).
                  </td>
                </tr>
                <tr>
                  <td>429</td>
                  <td>Too Many Requests</td>
                  <td>
                    Too many requests hit the API too quickly. We recommend an
                    exponential backoff of your requests.
                  </td>
                </tr>
                <tr>
                  <td>500, 502, 503, 504</td>
                  <td>Server Errors</td>
                  <td>
                    Something went wrong on Stripe’s end. (These are rare.)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Error Types Section */}
        <div className="api-reference-box">
          <div className="section-title" style={{ marginBottom:"0", textAlign:"left"}}>Error Types</div>
          <div className="table-container">
            <table className="api-reference-table">
              <thead>
                <tr>
                  <th>Error Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <code>api_error</code>
                  </td>
                  <td>
                    API errors cover any other type of problem (e.g., a
                    temporary problem with Stripe’s servers), and are extremely
                    uncommon.
                  </td>
                </tr>
                <tr>
                  <td>
                    <code>card_error</code>
                  </td>
                  <td>
                    Card errors are the most common type of error you should
                    expect to handle. They result when the user enters a card
                    that can’t be charged for some reason.
                  </td>
                </tr>
                <tr>
                  <td>
                    <code>idempotency_error</code>
                  </td>
                  <td>
                    Idempotency errors occur when an{" "}
                    <code>Idempotency-Key</code> is re-used on a request that
                    does not match the first request’s API endpoint and
                    parameters.
                  </td>
                </tr>
                <tr>
                  <td>
                    <code>invalid_request_error</code>
                  </td>
                  <td>
                    Invalid request errors arise when your request has invalid
                    parameters.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
      <div className="col-sm-4">
      <div className="aside-section">
        <h2 className="heading" style={{marginTop:"10px",}}>Not a developer?</h2>
        <p>
          Create Trip{" "}
          <a href="/payments/no-code" className="link">
            <span>No-code options</span>
          </a>{" "}
          or apps from{" "}
          <a href="https://stripe.partners/" className="link">
            <span>our partners</span>
          </a>{" "}
          to get started with Stripe and to do more with your Stripe account—no
          code required.
        </p>
      </div>

      <div className="base-url-section">
        <div className="base-url-header">
          <div className="base-url-title">Base URL</div>
          <button
            className="copy-button"
            onClick={handleCopy}
            aria-label="Copy URL"
          >
            <i class="bi bi-copy icon-copy">
              <span className="tooltips">copied</span>
            </i>
          </button>
        </div>
        <pre className="base-url">
          <code>{urlToCopy}</code>
        </pre>
      </div>

      <div className="authenticated-request">
        <div className="header">
          <div className="title">Create Order Trip Parameter</div>

          <button
            className="copy-button"
            aria-label="Copy cURL command"
            onClick={handleCopyParameter}
          >
            <i className="bi bi-copy icon-copy">
              <span className="tooltips">Copy cURL command</span>
            </i>
          </button>
        </div>
        <pre className="curl-command">
          <code>
            <span className="curl-command-text">sourcePlace: ""</span>
            <br />
            <span className="curl-command-text">sourceLatitude: ""</span>
            <br />
            <span className="curl-command-text">sourceLongitude: ""</span>
            <br />
            <span className="curl-command-text">destinationPlace: ""</span>
            <br />
            <span className="curl-command-text">providerName: ""</span>
            <br />
            <span className="curl-command-text">destinationLatitude: ""</span>
            <br />
            <span className="curl-command-text">destinationLongitude: ""</span>
            <br />
            <span className="curl-command-text">totalDistance: ""</span>
            <br />
            <span className="curl-command-text">totalDuration: ""</span>
            <br />
            <span className="curl-command-text">price: ""</span>
            <br />
            <span className="comment"># All are order create parameter.</span>
          </code>
        </pre>
      </div>
        </div>   
      
    </div>
    </div>
  );
};

export default ApiDocument;
