import axiosInstance from "./AxiosInstance";

export function actionAddNotification(data) {
    return axiosInstance.post(`/create-alarm-notification`, data);
}

export function getNotificationList(data) {
    return axiosInstance.post(`/alarm-notification-list`, data);
}

export function actionDelNotification(data) {
    return axiosInstance.post(`/alarm-notification-delete`, data);
}

export function getAllVehicleTaskList(data) {
    return axiosInstance.post(`maintenance-task/all-list`, data);
}

export function getAllgeoFenseList(data) {
    return axiosInstance.post(`/device-geo-fence-list`, data);
}
