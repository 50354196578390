import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate, useParams } from "react-router-dom";

import { getDriverAllDetails } from "../../services/EmployeeService";
import GeneralDepartment from "./GeneralDepartment";
import GeneralSegment from "./GeneralSegment";

const AddSegment = ({ setAddDepartmentStatus, addDepatmentData }) => {
  const [activeTab, setActiveTab] = useState("general");
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="inner-header py-3 d-none">
        <div className="left-block"></div>
      </div>
      <div className="inner-header-back">
        <div className="left-col">
          <button
            type="buttton"
            className="back-btn"
            onClick={() => {
              setAddDepartmentStatus(false);
            }}
          >
            <i class="bi bi-chevron-left"></i> Back
          </button>
        </div>
        <div className="tight-block"></div>
      </div>

      <div className="leaflet-control-management managementui-tab leaflet-control-var">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "general" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("general");
                }}
              >
                <span data-href="#tab-1">General</span>
              </li>
            </ul>
          </nav>

          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "general" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <GeneralSegment
                setActiveTab={setActiveTab}
                addDepatmentData={addDepatmentData}
                setAddDepartmentStatus={setAddDepartmentStatus}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSegment;
