import axiosInstance from "./AxiosInstance";

export function actionAddUpdatemaintainanceparm(data) {
  return axiosInstance.post(`/maintainanceparm/create-update`, data);
}

export function getMaintainanceparmList(data) {
  return axiosInstance.post(`/maintainanceparm-list`, data);
}

export function actionDelMaintainanceparm(data) {
  return axiosInstance.post(`/maintainanceparm/delete`, data);
}

export function actionAddUpdatemaintainanceTask(data) {
  return axiosInstance.post(`/maintenance-task/create-update`, data);
}

export function getMaintainanceTaskList(data) {
  return axiosInstance.post(`/maintenance-task-list`, data);
}

export function actionDelMaintainanceTask(data) {
  return axiosInstance.post(`/maintenance-task/delete`, data);
}

export function actionAddVehicleTask(data) {
  return axiosInstance.post(`/vehicle-task/add`, data);
}

export function getVehicleTaskList(data) {
  return axiosInstance.post(`/vehicle-task/list`, data);
}

export function getVehicleTaskHistoryList(data) {
  return axiosInstance.post(`/vehicle-task-history/list`, data);
}

export function createVehicleMaintance(data) {
  return axiosInstance.post(
    `/create-vehicle-task
`,
    data
  );
}
