import React, { useState, useEffect } from "react";

import { Button, Form, FormGroup, Label } from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  actionClassVehicle,
  actionUpdateVehicle,
  getVehicleList,
} from "../../services/VehicleService";
import { actionCompanyList } from "../../services/Common";
import Select from "react-select";
import { getDriverList } from "../../services/TripService";
import { useNavigate } from "react-router-dom";
import {
  getDepartmentAllList,
  getSegmentAllList,
} from "../../services/ManagementService";

const tableLengthList = configDb.data.dataTableLength;

const General = ({ setActiveTab, vehicleDetails }) => {
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);
  const navigate = useNavigate();
  let filterData = localStorage.getItem("filter");
  const [vehicleList, setVehicleList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [vehicleData, setVehicleData] = useState({
    uuid: "",
    vehicleName: "",
    modelYear: "",
    vehicleClass: "",
    vehiclePlate: "",
    maintainanceKm: "",
    companyId: "",
    deviceImei: "",
    driverId: "",
    speed: "",
    total_km_after_maintanance: "",
    vehicle_model: "",
    department_id: "",
    segement_id: "",
    manufacturer: "",
  });
  const [DriverList, setDriverList] = useState([]);
  const [vehicleClasssList, setVehicleClasssList] = useState([]);
  const [vehicleClassFilterList, setVehicleClassFilterList] = useState([]);

  const [companyList, setCompanyList] = useState([]);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [vehicleClass, setVehicleClass] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const [segementList, setSegementList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  let errorsObj = {
    vehicleName: "",
    modelYear: "",
    vehicleClass: "",
    vehiclePlate: "",
    maintainanceKm: "",
    companyId: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
    vehicle_class: vehicleClass.vehicle_class,
    filter_id: filterData,
  });

  const selectedCompany = companyList.find(
    (item) => item.value == vehicleData.companyId
  );
  const selectedClass = vehicleClasssList.find(
    (item) => item.value == vehicleData.vehicleClass
  );

  useEffect(() => {
    if (vehicleDetails) {
      setVehicleData({
        vehicleName: vehicleDetails.vehicleName || "",
        modelYear: vehicleDetails.vehicle_make_model_year || "",
        vehicleClass: vehicleDetails.vehicle_class || "",
        vehiclePlate: vehicleDetails.vehicle_plate_no || "",
        companyId: vehicleDetails.companyId || "",
        deviceImei: vehicleDetails.device_imei || "",
        driverId: vehicleDetails.driver_id || "",
        total_km_after_maintanance:
          vehicleDetails.total_km_after_maintanance || "",
        vehicle_model: vehicleDetails.vehicle_model || "",
        department_id: vehicleDetails.department_id || "",
        segement_id: vehicleDetails.segment_id || "",
        manufacturer: vehicleDetails.manufacturer || "",
      });
    }
  }, [vehicleDetails]);

  useEffect(
    function () {
      let data = { ...params };
      setInterval(function () {
        if (data.filter_id !== localStorage.getItem("filter")) {
          data.filter_id = localStorage.getItem("filter");
          data.is_reload = !data.is_reload;
          setParams(data);
        }
      }, 1000);
    },
    [params]
  );

  useEffect(function () {
    actionCompanyList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setCompanyList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(
    function () {
      let data = params;
      getDriverList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setDriverList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      if (
        vehicleData.companyId &&
        JsonParse.user_role == ConfigDB.data.ROLE_ADMIN
      ) {
        const data = {
          companyId: vehicleData.companyId,
        };
        actionClassVehicle(data)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].vehicle_class,
              });
            }

            setVehicleClasssList(list);
          })
          .catch((err) => {});
      }
    },
    [vehicleData.companyId]
  );

  useEffect(() => {
    let data = params;
    actionClassVehicle(data)
      .then((response) => {
        response = response.data;
        let list = response.data.map((vehicle) => ({
          value: vehicle.id,
          label: vehicle.vehicle_class,
        }));
        setVehicleClassFilterList(list);
      })
      .catch((err) => {});
  }, [params]);

  useEffect(function () {
    if (JsonParse.user_role == ConfigDB.data.ROLE_COMPANY) {
      actionClassVehicle(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicle_class,
            });
          }

          setVehicleClasssList(list);
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(
    function () {
      let data = { ...params };
      setIsLoading(true);
      getVehicleList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setVehicleList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  useEffect(function () {
    getDepartmentAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setDepartmentList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getSegmentAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setSegementList(list);
      })
      .catch((err) => {});
  }, []);

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    const getValue = (e) => (e ? (e.target ? e.target.value : e.value) : null);
    if (type === "length") {
      paramsObj.limit = getValue(e);
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = getValue(e);
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "device_imei") {
      paramsObj.page = 1;
      paramsObj.device_imei = getValue(e);
    } else if (type === "vehicleClassId") {
      paramsObj.page = 1;
      paramsObj[type === "vehicleClassId" ? "vehicle_class" : ""] = getValue(e);
    }

    setParams(paramsObj);
  };

  const handleVehicleForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!vehicleData.vehicleName) {
      errorObj.vehicleName = `Vehicle Name required.`;
      error = true;
    }

    if (!/^\d+$/.test(vehicleData.modelYear)) {
      errorObj.modelYear = "Make model year must contain only numbers.";
      error = true;
    }

    if (!vehicleData.vehicleClass) {
      errorObj.vehicleClass = `Select vehicle class.`;
      error = true;
    }
    if (JsonParse.user_role == ConfigDB.data.ROLE_ADMIN) {
      if (!vehicleData.companyId) {
        errorObj.companyId = `Select Company is required`;
        error = true;
      }
    }

    if (!vehicleData.vehiclePlate) {
      errorObj.vehiclePlate = `Vehicle Plate required.`;
      error = true;
    }
    setErrors(errorObj);
    if (error) return;

    let data = {
      vehicleName: vehicleData.vehicleName,
      modelYear: vehicleData.modelYear,
      vehicleClass: vehicleData.vehicleClass,
      vehiclePlate: vehicleData.vehiclePlate,
      deviceImei: vehicleData.deviceImei,
      driverId: vehicleData.driverId,
      vehicle_model: vehicleData.vehicle_model,
      department_id: vehicleData.department_id,
      segment_id: vehicleData.segement_id,
      manufacturer: vehicleData.manufacturer,
    };

    if (JsonParse.user_role === ConfigDB.data.ROLE_ADMIN) {
      data.companyId = vehicleData.companyId;
    }

    let updateData = {
      vehicleName: vehicleData.vehicleName,
      modelYear: vehicleData.modelYear,
      vehicleClass: vehicleData.vehicleClass,
      vehiclePlate: vehicleData.vehiclePlate,
      deviceImei: vehicleData.deviceImei,
      uuid: vehicleDetails.uuid,
      companyId: vehicleData.companyId,
      driverId: vehicleData.driverId,
      vehicle_model: vehicleData.vehicle_model,
      department_id: vehicleData.department_id,
      segment_id: vehicleData.segement_id,
      manufacturer: vehicleData.manufacturer,
    };

    if (JsonParse.user_role === ConfigDB.data.ROLE_ADMIN) {
      updateData.companyId = vehicleData.companyId;
    }

    if (vehicleDetails?.uuid) {
      modifyVehicle(actionUpdateVehicle, updateData);
    } else {
      modifyVehicle(actionUpdateVehicle, data);
    }
  };

  const modifyVehicle = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("management");
        localStorage.setItem("vehicleId", response.data.data.id);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

const DriverIDs = DriverList.find(
  (option) => option.value === JSON.stringify(vehicleData.driverId)
)


  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleVehicleForm}>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Vehicle name"}
                        <span className={`text-danger ml-1`}>*</span>
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.vehicleName}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              vehicleName: e.target.value,
                            });
                          }}
                          name={`vehicleName`}
                          maxLength={50}
                          placeholder="Vehicle name"
                        />
                        {errors.vehicleName && (
                          <span className="input-error">
                            {errors.vehicleName}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Make Model Year"}
                        <span className={`text-danger ml-1`}>*</span>
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          placeholder="Make Model Year"
                          name={`modelYear`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              modelYear: e.target.value,
                            });
                          }}
                          // disabled={isApproveRejectLoad}
                          value={vehicleData.modelYear}
                          // onChange={(e) => setReason(e.target.value)}
                        ></input>
                        {errors.modelYear && (
                          <span className="input-error">
                            {errors.modelYear}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Manufacturer"}
                        <span className={`text-danger ml-1`}>*</span>
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          placeholder="Manufacturer"
                          name={`manufacturer`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              manufacturer: e.target.value,
                            });
                          }}
                          // disabled={isApproveRejectLoad}
                          value={vehicleData.manufacturer}
                          // onChange={(e) => setReason(e.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Vehcile Model"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.vehicle_model}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              vehicle_model: e.target.value,
                            });
                          }}
                          min={0}
                          name={`vehicle_model`}
                          maxLength={30}
                          placeholder="Vehcile Model"
                        />
                      </div>
                    </div>

                    {JsonParse.user_role == ConfigDB.data.ROLE_ADMIN ? (
                      <Label className="col-form-label">
                        {"Company List"}
                        <span className={`text-danger ml-1`}>*</span>
                      </Label>
                    ) : null}

                    {JsonParse.user_role == ConfigDB.data.ROLE_ADMIN ? (
                      <div className="mb-3">
                        <Select
                          options={companyList}
                          value={
                            companyList.find(
                              (option) => option.value === vehicleData.companyId
                            ) || selectedCompany
                          }
                          onChange={(selectedOption) =>
                            setVehicleData((prevData) => ({
                              ...prevData,
                              companyId: selectedOption.value,
                            }))
                          }
                          placeholder={"Select Company"}
                        />
                        {errors.companyId && (
                          <span className="input-error">
                            {errors.companyId}
                          </span>
                        )}
                      </div>
                    ) : null}
                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Vehicle Class"}
                        <span className={`text-danger ml-1`}>*</span>
                      </Label>
                      <div className="mb-3">
                        {/* <select
                  className="form-select"
                  aria-label="Default select example"
                  value={vehicleData.vehicleClass}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      vehicleClass: e.target.value,
                    });
                  }}
                >
                  <option value="">Select Vehicle Class</option>
                  {vehicleClasssList?.length > 0 &&
                    vehicleClasssList.map((item, index) => {
                      {
                      }
                      return (
                        <option key={index} value={item.value}>
                          {item?.label}
                        </option>
                      );
                    })}
                </select> */}

                        <Select
                          options={vehicleClasssList}
                          value={
                            vehicleClasssList.find(
                              (option) =>
                                option.value === vehicleData.vehicleClass
                            ) || selectedClass
                          }
                          onChange={(selectedOption) =>
                            setVehicleData((prevData) => ({
                              ...prevData,
                              vehicleClass: selectedOption.value,
                            }))
                          }
                          placeholder={"Select vehicle class"}
                        />
                        {errors.vehicleClass && (
                          <span className="input-error">
                            {errors.vehicleClass}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Vehicle Plate"}
                        <span className={`text-danger ml-1`}>*</span>
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.vehiclePlate}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              vehiclePlate: e.target.value,
                            });
                          }}
                          min={0}
                          name={`vehiclePlate`}
                          maxLength={50}
                          placeholder="Vehicle Plate"
                        />
                        {errors.vehiclePlate && (
                          <span className="input-error">
                            {errors.vehiclePlate}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Device IMEI"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.deviceImei}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              deviceImei: e.target.value,
                            });
                          }}
                          min={0}
                          name={`deviceImei`}
                          maxLength={15}
                          placeholder="Device IMEI"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Driver"}
                        <span className={`text-danger ml-1`}>*</span>
                      </Label>
                      <div className="input-div">
                        <Select
                          options={DriverList}
                          value={DriverList.find(
                            (option) => option.value === vehicleData.driverId
                          )}
                          onChange={(selectedOption) =>
                            setVehicleData({
                              ...vehicleData,
                              driverId: selectedOption.value,
                            })
                          }
                          placeholder={"Select Driver"}
                        />
                        {errors.driver && (
                          <span className="input-error">{errors.driver}</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Department"}</Label>
                      <div className="input-div">
                        <Select
                          options={departmentList}
                          value={departmentList.find(
                            (option) =>
                              option.value === vehicleData.department_id
                          )}
                          onChange={(selectedOption) =>
                            setVehicleData({
                              ...vehicleData,
                              department_id: selectedOption.value,
                            })
                          }
                          placeholder={"Select Department"}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Segments"}</Label>
                      <Select
                        options={segementList}
                        value={segementList.find(
                          (option) => option.value === vehicleData.segement_id
                        )}
                        onChange={(selectedOption) =>
                          setVehicleData({
                            ...vehicleData,
                            segement_id: selectedOption.value,
                          })
                        }
                        placeholder={"Select Segments"}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default General;
