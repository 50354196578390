import React, {useEffect, useState} from "react";
import {CardElement, useStripe, useElements} from "@stripe/react-stripe-js";
import VisaImg from "../assets/images/visa.png";
import MasterImg from "../assets/images/mastercard.png";
import {toast} from "react-toastify";
import {actionBuySubscription, actionSubscriptionSucceeded} from "../services/CompanyService";
import Utils from "./index";
import {useDispatch, useSelector} from "react-redux";
import {subscriptionPlanActions} from "../store";

function Checkout() {
    const auth = useSelector(x => x.auth.value);
    const planId = useSelector(x => x.subscriptionPlan.value);
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!planId) {
            window.location.href = "/dashboard";
        }
    }, [planId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            if (!elements || !stripe) return;
            const cardElement = elements.getElement(CardElement);
            const {error, paymentMethod} = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
            });

            if (error) {
                throw error.message
            }

            if (paymentMethod) {
                let paymentData = {};
                await actionBuySubscription({plan_id: planId, payment_token: paymentMethod.id})
                    .then(response => {
                        paymentData = response?.data;
                    }).catch(error => {
                        throw error.message;
                    });

                let succeededData = {
                    stripe_subscription_id: paymentData.stripe_subscription_id,
                    plan_id: planId
                };
                let isSucceeded = true;
                if (paymentData?.actionRequired) {
                    isSucceeded = false;
                    const {paymentIntent, error} = await stripe.confirmCardPayment(paymentData?.clientSecret);
                    if (error) throw error.message;
                    if (paymentIntent.status === "succeeded") {
                        isSucceeded = true;
                    } else {
                        throw paymentIntent.status;
                    }
                }

                if (isSucceeded) {
                    await actionSubscriptionSucceeded(succeededData)
                        .then(response => {
                            let responseData = response.data;
                            // let userData = {...auth, subscription_data: {}};
                            let userData = {...auth, subscription_data: responseData.subscription_data};
                            Utils.setDetail(userData);
                            toast.success(responseData.message);
                            dispatch(subscriptionPlanActions.subscriptionPlan(0));
                            window.location.href = "/dashboard";
                        }).catch(error => {
                            throw Utils.getErrorMessage(error);
                        });
                }
            }
        } catch (error) {
            toast.error(error);
        }
        setIsLoading(false);
    };

    return (
        <div className="container">
            <div className="row justify-content-sm-center">
                <div className="col-xl-6">
                    <div className="card-details">
                        <ul>
                            <li><a><img src={VisaImg} alt="visa"/></a></li>
                            <li><a><img src={MasterImg} alt=""/></a></li>
                        </ul>
                        <form method={'post'} onSubmit={handleSubmit}>
                            <div className="card-details-form">
                                <div className="row">
                                    <CardElement disabled={isLoading}
                                                 options={{
                                                     style: {
                                                         base: {
                                                             fontSize: "16px",
                                                             color: "#424770",
                                                             "::placeholder": {
                                                                 color: "#aab7c4",
                                                             },
                                                         },
                                                         invalid: {
                                                             color: "#9e2146",
                                                         },
                                                     },
                                                 }}
                                    />

                                    {!isLoading && (
                                        <button type="submit" className="btn card-form-button">Pay</button>
                                    )}
                                    {isLoading && (
                                        <button disabled={true} className={`btn card-form-button btn-secondary`}>
                                            <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Checkout;