import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {useSelector} from "react-redux";

import Header from "../partials/Header";
// import Sidebar from "../partials/Sidebar";
import SubscriptionPopup from "../subscription/SubscriptionPopup";


function DashboardLayout() {
    let subscription = useSelector(x => x.subscription.value);
    let pathName = window.location.pathname;
    const [showSubscription, setShowSubscription] = useState(false);

    useEffect(() => {
        setShowSubscription(!subscription && pathName !== '/payment');
    }, [pathName, subscription]);
    return (
        <>
            {showSubscription && <SubscriptionPopup/>}
            <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <Header/>
                <div className="app-main">
                    <div className="app-sidebar">
                        {/* <Sidebar/> */}
                        <div className="home-section">
                            <Outlet/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardLayout;