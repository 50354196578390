import axiosInstance from "./AxiosInstance";

export function actionAddCompanyProvide(data) {
    return axiosInstance.post(`/company-provider/create-update`, data);
}

export function getCompanyProvideList(data) {
    return axiosInstance.post(`/company-provider/list`, data);
}

export function actionDelCompanyProvide(data) {
    return axiosInstance.post(`/company-provider/delete`, data);
}

export function getCompanyProvideDetails(data) {
    return axiosInstance.post(`/company-provider/detail`, data);
}

export function getCompanyTokenRestore(data) {
    return axiosInstance.post(`/company-provider/restore-token`, data);
}
