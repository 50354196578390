import React, { useState, useEffect, useCallback, useRef } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";
import configDb from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus-i.svg";
import { actionDelPlace, getPlacesList } from "../../services/PlaceService";
import PlaceMap from "../map/PlaceMap";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import ConfigDB from "../../config";
import { actionAddUpdatePlace } from "../../services/PlaceService";
import { useLocation, useParams } from "react-router-dom";
import { actionCompanyList } from "../../services/Common";
import Select from "react-select";
import { Input, ListGroup, ListGroupItem } from "reactstrap";
import {
  actionAddUpdateRoutepath,
  actionDelRoutepath,
  getRoutepathList,
} from "../../services/RoutePathService";

const tableLengthList = configDb.data.dataTableLength;

const containerStyle = {
  width: "100%",
  height: "400px",
};
const libraries = ["places", "drawing"];

const center = {
  lat: 23.090312095942565,
  lng: 72.53448486328125,
  placeName: "Ahemdabad",
};

const RoutePath = () => {
  const auth = useSelector((x) => x.auth.value);
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [placesList, setPlacesList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [modifyDetailsModal, setModifyDetailsModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [delPlace, setDelPlace] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [placeData, setPlaceData] = useState({
    fromlatitude: "",
    fromlongitude: "",
    destinationlatitude: "",
    destinationlongitude: "",
    place: "",
    companyId: "",
    uuid: "",
    fromPlace: "",
    destinationPlace: "",
  });
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let errorsObj = { companyId: "", fromPlace: "", destinationPlace: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [markers, setMarkers] = useState([]);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });
  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(center);
  const [destinationmarkerPosition, seDestinationtMarkerPosition] =
    useState(center);
  const [fromMarkerPosition, setFromMarkerPosition] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [routePathList, setRoutePathList] = useState([]);
  const [directions, setDirections] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: ConfigDB.data.GOOGLE_MAP_KEY,
    libraries,
  });

  const navigate = useNavigate();

  const onMapClick = useCallback(
    async (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
      );
      const data = await response.json();
      const placeName = data.results[0]?.formatted_address || "";

      // Determine whether the click is for the "From" or "Destination" place
      if (!placeData.fromPlace) {
        setPlaceData((prevData) => ({
          ...prevData,
          fromlatitude: lat,
          fromlongitude: lng,
          fromPlace: placeName,
        }));
      } else if (!placeData.destinationPlace) {
        setPlaceData((prevData) => ({
          ...prevData,
          destinationlatitude: lat,
          destinationlongitude: lng,
          destinationPlace: placeName,
        }));
      }
    },
    [placeData]
  );

  const mapRef = useRef();

  const onMapLoad = useCallback(
    (map) => {
      mapRef.current = map;
      setMap(map);

      if (placeData.fromlatitude && placeData.fromlongitude) {
        setMarkerPosition({
          lat: parseFloat(placeData.fromlatitude),
          lng: parseFloat(placeData.fromlongitude),
          placeName: placeData.fromPlace,
        });
      }

      if (placeData.destinationlatitude && placeData.destinationlongitude) {
        setMarkerPosition({
          lat: parseFloat(placeData.destinationlatitude),
          lng: parseFloat(placeData.destinationlongitude),
          placeName: placeData.destinationPlace,
        });
      }
    },
    [placeData]
  );

  //   const calculateDirections = useCallback(() => {
  //     const {
  //       fromlatitude,
  //       fromlongitude,
  //       destinationlatitude,
  //       destinationlongitude,
  //     } = placeData;

  //     if (
  //       fromlatitude &&
  //       fromlongitude &&
  //       destinationlatitude &&
  //       destinationlongitude
  //     ) {
  //       const origin = new window.google.maps.LatLng(fromlatitude, fromlongitude);
  //       const destination = new window.google.maps.LatLng(
  //         destinationlatitude,
  //         destinationlongitude
  //       );

  //       const directionsService = new window.google.maps.DirectionsService();

  //       directionsService.route(
  //         {
  //           origin,
  //           destination,
  //           travelMode: window.google.maps.TravelMode.DRIVING,
  //         },
  //         (result, status) => {
  //           if (status === window.google.maps.DirectionsStatus.OK) {
  //             setDirections(result); // Update directions state
  //           } else {
  //             console.error("Directions request failed due to " + status);
  //           }
  //         }
  //       );
  //     }
  //   }, [placeData]);

  //   useEffect(() => {
  //     calculateDirections(); // Call calculateDirections when placeData changes
  //   }, [placeData, calculateDirections]);

  const onMarkerDragEnd = useCallback(async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
    );
    const data = await response.json();
    const placeName = data.results[0]?.formatted_address || "";
    setMarkerPosition({ lat, lng, placeName });
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const selectedCompany = companyList.find(
    (item) => item.value == placeData.companyId
  );

  const selectedFromPlace = placesList.find(
    (item) => item.value == placeData.fromPlace
  );

  const selectedDestinationPlace = placesList.find(
    (item) => item.value == placeData.destinationPlace
  );

  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);

  useEffect(function () {
    actionCompanyList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setCompanyList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(
    function () {
      let data = params;

      getPlacesList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].placeName,
              latitude: response.data[i].latitude,
              longitude: response.data[i].longitude,
            });
          }
          setPlacesList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getRoutepathList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setRoutePathList(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [params]
  );

  const initialZoom = 16;

  useEffect(() => {
    if (
      selectedFromPlace &&
      selectedFromPlace.latitude &&
      selectedFromPlace.longitude
    ) {
      const { latitude, longitude } = selectedFromPlace;
      setFromMarkerPosition({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      });
    }
  }, [selectedFromPlace]);

  useEffect(() => {
    if (
      selectedDestinationPlace &&
      selectedDestinationPlace.latitude &&
      selectedDestinationPlace.longitude
    ) {
      const { latitude, longitude } = selectedDestinationPlace;
      seDestinationtMarkerPosition({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      });
    }
  }, [selectedDestinationPlace]);

  useEffect(() => {
    if (mapRef.current && fromMarkerPosition && destinationmarkerPosition) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(fromMarkerPosition);
      bounds.extend(destinationmarkerPosition);
      mapRef.current.fitBounds(bounds);
    }
  }, [fromMarkerPosition, destinationmarkerPosition]);

  const handleonFormSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };

    if (JsonParse.user_role == ConfigDB.data.ROLE_ADMIN) {
      if (!placeData.companyId) {
        errorObj.companyId = `Select Company is required`;
        error = true;
      }
    }

    if (!placeData.fromPlace) {
      errorObj.fromPlace = `Select fromplace is required`;
      error = true;
    }

    if (!placeData.destinationPlace) {
      errorObj.destinationPlace = `Select destinationplace is required`;
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    let data = {
      fromPlace: placeData.fromPlace,
      destinationPlace: placeData.destinationPlace,
      companyId: placeData.companyId,
    };

    if (JsonParse.user_role == ConfigDB.data.ROLE_ADMIN) {
      data.companyId = placeData.companyId;
    }

    let updateData = {
      fromPlace: placeData.fromPlace,
      destinationPlace: placeData.destinationPlace,
      companyId: placeData.companyId,
      uuid: placeData?.uuid,
    };

    if (JsonParse.user_role == ConfigDB.data.ROLE_ADMIN) {
      updateData.companyId = placeData.companyId;
    }

    if (placeData?.uuid) {
      modifyPlace(actionAddUpdateRoutepath, updateData);
    } else {
      modifyPlace(actionAddUpdateRoutepath, data);
    }
  };

  const modifyPlace = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setPlaceData({
          fromPlace: "",
          destinationPlace: "",
          companyId: "",
        });
        setModifyModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };
  const onDeletePlace = (e, item) => {
    e.preventDefault();
    setDelPlace(item.uuid);
    setDelModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelPlace("");
    setDelModal(false);
  };

  const onDestroyPlace = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delPlace };
    actionDelRoutepath(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelPlace("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const onAddPlace = (e) => {
    e.preventDefault();
    setModifyModal(true);
  };

  const onEditPlace = (e, item) => {
    e.preventDefault();
    setPlaceData({
      fromPlace: item.from_place_id,
      destinationPlace: item.destination_place_id,
      companyId: item.companyId,
      uuid: item.uuid,
    });
    setModifyModal(true);
  };

  const onModifyModalClose = (e) => {
    setPlaceData({
      fromPlace: "",
      destinationPlace: "",
      companyId: "",
      uuid: "",
    });
    setModifyModal(false);
    setErrors(errorsObj);
  };

  const onDetailsPlace = (e, item) => {
    e.preventDefault();
    setPlaceData({
      fromPlace: item.from_place_id,
      destinationPlace: item.destination_place_id,
      companyId: item.companyId,
      uuid: item.uuid,
      fromPlace: item.from_place_name,
      destinationPlace: item.destination_place_name,
    });
    setModifyDetailsModal(true);
  };

  const onModifyDetailsModalClose = (e) => {
    setModifyDetailsModal(false);
  };

  return (
    <>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Route-Path List</h5>
        </div>

        <div className="right-block w-auto">
          {allPermissionsList.findIndex((e) => e.name == "modify_route_path") >
            -1 && (
            <Button
              type={`button`}
              onClick={onAddPlace}
              className={`add-btn btn float-right`}
            >
              {`Add Route-Path`}
              <i className="plus-circle">
                <img src={plusIcon} alt="" className="plus-icons" />
              </i>
            </Button>
          )}
        </div>
      </div>

      <div className="container-event">
        <div className="page-header-row">
          <div className="row">
            <div className="col">
              <div className="row d-flex justify-content-between company-section">
                <div className="col-xl-2">
                  <div className="selected-block">
                    <p>Show</p>
                    <select
                      className="form-select"
                      aria-label="Events Only"
                      defaultValue={params.limit}
                      onChange={(e) => handleParams(e, "length")}
                    >
                      {tableLengthList &&
                        tableLengthList.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div className="search-bar">
                    <i className="bi bi-search"></i>
                    <input
                      type="text"
                      className="form-control"
                      value={params.search}
                      onChange={(e) => handleParams(e, "search")}
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-table-row">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope={`col`} width="5%">
                  #
                </th>
                <th scope={`col`}>{`From Place Name`}</th>
                <th scope={`col`}>{`Destination Place Name`}</th>
                {(allPermissionsList.findIndex(
                  (e) => e.name == "modify_route_path"
                ) > -1 ||
                  allPermissionsList.findIndex(
                    (e) => e.name == "delete_route_path"
                  ) > -1) && <th scope={`col`} width="20%">{`Action`}</th>}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {routePathList &&
                    routePathList?.data?.map((item, i) => (
                      <tr key={i}>
                        <td>{item.index}</td>
                        <td
                          onClick={(e) => onDetailsPlace(e, item)}
                          style={{ cursor: "pointer" }}
                        >
                          {item.from_place_name}
                        </td>
                        <td
                          onClick={(e) => onDetailsPlace(e, item)}
                          style={{ cursor: "pointer" }}
                        >
                          {item.destination_place_name}
                        </td>
                        {(allPermissionsList.findIndex(
                          (e) => e.name == "modify_route_path"
                        ) > -1 ||
                          allPermissionsList.findIndex(
                            (e) => e.name == "delete_route_path"
                          ) > -1) && (
                          <td>
                            <>
                              {allPermissionsList.findIndex(
                                (e) => e.name == "modify_route_path"
                              ) > -1 && (
                                <Button
                                  type={`button`}
                                  onClick={(e) => onEditPlace(e, item)}
                                  className={`btn btn-info add-btn-circle me-2`}
                                >
                                  <i className="bi bi-pencil-fill"></i>
                                </Button>
                              )}
                            </>
                            {allPermissionsList.findIndex(
                              (e) => e.name == "delete_route_path"
                            ) > -1 && (
                              <Button
                                type={`button`}
                                onClick={(e) => onDeletePlace(e, item)}
                                className={`btn btn-danger add-btn-circle btn-square me-2`}
                              >
                                <i className="bi bi-trash-fill"></i>
                              </Button>
                            )}
                            <button
                              type={`button`}
                              onClick={(e) => onDetailsPlace(e, item)}
                              className={`btn btn-success add-btn-circle me-2`}
                            >
                              <i className="bi bi-eye-fill" />
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                </>
              )}

              {routePathList && routePathList?.data?.length === 0 && (
                <tr>
                  <td
                    colSpan={
                      allPermissionsList.findIndex(
                        (e) => e.name == "modify_route_path"
                      ) > -1 ||
                      allPermissionsList.findIndex(
                        (e) => e.name == "delete_route_path"
                      ) > -1
                        ? 4
                        : 2
                    }
                    className={`text-center`}
                  >{`Record Not Found`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className={`float-right`}>
          <Pagination
            activePage={params.page}
            itemsCountPerPage={parseInt(params.limit)}
            totalItemsCount={parseInt(totalRecords)}
            pageRangeDisplayed={5}
            itemClass={`page-item`}
            linkClass={`page-link`}
            onChange={(e) => handleParams(e, "pagination")}
          />
        </div>
      </div>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Route-Path`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this route-path?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroyPlace}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>
          {placeData.uuid ? `Edit Route-Path` : `Create Route-Path`}
        </ModalHeader>
        <Form method={`post`} onSubmit={handleonFormSubmit}>
          <ModalBody>
            {JsonParse.user_role == ConfigDB.data.ROLE_ADMIN ? (
              <div className="col-xl-4" style={{ width: "100%" }}>
                <div className="mb-3">
                  <Select
                    options={companyList}
                    value={
                      companyList.find(
                        (option) => option.value === placeData.companyId
                      ) || selectedCompany
                    }
                    onChange={(selectedOption) =>
                      setPlaceData((prevData) => ({
                        ...prevData,
                        companyId: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Company"}
                  />
                  {errors.companyId && (
                    <span className="input-error">{errors.companyId}</span>
                  )}
                </div>
              </div>
            ) : null}

            <div className="mb-3">
              <Select
                options={placesList}
                value={
                  placesList?.find(
                    (option) => option.value === placeData.fromPlace
                  ) || selectedFromPlace
                }
                onChange={(selectedOption) =>
                  setPlaceData((prevData) => ({
                    ...prevData,
                    fromPlace: selectedOption.value,
                    fromlatitude: selectedOption.latitude,
                    fromlongitude: selectedOption.longitude,
                  }))
                }
                placeholder={"Select From Place"}
              />
              {errors.fromPlace && (
                <span className="input-error">{errors.fromPlace}</span>
              )}
            </div>

            <div className="mb-3">
              <Select
                options={placesList}
                value={
                  placesList?.find(
                    (option) => option.value === placeData.destinationPlace
                  ) || selectedDestinationPlace
                }
                onChange={(selectedOption) =>
                  setPlaceData((prevData) => ({
                    ...prevData,
                    destinationPlace: selectedOption.value,
                    destinationlatitude: selectedOption.latitude,
                    destinationlongitude: selectedOption.longitude,
                  }))
                }
                placeholder={"Select Destination Place"}
              />
              {errors.destinationPlace && (
                <span className="input-error">{errors.destinationPlace}</span>
              )}
            </div>
          </ModalBody>

          <div
            style={{
              marginBottom: "16px",
              padding: "13px",
              marginTop: "-18px",
            }}
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={
                fromMarkerPosition ||
                destinationmarkerPosition || { lat: 0, lng: 0 }
              }
              zoom={10}
              onLoad={onMapLoad}
              onClick={onMapClick}
              onUnmount={onUnmount}
            >
              {placeData.fromlatitude && placeData.fromlongitude && (
                <Marker
                  position={{
                    lat: parseFloat(placeData.fromlatitude),
                    lng: parseFloat(placeData.fromlongitude),
                  }}
                  draggable={true}
                  onDragEnd={onMarkerDragEnd}
                />
              )}

              {fromMarkerPosition && (
                <Marker
                  position={fromMarkerPosition}
                  draggable={true}
                  onDragEnd={onMarkerDragEnd}
                />
              )}

              {destinationmarkerPosition && (
                <Marker
                  position={destinationmarkerPosition}
                  draggable={true}
                  onDragEnd={onMarkerDragEnd}
                />
              )}

              {placeData.destinationlatitude &&
                placeData.destinationlongitude && (
                  <Marker
                    position={{
                      lat: parseFloat(placeData.destinationlatitude),
                      lng: parseFloat(placeData.destinationlongitude),
                    }}
                    draggable={true}
                    onDragEnd={onMarkerDragEnd}
                  />
                )}
            </GoogleMap>

            {/* <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={initialZoom}
              onLoad={onMapLoad}
            >
              {directions && (
                <DirectionsRenderer
                  directions={directions}
                  options={{ polylineOptions: { strokeColor: "red" } }}
                />
              )}
            </GoogleMap> */}
          </div>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modifyDetailsModal}>
        <ModalHeader>Route-path Details</ModalHeader>
        <Form method={`post`} onSubmit={handleonFormSubmit}>
          <ModalBody>
            <div style={{ fontSize: "15px", fontWeight: "700" }}>
              SourcePlaceName:
              <span style={{ color: "gray" }}> {placeData.fromPlace}</span>
            </div>
            <div style={{ fontSize: "15px", fontWeight: "700" }}>
              DestinationPlaceName:
              <span style={{ color: "gray" }}>
                {" "}
                {placeData.destinationPlace}
              </span>
            </div>
          </ModalBody>

          <div
            style={{
              marginBottom: "16px",
              padding: "13px",
              marginTop: "-18px",
            }}
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={
                fromMarkerPosition ||
                destinationmarkerPosition || { lat: 0, lng: 0 }
              }
              zoom={10}
              onLoad={onMapLoad}
              onClick={onMapClick}
              onUnmount={onUnmount}
            >
              {placeData.fromlatitude && placeData.fromlongitude && (
                <Marker
                  position={{
                    lat: parseFloat(placeData.fromlatitude),
                    lng: parseFloat(placeData.fromlongitude),
                  }}
                  draggable={false}
                  onDragEnd={onMarkerDragEnd}
                />
              )}

              {fromMarkerPosition && (
                <Marker
                  position={fromMarkerPosition}
                  draggable={false}
                  onDragEnd={onMarkerDragEnd}
                />
              )}

              {destinationmarkerPosition && (
                <Marker
                  position={destinationmarkerPosition}
                  draggable={false}
                  onDragEnd={onMarkerDragEnd}
                />
              )}

              {placeData.destinationlatitude &&
                placeData.destinationlongitude && (
                  <Marker
                    position={{
                      lat: parseFloat(placeData.destinationlatitude),
                      lng: parseFloat(placeData.destinationlongitude),
                    }}
                    draggable={false}
                    onDragEnd={onMarkerDragEnd}
                  />
                )}
            </GoogleMap>
          </div>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyDetailsModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default RoutePath;
