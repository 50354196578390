import axiosInstance from "./AxiosInstance";

export function actionUpdateVehicleClass(data) {
    return axiosInstance.post(`/vehicle-class/create-update`, data);
}

export function getVehicleClassList(data) {
    return axiosInstance.post(`/vehicle-class/list`, data);
}

// export function getVehicleClassDetail(data) {
//     return axiosInstance.post(`/vehicle/detail`, data);
// }

export function actionDelVehicleClass(data) {
    return axiosInstance.post(`/vehicle-class/delete`, data);
}
