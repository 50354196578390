import axiosInstance from "./AxiosInstance";

export function actionCountryList(data) {
  return axiosInstance.post(`/country`, data);
}

export function actionStateList(data) {
  return axiosInstance.post(`/country/state`, data);
}

export function actionTimeZoneList(data) {
  return axiosInstance.post(`/country/timezone`, data);
}

export function actionTimeAllZoneList(data) {
  return axiosInstance.post(`/timezone-all-list`, data);
}

export function actionLanguageList(data) {
  return axiosInstance.post(`/language`, data);
}

export function actionCompanyList(data) {
  return axiosInstance.post(`/company-list`, data);
}

export function getLanguageListAll() {
  return axiosInstance.post(`language`);
}
