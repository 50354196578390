import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";

import configDb from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import {
  actionDelCompany,
  getCompanyList,
} from "../../services/CompanyService";
import { actionOrderList, getTripList } from "../../services/TripService";
import {
  actionAddVehicleTask,
  createVehicleMaintance,
  getVehicleTaskHistoryList,
  getVehicleTaskList,
} from "../../services/Maintanance";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-time-picker";
import moment from "moment";
import { getVehicleList } from "../../services/VehicleService";
import Select from "react-select";
import { getAllVehicleTaskList } from "../../services/AlarmNotificationService";
import plusIcon from "../../assets/images/plus-i.svg";

const tableLengthList = configDb.data.dataTableLength;

function VehicleTask(props) {
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [tripList, setTripList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [delModal, setDelModal] = useState(false);
  const [delCompany, setDelCompany] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modifyModal, setModifyModal] = useState(false);
  const [modifyTaskModal, setModifyTaskModal] = useState(false);
  const [task, setTask] = useState("");

  const [ItemList, setItemList] = useState({});
  const [vehicleClassData, setVehicleClassData] = useState({
    uuid: "",
    task_name: "",
    maintainance_km: "",
    sub_task: "",
    isCommon: false,
  });
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [dates, setDates] = useState("");
  const [time, setTime] = useState("12:00");
  const [vehicleHistory, setVehicleHistory] = useState(false);
  const [vehicleHistoryList, setVehicleHistoryList] = useState({});

  const [vehicleAllHistoryList, setVehicleAllHistoryList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [vehicle, setVehicle] = useState("");
  const [vehicleTaskList, setVehicleTaskList] = useState([]);
  const [taskType, setTaskType] = useState("");
  const [vehicleData, setVehicleData] = useState({
    uuid: "",
    task_id: "",
    vehicle_id: "",
    kilometer_at: "",
  });

  const handleVehicleHistory = (item) => {
    setVehicleHistory(true);
    setVehicleHistoryList(item);
  };

  const handleVehicleHistoryClose = () => {
    setVehicleHistory(false);
  };

  const handleChange = (newTime) => {
    setTime(newTime);
  };

  // Function to handle changes to the activity fields
  const handleActivityChange = (index, value) => {
    const newActivities = [...vehicleData.activities];
    newActivities[index] = value;
    setVehicleData({
      ...vehicleData,
      activities: newActivities,
    });
  };

  // Function to add a new activity input field
  const addActivityField = () => {
    setVehicleData({
      ...vehicleData,
      activities: [...vehicleData.activities, ""],
    });
  };

  // Function to remove an activity input field
  const removeActivityField = (index) => {
    const newActivities = vehicleData.activities.filter((_, i) => i !== index);
    setVehicleData({
      ...vehicleData,
      activities: newActivities,
    });
  };

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    schedule_date: "",
    schedule_time: "",
    vehicleId: vehicle.vehicleId,
    task_id: taskType.task_id,
  });
  const navigate = useNavigate();

  const handleChangeDate = (date) => {
    setDates(date);
  };

  const onVehicleTask = () => {
    setVehicleClassData({ uuid: "", name: "" });
    setModifyModal(true);
  };

  const handleVehicleClose = () => {
    setModifyModal(false);
  };

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getVehicleTaskList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setTripList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      getAllVehicleTaskList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].task_name,
            });
          }

          setVehicleTaskList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(() => {
    let data = params;
    getVehicleList(data)
      .then((response) => {
        response = response.data;
        let list = response.data.map((vehicle) => ({
          value: vehicle.id,
          label: vehicle.vehicleName,
        }));
        setVehicleList(list);
      })
      .catch((err) => {});
  }, [params]);

  useEffect(() => {
    if (vehicleHistoryList.id) {
      let data = {
        vehicle_task_id: vehicleHistoryList.id,
      };
      setIsLoading(true);
      getVehicleTaskHistoryList(data)
        .then((response) => {
          setVehicleAllHistoryList(response.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [vehicleHistoryList.id]);

  const handleParams = (e, type) => {
    let paramsObj = { ...params };

    const getValue = (e) => (e ? (e.target ? e.target.value : e.value) : null);

    if (type === "length") {
      paramsObj.limit = getValue(e);
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = getValue(e);
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "schedule_date") {
      paramsObj.page = 1;
      paramsObj.schedule_date = getValue(e);
    } else if (type === "vehicle" || type === "task_type") {
      paramsObj.page = 1;
      paramsObj[type === "vehicle" ? "vehicleId" : "task_id"] = getValue(e);
    } else if (type === "schedule_time") {
      paramsObj.page = 1;
      paramsObj.schedule_time = getValue(e);
    }

    setParams(paramsObj);
  };
  const onDeleteCompany = (e, item) => {
    e.preventDefault();
    setDelCompany(item.uuid);
    setDelModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelCompany("");
    setDelModal(false);
  };

  const onDestroyCompany = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delCompany };
    actionDelCompany(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelCompany("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const ItemComponent = ({ item }) => {
    let badgeColor = "";
    let displayStatus = item.status;

    if (item.status === 0) {
      displayStatus = "Pending";
      badgeColor = "bg-warning";
    } else if (item.status === 1) {
      displayStatus = "Scheduled";
      badgeColor = "bg-success";
    } else if (item.status === 2) {
      displayStatus = "Completed";
      badgeColor = "bg-success";
    }

    return (
      <span className={`badge rounded-pill btn-warning ${badgeColor}`}>
        {displayStatus}
      </span>
    );
  };

  const date = new Date(dates);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const handleVehicleClassForm = (e) => {
    e.preventDefault();
    let dataParams = { ...params };

    setIsModifyButtonLoad(true);
    let data = {
      schedule_date: formattedDate,
      schedule_time: time,
      id: ItemList.id,
      vehicle_id: ItemList.vehicle_id,
      company_id: ItemList.company_id,
      task_id: ItemList.task_id,
      kilometer_at: ItemList.kilometer_at,
      status: 1,
    };

    actionAddVehicleTask(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setModifyModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const handleItemClick = (item) => {
    onVehicleTask();
    setItemList(item);
  };

  const handleTaskOpen = () => {
    setModifyTaskModal(true);
  };

  const handleTaskClose = () => {
    setModifyTaskModal(false);
  };

  const handleSubmitTaskForm = (e) => {
    e.preventDefault();
    let dataParams = { ...params };

    setIsModifyButtonLoad(true);
    let data = {
      vehicle_id: vehicle.vehicleId,
      task_id: task.taskId,
      kilometer_at: vehicleData.kilometer_at,
    };

    createVehicleMaintance(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setModifyTaskModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  return (
    <>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Task Management List</h5>
        </div>
        <div className="right-block w-auto">
          <Button
            type={`button`}
            onClick={handleTaskOpen}
            className={`add-btn btn  float-right`}
          >
            {`Add Maintenance`}
            <i className="plus-circle">
              <img src={plusIcon} alt="" className="plus-icons" />
            </i>
          </Button>
        </div>
      </div>

      <div className="container-event">
        <div className="page-header-row page-header-task-vehical">
          <div className="row">
            <div className="col-xl-12">
              <div className="row d-flex justify-content-between">
                <div className="col">
                  <div className="selected-block selected-mobile">
                    <p>Show</p>
                    <select
                      className="form-select"
                      aria-label="Events Only"
                      defaultValue={params.limit}
                      onChange={(e) => handleParams(e, "length")}
                    >
                      {tableLengthList &&
                        tableLengthList.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="col">
                  <div className="selected-block selected-mobile">
                    <p>Date</p>
                    <div className="input-group date">
                      <input
                        type="date"
                        className="form-control form-control-date"
                        placeholder="Today"
                        value={params.schedule_date}
                        onChange={(e) => handleParams(e, "schedule_date")}
                      />
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="selected-block selected-mobile">
                    <p>Time</p>
                    <div className="input-group time">
                      <input
                        type="time"
                        className="form-control"
                        value={params.schedule_time}
                        onChange={(e) => handleParams(e, "schedule_time")}
                        placeholder="Time"
                        style={{ marginBottom: "-15px", marginLeft: "10px" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="selected-block selected-mobile">
                    <p>Vehicle</p>
                    <div style={{ width: "100%" }}>
                      <Select
                        options={vehicleList}
                        value={vehicleList?.find(
                          (option) => option.value === params.vehicleId
                        )}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            setVehicle((prevData) => ({
                              ...prevData,
                              vehicleId: selectedOption.value,
                            }));
                            handleParams(selectedOption, "vehicle");
                          } else {
                            setVehicle((prevData) => ({
                              ...prevData,
                              vehicleId: null,
                            }));
                            handleParams(null, "vehicle");
                          }
                        }}
                        placeholder="Select Vehicle"
                        isClearable={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="selected-block selected-mobile">
                    <p>Maintainence Type</p>
                    <div style={{ width: "100%" }}>
                      <Select
                        options={vehicleTaskList}
                        value={vehicleTaskList?.find(
                          (option) => option.value === params.task_id
                        )}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            setTaskType((prevData) => ({
                              ...prevData,
                              task_id: selectedOption.value,
                            }));
                            handleParams(selectedOption, "task_type");
                          } else {
                            setTaskType((prevData) => ({
                              ...prevData,
                              task_id: null,
                            }));
                            handleParams(null, "task_type");
                          }
                        }}
                        placeholder="Select Vehicle"
                        isClearable={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="selected-block selected-mobile">
                    <p>Search</p>
                    <div className="search-bar">
                      <i className="bi bi-search"></i>
                      <input
                        type="text"
                        className="form-control"
                        value={params.search}
                        onChange={(e) => handleParams(e, "search")}
                        placeholder="Search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-table-row">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope={`col`}>{`Vehicle Name`}</th>
                <th scope={`col`}>{`Task Name`}</th>
                <th scope={`col`}>{`Kilometer At`}</th>
                <th scope={`col`}>{`Schedule Date`}</th>
                <th scope={`col`}>{`Status`}</th>
                {allPermissionsList.findIndex(
                  (e) => e.name == "view_details_trip"
                ) > -1 && <th scope={`col`}>{`Action`}</th>}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {tripList &&
                    tripList?.data?.map((item, i) => (
                      <tr key={i}>
                        <td style={{ cursor: "pointer" }}>
                          {item?.vehicle_name}
                        </td>
                        <td style={{ cursor: "pointer" }}>{item?.task_name}</td>
                        <td style={{ cursor: "pointer" }}>
                          {item?.kilometer_at}
                        </td>
                        <td style={{ cursor: "pointer" }}>
                          {item?.schedule_date || "-"}
                        </td>
                        <td>
                          <ItemComponent item={item} />
                        </td>
                        <td>
                          {parseInt(item.status) === 0 ? (
                            <NavLink
                              onClick={() => handleItemClick(item)}
                              role="button"
                              className="btn btn-success add-btn-circle me-2"
                            >
                              <i className="bi bi-plus"></i>
                            </NavLink>
                          ) : parseInt(item.status) === 2 ? (
                            <NavLink
                              onClick={() => handleVehicleHistory(item)}
                              role="button"
                              className="btn btn-success add-btn-circle me-2"
                            >
                              <i className="bi bi-eye"></i>
                            </NavLink>
                          ) : (
                            <>-</>
                          )}{" "}
                        </td>
                      </tr>
                    ))}
                </>
              )}

              {tripList && tripList?.data?.length === 0 && (
                <tr>
                  <td
                    colSpan={
                      allPermissionsList.findIndex(
                        (e) => e.name == "modify_trip"
                      ) > -1 ||
                      allPermissionsList.findIndex(
                        (e) => e.name == "delete_trip"
                      ) > -1
                        ? 6
                        : 7
                    }
                    className={`text-center`}
                  >{`Record Not Found`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={`float-right`}>
          <Pagination
            activePage={params.page}
            itemsCountPerPage={parseInt(params.limit)}
            totalItemsCount={parseInt(totalRecords)}
            pageRangeDisplayed={5}
            itemClass={`page-item`}
            linkClass={`page-link`}
            onChange={(e) => handleParams(e, "pagination")}
          />
        </div>
      </div>
      <Modal isOpen={delModal}>
        <ModalHeader>{`DeleteRole`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this company?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroyCompany}
            >{`Delete`}</button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Schedule</ModalHeader>
        <Form method={`post`} onSubmit={handleVehicleClassForm}>
          <ModalBody>
            <div className="row">
              <div className="col-sm-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Schedule Time"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <DatePicker
                    value={dates}
                    onChange={handleChangeDate}
                    sort
                    format="YYYY-MM-DD"
                    calendarPosition="bottom-center"
                    placeholder="Select Date"
                    style={{
                      border: "1px solid #CCCCCC",
                      borderRadius: "5px",
                      height: "40px",
                      margin: "1px 0",
                      padding: "2px 5px",
                      width: "100%",
                      paddingRight: "30px",
                    }}
                  />
                </FormGroup>
              </div>
              <div className="col-sm-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Select Time"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <TimePicker
                    onChange={handleChange}
                    value={time}
                    format={`hh:mm a`}
                    className={`react-picker form-control`}
                  />
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={handleVehicleClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={vehicleHistory}>
        <ModalHeader>Vehicle Task Maintainance History</ModalHeader>
        <Form method={`post`} onSubmit={handleVehicleClassForm}>
          <ModalBody>
            <div className="table-reponsive" style={{ padding: "0" }}>
              <table
                className="table tripdetails-table tripdetails-table-2"
                style={{
                  marginBottom: "33px",
                  marginTop: "15px",
                  border: "solid #47cbf2 1px;",
                }}
              >
                <thead>
                  <tr>
                    <th scope="col" style={{ fontWeight: "600" }}>
                      Task Name
                    </th>
                    <th scope="col" style={{ fontWeight: "600" }}>
                      Vehicle Name
                    </th>
                    <th scope="col" style={{ fontWeight: "600" }}>
                      KM
                    </th>
                    <th scope="col" style={{ fontWeight: "600" }}>
                      Cost
                    </th>
                    <th scope="col" style={{ fontWeight: "600" }}>
                      Service Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {vehicleAllHistoryList?.map((item) => {
                    return (
                      <>
                        <tr>
                          <td>{item.task_name}</td>
                          <td>{item.vehicle_name}</td>
                          <td>{item.vehicle_kilometer}KM</td>
                          <td>{item.cost}</td>
                          <td>
                            {moment(item.service_dates).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={handleVehicleHistoryClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Schedule</ModalHeader>
        <Form method={`post`} onSubmit={handleVehicleClassForm}>
          <ModalBody>
            <div className="row">
              <div className="col-sm-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Schedule Time"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <DatePicker
                    value={dates}
                    onChange={handleChangeDate}
                    sort
                    format="YYYY-MM-DD"
                    calendarPosition="bottom-center"
                    placeholder="Select Date"
                    style={{
                      border: "1px solid #CCCCCC",
                      borderRadius: "5px",
                      height: "40px",
                      margin: "1px 0",
                      padding: "2px 5px",
                      width: "100%",
                      paddingRight: "30px",
                    }}
                  />
                </FormGroup>
              </div>
              <div className="col-sm-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Select Time"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <TimePicker
                    onChange={handleChange}
                    value={time}
                    format={`hh:mm a`}
                    className={`react-picker form-control`}
                  />
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={handleVehicleClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modifyTaskModal}>
        <ModalHeader>Maintenance</ModalHeader>
        <Form method={`post`} onSubmit={handleSubmitTaskForm}>
          <ModalBody>
            <div className="row">
              <Label className="col-form-label">{"Vehicle Name"}</Label>
              <div className="col-xl-12">
                <div className="input-div">
                  <Select
                    options={vehicleList}
                    value={vehicleList.find(
                      (option) => option.value == vehicle
                    )}
                    onChange={(selectedOption) =>
                      setVehicle((prevData) => ({
                        ...prevData,
                        vehicleId: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Vehicle"}
                  />
                </div>
              </div>

              <Label className="col-form-label">{"Vehicle Task"}</Label>
              <div className="col-xl-12">
                <div className="input-div">
                  <Select
                    options={vehicleTaskList}
                    value={vehicleTaskList.find(
                      (option) => option.value == task
                    )}
                    onChange={(selectedOption) =>
                      setTask((prevData) => ({
                        ...prevData,
                        taskId: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Task"}
                  />
                </div>
              </div>

              {/* <Label className="col-form-label">{"Activities"}</Label>
              <div className="col-xl-12">
                {vehicleData.activities.map((activity, index) => (
                  <div
                    className="input-div mb-2 d-flex align-items-center"
                    key={index}
                  >
                    <input
                      className="form-control"
                      type="text"
                      value={activity}
                      onChange={(e) =>
                        handleActivityChange(index, e.target.value)
                      }
                      min={0}
                      name={`activities-${index}`}
                      maxLength={15}
                      placeholder="Activities"
                      style={{ flex: 1 }} // Input field takes up available space
                    />
                    <Button
                      color="danger"
                      onClick={() => removeActivityField(index)}
                      className="ml-2"
                      disabled={vehicleData.activities.length === 1}
                      style={{ width: "80px", marginLeft: "10px" }}
                    >
                      Remove
                    </Button>
                    {index === vehicleData.activities.length - 1 && (
                      <Button
                        color="primary"
                        onClick={addActivityField}
                        className="ml-2"
                        style={{
                          width: "74px",
                          height: "33px",
                          marginLeft: "10px",
                        }}
                      >
                        Add Activity
                      </Button>
                    )}
                  </div>
                ))}
              </div> */}

              <Label className="col-form-label">{"Kilometer At"}</Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.kilometer_at}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      kilometer_at: e.target.value,
                    });
                  }}
                  name={`kilometer_at`}
                  maxLength={15}
                  placeholder="Kilometer At"
                />
              </div>
            </div>

            {/* <div className="row">
              <Label className="col-form-label">{"Vehicle Task"}</Label>
              <div className="col-xl-12">
                <div className="input-div">
                  <Select
                    options={vehicleTaskList}
                    value={vehicleTaskList.find(
                      (option) => option.value == task
                    )}
                    onChange={(selectedOption) =>
                      setTask((prevData) => ({
                        ...prevData,
                        taskId: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Task"}
                  />
                </div>
              </div>

              <Label className="col-form-label">{"Activities"}</Label>

              <div className="col-xl-12">
                {vehicleData.activities.map((activity, index) => (
                  <div
                    className="input-div mb-2 d-flex align-items-center"
                    key={index}
                  >
                    <input
                      className="form-control"
                      type="text"
                      value={activity}
                      onChange={(e) =>
                        handleActivityChange(index, e.target.value)
                      }
                      min={0}
                      name={`activities-${index}`}
                      maxLength={15}
                      placeholder="Activities"
                      style={{ flex: 1 }} // Input field takes up available space
                    />
                    <Button
                      color="danger"
                      onClick={() => removeActivityField(index)}
                      className="ml-2"
                      disabled={vehicleData.activities.length === 1}
                      style={{ width: "80px", marginLeft: "10px" }}
                    >
                      Remove
                    </Button>
                    {index === vehicleData.activities.length - 1 && (
                      <Button
                        color="primary"
                        onClick={addActivityField}
                        className="ml-2"
                        style={{
                          width: "74px",
                          height: "33px",
                          marginLeft: "10px",
                        }}
                      >
                        Add Activity
                      </Button>
                    )}
                  </div>
                ))}
              </div>

              <Label className="col-form-label">{"Replaced Parts"}</Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.replaced_parts}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      replaced_parts: e.target.value,
                    });
                  }}
                  min={0}
                  name={`replaced_parts`}
                  maxLength={15}
                  placeholder="Replaced Parts"
                />
              </div>

              <Label className="col-form-label">{"Cost"}</Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.cost_associated_for_maintenance}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      cost_associated_for_maintenance: e.target.value,
                    });
                  }}
                  min={0}
                  name={`cost_associated_for_maintenance`}
                  maxLength={15}
                  placeholder="Cost"
                />
              </div>
            </div> */}
            {/* <div className="row">
              <div className="col-sm-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Schedule Time"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <DatePicker
                    value={dates}
                    onChange={handleChangeDate}
                    sort
                    format="YYYY-MM-DD"
                    calendarPosition="bottom-center"
                    placeholder="Select Date"
                    style={{
                      border: "1px solid #CCCCCC",
                      borderRadius: "5px",
                      height: "40px",
                      margin: "1px 0",
                      padding: "2px 5px",
                      width: "100%",
                      paddingRight: "30px",
                    }}
                  />
                </FormGroup>
              </div>
              <div className="col-sm-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Select Time"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <TimePicker
                    onChange={handleChange}
                    value={time}
                    format={`hh:mm a`}
                    className={`react-picker form-control`}
                  />
                </FormGroup>
              </div>
            </div> */}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={handleTaskClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default VehicleTask;
