import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const name = 'subscription';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({name, initialState, reducers});

export const subscriptionActions = {...slice.actions, ...extraActions};
export const subscriptionReducer = slice.reducer;

function createInitialState() {
    return {
        value: false
    }
}

function createReducers() {
    return {
        setSubscription
    };

    function setSubscription(state, action) {
        state.value = action.payload;
    }
}

function createExtraActions() {
    return {
        subscription: subscription()
    };

    function subscription() {
        return createAsyncThunk(
            `${name}/load`,
            function (arg, {dispatch}) {
                dispatch(subscriptionActions.setSubscription(arg));
            }
        );
    }
}